﻿import * as React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const green = '#5ec4b6';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.primary.main,
            backgroundColor: green,
            '&:hover': {
                backgroundColor: green
            },
            borderRadius: 0,
            minWidth: '17.1875rem',
            minHeight: '4.25rem',
            textTransform: 'none'
        }
    }));

interface IProps extends ButtonProps {
    component?: any;
}

const defaultProps: IProps = {
    component: undefined,
}

export function GreenButton(props: IProps) {
    const classes = useStyles();
    const { component, ...rest } = props;

    return (
        <React.Fragment>
            {component ?
                <Button {...rest} className={classes.root} component={component} />
                :
                <Button {...rest} className={classes.root} />
            }
        </React.Fragment>
    );
}

GreenButton.defaultProps = defaultProps;
export default GreenButton;