﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import SquareButton from '../Utilities/SquareButton';
import LightBlueButton from '../Utilities/LightBlueButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ProjectTemplateViewModel from '../ViewModels/ProjectTemplateViewModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
    })
);

interface IProps {
    formId: number;
    open: boolean;
    close: () => void;
    projectTemplates: ProjectTemplateViewModel[];
}

export default function NewFormDialog(props: IProps) {
    const classes = useStyles();
    const { formId, open, close } = props;

    const [dropdownElement, setDropdownElement] = React.useState<DropdownViewModel | null>(null);
    const [dropdownList, setDropdownList] = React.useState<DropdownViewModel[]>([]);
    const [projectTemplate, setProjectTemplate] = React.useState(new ProjectTemplateViewModel());

    React.useEffect(() => {
        setDropdownList(props.projectTemplates.map((template) => {
            return {
                key: template.id,
                value: template.projectName
            }
        }));

    }, [props.projectTemplates]);


    const newFormLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <RouterLink to={{
                    pathname: `/Documents/NewForm`,
                    state: {
                        formId: formId,
                        templateId: dropdownElement === null ? 0 : dropdownElement.key,
                        projectTemplate: projectTemplate
                    }
                }} ref={ref} {...itemProps} />
            )),
        [formId, dropdownElement, projectTemplate],
    );

    const onTemplateChange = (event: any, newValue: DropdownViewModel | null) => {
        setDropdownElement(newValue);

        if (newValue !== null) {
            var template = props.projectTemplates.find((template) => {
                return template.id === newValue.key
            });

            if (template !== undefined) {
                setProjectTemplate(template);
            }
        }        
    }

    const cancel = () => {
        setDropdownElement(null);
        close();
    }

    const optionLabel = (option: DropdownViewModel) => {
        return option.value;
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="new-form-title"
            PaperProps={{ className: classes.paper }}
        >
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            clearOnEscape
                            options={dropdownList}
                            getOptionLabel={optionLabel}
                            id="template-select"
                            onChange={onTemplateChange}
                            value={dropdownElement}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    label="Select Project Template"
                                    
                                    placeholder="No Template"
                                    InputProps={{
                                        ...params.InputProps,
                                        disabled: false,
                                       
                                    }}
                                    InputLabelProps={{
                                        shrink: true,

                                    }}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SquareButton onClick={cancel} variant="outlined" color="primary">Cancel</SquareButton>
                <LightBlueButton variant="contained" component={newFormLink}>Start New Form</LightBlueButton>
            </DialogActions>
        </Dialog>
    );
}