﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import OrderViewmodel, { BookshopProductViewModel, OrderItemViewModel, ShippingViewModel } from './OrderViewmodel';
import InputLabel from '@material-ui/core/InputLabel';
import GreenTextfield from './GreenTextfield';
import FormHelperText from '@material-ui/core/FormHelperText';
import GreenButton from './GreenButton';
import { UtilityMethods } from '../Utilities/UtilityMethods';
import authService from '../api-authorization/AuthorizeService';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import GreenTextValidator from './GreenTextValidator';
import GreenSelectValidator from './GreenSelectValidator';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import { useHistory, useParams } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import Bugsnag from '@bugsnag/js';

const lightGreen = '#c9e9e6';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        greenBox: {
            backgroundColor: lightGreen,
            padding: '1.5625rem !important'
        },
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
        link: {
            color: theme.palette.primary.main,
            textDecorationColor: theme.palette.secondary.main,
            transition: 'all .3s ease',
            '&:hover': {
                color: theme.palette.tertiary.main,
            }
        },
        blueText: {
            color: theme.palette.primary.main
        },
        collapse: {
            width: '100%'
        }
    })
);

interface RouteParams {
    orderId?: string;
}

export default function InformationForm() {
    const history = useHistory();
    const classes = useStyles();
    const { orderId } = useParams<RouteParams>();
    const [info, setInfo] = React.useState(new OrderViewmodel());
    const [products, setProducts] = React.useState<BookshopProductViewModel[]>();
    const [shippingTo, setShippingTo] = React.useState('Ireland');
    const [shippingLookup, setShippingLookup] = React.useState<ShippingViewModel[]>();
    const [copyAddress, setCopyAddress] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [countryCodes, setCountryCodes] = React.useState<DropdownViewModel[]>([]);
    const [disableButton, setButtonDisabled] = React.useState(true);

    React.useEffect(() => {
        getShipping();
        getOrderItems();
        getCountryCodes();
    }, []);

    React.useEffect(() => {
        if (orderId && orderId.length > 0 && info.name.length === 0 && products) {
            getExistingOrder();
        }
    }, [orderId, products]);

    React.useEffect(() => {
        // Calculate shipping
        if (shippingLookup && shippingLookup.length > 0) {
            const totalWeight: number = info.order.map(({ weight, quantity }) => (weight * quantity)).reduce((sum, i) => sum + i, 0);
            const shipping = shippingLookup.find(f => f.international === (shippingTo !== 'Ireland') && f.lowerWeight < totalWeight && f.upperWeight > totalWeight);
            const shipCost = shipping ? shipping.cost : 0;
            const total = info.order.map(({ cost, quantity }) => (cost * quantity)).reduce((sum, i) => sum + i, 0) + shipCost;
            setInfo({
                ...info,
                shipping: shipping ? shipping.id : 0,
                total: total
            });
        }
    }, [info.order, shippingTo]); 

    React.useEffect(() => {
        if (copyAddress) {
            setInfo({
                ...info,
                shipStreetAddress1: info.streetAddress1,
                shipStreetAddress2: info.streetAddress2,
                shipStreetAddress3: info.streetAddress3,
                shipCity: info.city,
                shipProvince: info.province,
                shipPostalCode: info.postalCode
            });
        }
    }, [copyAddress]);

    React.useEffect(() => {
        if (copyAddress) {
            setInfo({
                ...info,
                shipStreetAddress1: info.streetAddress1
            });
        }
    }, [info.streetAddress1]);

    React.useEffect(() => {
        if (copyAddress) {
            setInfo({
                ...info,
                shipStreetAddress2: info.streetAddress2
            });
        }
    }, [info.streetAddress2]);

    React.useEffect(() => {
        if (copyAddress) {
            setInfo({
                ...info,
                shipStreetAddress3: info.streetAddress3
            });
        }
    }, [info.streetAddress3]);

    React.useEffect(() => {
        if (copyAddress) {
            setInfo({
                ...info,
                shipCity: info.city
            });
        }
    }, [info.city]);

    React.useEffect(() => {
        if (copyAddress) {
            setInfo({
                ...info,
                shipProvince: info.province
            });
        }
    }, [info.province]);

    React.useEffect(() => {
        if (copyAddress) {
            setInfo({
                ...info,
                shipPostalCode: info.postalCode
            });
        }
    }, [info.postalCode]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value;

        if (e.target.name === 'countryCode') {
            value = e.target.value.toString();
        } else {
            value = e.target.value;
        }

        setInfo({
            ...info,
            [e.target.name]: value
        });
    }

    const onOrderChange = (id: number, value: any) => {
        const newOrder = [...info.order];
        const itemIndex = newOrder.findIndex(i => i.productId === id);
        const productIndex = products!.findIndex(f => f.id === id);

        if (itemIndex < 0) {
            if (products) {
                const product = products!.find(f => f.id === id);

                if (product) {
                    const newItem = new OrderItemViewModel();
                    newItem.productId = id;
                    newItem.cost = product.cost;
                    newItem.weight = product.weight;
                    newItem.quantity = value;
                    newOrder.push(newItem);
                }
            }
        } else {
            const newItem = { ...newOrder[itemIndex] };
            newItem.quantity = value;
            newOrder[itemIndex] = newItem;
        }

        const newList = products ? [...products] : [];
        const newProduct = { ...newList[productIndex] };
        newProduct.quantity = value;
        newList[productIndex] = newProduct;

        setInfo({
            ...info,
            order: newOrder
        });

        setProducts(newList);
    }

    const getOrderItems = async () => {
        const token = await authService.getAccessToken();

        fetch(`Bookshop/GetOrderItems`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }).then(response => response.json()).then((data: BookshopProductViewModel[]) => {
            const list = data.map(item => {
                return {
                    id: item.id,
                    cost: item.cost,
                    description: item.description,
                    weight: item.weight,
                    quantity: 0
                };
            });

            setProducts(list);
        }).catch(error => {
            Bugsnag.notify(error);
        });
    }

    const getShipping = async () => {
        const token = await authService.getAccessToken();

        fetch(`Bookshop/GetShippingCosts`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }).then(response => response.json()).then((data) => {
            setShippingLookup(data);
        }).catch(error => {
            Bugsnag.notify(error);
        });
    }

    const getCountryCodes = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch(`Bookshop/GetCountryCodes`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setCountryCodes(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const getExistingOrder = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        setCopyAddress(false);

        fetch(`Bookshop/GetExistingOrder?orderId=${orderId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            data.memberId === null ? data.memberId = '' : data.memberId = data.memberId;
            setInfo(data);

            if (shippingLookup) {
                const newList = products ? [...products] : [];
                data.order.forEach((item: OrderItemViewModel) => {
                    const productIndex = products!.findIndex(f => f.id === item.productId);
                    const newProduct = { ...newList[productIndex] };
                    newProduct.quantity = item.quantity;
                    newList[productIndex] = newProduct;
                });
                setProducts(newList);

                const shipping = shippingLookup.find(f => f.id === data.shipping);

                if (shipping) {
                    setShippingTo(shipping.international ? 'Rest of World' : 'Ireland');
                }
            }

            setLoading(false);

        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const submit = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`Bookshop/AddOrder`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(info)
        })
            .then(response => response.json())
            .then(data => {
                setSaving(false);

                if (data !== 0) {
                    history.push({
                        pathname: "/BookshopPayment",
                        state: { orderId: data }
                    });
                } else {
                    // exception occurred
                }
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const onCaptchaChange = (token: string | null) => {
        if (token && token.length > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }

    const shippingCost = info.shipping > 0 && shippingLookup && shippingLookup.length > 0 ? shippingLookup!.find(f => f.id == info.shipping) : new ShippingViewModel();

    return (
        <ValidatorForm onSubmit={submit}>
            <Collapse in={((orderId && orderId.length > 0) || !orderId) && !loading}>
                <Grid container spacing={1}>
                    {orderId && orderId.length > 0 && <Grid item xs={12}><Typography color="error" variant="h6">There was an issue processing your payment, please try again.</Typography></Grid>}
                    <Grid item xs={12}><Typography color="secondary" variant="h5">Order online</Typography></Grid>
                    <Grid item xs={12}>
                        <Typography color="primary">The most popular items from the RIAI Bookshop are now available to order online. <a className={classes.link} href="https://www.riai.ie/uploads/files/general-files/RIAI_Contracts_04032021.pdf" target="_blank"><b>Click here to see a full list of RIAI Contracts.</b></a></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" gutterBottom><b>Please note:</b> orders are fulfilled by the RIAI Bookshop and will be dispatched within two working days.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={classes.blueText}><b>Name *</b></InputLabel>
                        <GreenTextValidator
                            name="name"
                            margin="normal"
                            fullWidth
                            value={info.name}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                        <FormHelperText className={classes.blueText}>(Name of person to whom the delivery should be addressed)</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={classes.blueText}><b>RIAI Membership Number</b></InputLabel>
                        <GreenTextfield
                            name="memberId"
                            margin="normal"
                            fullWidth
                            value={info.memberId}
                            onChange={onChange}
                        />
                        <FormHelperText className={classes.blueText}>(If applicable - please prepend with "P" for practice members)</FormHelperText>
                    </Grid>

                    <Grid item xs={12}><Typography color="secondary" variant="h6">Billing Address</Typography></Grid>
                    <Grid item xs={12}><Typography color="primary">Billing Address must match the address on the account for the credit card that is being used.</Typography></Grid>
                    <Grid item xs={12}>
                        <InputLabel className={classes.blueText}><b>Address Line 1 *</b></InputLabel>
                        <GreenTextValidator
                            name="streetAddress1"
                            margin="normal"
                            fullWidth
                            value={info.streetAddress1}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={classes.blueText}><b>Address Line 2</b></InputLabel>
                        <GreenTextValidator
                            name="streetAddress2"
                            margin="normal"
                            fullWidth
                            value={info.streetAddress2}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={classes.blueText}><b>Address Line 3</b></InputLabel>
                        <GreenTextValidator
                            name="streetAddress3"
                            margin="normal"
                            fullWidth
                            value={info.streetAddress3}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={classes.blueText}><b>Town *</b></InputLabel>
                        <GreenTextValidator
                            name="city"
                            margin="normal"
                            fullWidth
                            value={info.city}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={classes.blueText}><b>County *</b></InputLabel>
                        <GreenTextValidator
                            name="province"
                            margin="normal"
                            fullWidth
                            value={info.province}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={classes.blueText}><b>Eircode *</b></InputLabel>
                        <GreenTextValidator
                            name="postalCode"
                            margin="normal"
                            fullWidth
                            value={info.postalCode}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12}><Typography color="secondary" variant="h6">Shipping Address</Typography></Grid>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={copyAddress}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCopyAddress(e.target.checked)}
                                name="copyAddress"
                                color="secondary"
                            />
                        }
                        label="Same as billing address"
                    />
                    <Collapse in={!copyAddress} className={classes.collapse}>
                        <Grid item xs={12}>
                            <InputLabel className={classes.blueText}><b>Address Line 1 *</b></InputLabel>
                            <GreenTextValidator
                                name="shipStreetAddress1"
                                margin="normal"
                                fullWidth
                                value={info.shipStreetAddress1}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className={classes.blueText}><b>Address Line 2</b></InputLabel>
                            <GreenTextValidator
                                name="shipStreetAddress2"
                                margin="normal"
                                fullWidth
                                value={info.shipStreetAddress2}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className={classes.blueText}><b>Address Line 3</b></InputLabel>
                            <GreenTextValidator
                                name="shipStreetAddress3"
                                margin="normal"
                                fullWidth
                                value={info.shipStreetAddress3}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className={classes.blueText}><b>Town *</b></InputLabel>
                            <GreenTextValidator
                                name="shipCity"
                                margin="normal"
                                fullWidth
                                value={info.shipCity}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className={classes.blueText}><b>County *</b></InputLabel>
                            <GreenTextValidator
                                name="shipProvince"
                                margin="normal"
                                fullWidth
                                value={info.shipProvince}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className={classes.blueText}><b>Eircode *</b></InputLabel>
                            <GreenTextValidator
                                name="shipPostalCode"
                                margin="normal"
                                fullWidth
                                value={info.shipPostalCode}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                    </Collapse>
                    <Grid item xs={12}>
                        <InputLabel className={classes.blueText}><b>Email Address *</b></InputLabel>
                        <GreenTextValidator
                            name="email"
                            margin="normal"
                            fullWidth
                            value={info.email}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel className={classes.blueText}><b>Country Code *</b></InputLabel>
                        <GreenSelectValidator
                            name="countryCode"
                            margin="normal"
                            fullWidth
                            value={info.countryCode}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                            InputProps={{
                                size: "small",
                                disabled: loading,
                                startAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress size={20} /> : null}
                                    </React.Fragment>
                                )
                            }}>
                            {countryCodes && countryCodes.map((item, index) => <MenuItem key={'country' + index} value={item.key}>{item.value + " (+" + item.key + ")"}</MenuItem>)}
                        </GreenSelectValidator>
                    </Grid>
                    <Grid item xs={8}>
                        <InputLabel className={classes.blueText}><b>Contact Number *</b></InputLabel>
                        <GreenTextValidator
                            name="number"
                            margin="normal"
                            fullWidth
                            value={info.number}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                        <FormHelperText className={classes.blueText}>Please do not enter the leading 0.</FormHelperText>
                        <FormHelperText className={classes.blueText}>Must match the phone number on the account for the credit card that is being used.</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel className={classes.blueText}><b>Note on your Order</b></InputLabel>
                        <GreenTextfield
                            name="note"
                            margin="normal"
                            fullWidth
                            multiline
                            value={info.note}
                            onChange={onChange}
                        />
                        <FormHelperText className={classes.blueText}>If there are any additional details that you would like to let us know about, please do so here.</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6" gutterBottom>YOUR ORDER</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" gutterBottom>All prices are inclusive of VAT, where applicable.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" gutterBottom><b>Please note:</b> The RIAI Good Practice Guide can only be sold to RIAI Members. Please provide your RIAI Membership Number in the field above. Orders received without a valid RIAI Membership Number will not be fulfilled and your payment will be refunded.</Typography>
                    </Grid>

                    {products && products.map(item => {
                        return <Grid item xs={12} className={classes.greenBox} key={item.id}>
                            <GreenTextValidator
                                name={item.id.toString()}
                                margin="dense"
                                fullWidth
                                type="number"
                                inputProps={{
                                    min: 0
                                }}
                                value={item.quantity}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onOrderChange(item.id, e.target.value)}
                                validators={['minNumber:0']}
                                errorMessages={['Quantity cannot be negative']}
                            />
                            <Typography>{item.description}</Typography>
                        </Grid>
                    })}

                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6" gutterBottom>SHIPPING CHARGES</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" gutterBottom>Please note: Shipping to Northern Ireland is at the IRELAND rate.</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.greenBox}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Shipping To *</FormLabel>
                                    <RadioGroup aria-label="shippingTo" name="shippingTo" value={shippingTo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShippingTo(e.target.value)}>
                                        <FormControlLabel value='Ireland' control={<Radio />} label="Ireland" color="primary" />
                                        <FormControlLabel value='Rest of World' control={<Radio />} label="Rest of World" color="primary" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="primary" gutterBottom>Shipping</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="primary" gutterBottom>€{UtilityMethods.round2Decimals(shippingCost ? shippingCost.cost : 0).toFixed(2)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6">TOTAL</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" gutterBottom>€{UtilityMethods.round2Decimals(info.total).toFixed(2)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" gutterBottom>*denotes required field</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" gutterBottom>Please see our <a className={classes.link} href="http://www.riai.ie/about_this_site/privacy_statement/" target="_blank">Privacy Statement</a>, which has been created to demonstrate our commitment to the privacy of your personal information and explain our use of your personal information under applicable Data Protection legislation.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h6">CAPTCHA</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ReCAPTCHA sitekey="6LdqazMeAAAAAItrGkQG7D4MEdvQtfVcNjiQe_za" onChange={onCaptchaChange} />
                    </Grid>
                    <Grid item>
                        <GreenButton variant="contained" type="submit" size="large" disabled={saving || loading || disableButton}><b>Submit</b></GreenButton>
                    </Grid>
                </Grid>
            </Collapse>
        </ValidatorForm>
    );
}