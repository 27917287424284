﻿import * as React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { SortFaqViewModel } from '../ViewModels/FaqViewModel';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import Bugsnag from '@bugsnag/js';

interface IProps {
    faq: SortFaqViewModel;
    edit: (id: number, refresh: boolean) => void;
    refresh: () => void;
}

export default function FaqTableRow(props: IProps) {
    const { faq } = props;
    const [loading, setLoading] = React.useState(false);

    const edit = () => {
        props.edit(faq.id, false);
    }

    const deleteQuestion = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        fetch(`Faq/DeleteQuestion?id=${faq.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => {
                setLoading(false);
                props.refresh();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    return (
        <TableRow tabIndex={-1}>
            <TableCell>{faq.category}</TableCell>
            <TableCell>{faq.question}</TableCell>
            <TableCell>{faq.answer}</TableCell>
            <TableCell align="right">
                <LightBlueButton variant="contained" fullWidth size="small" onClick={deleteQuestion} disabled={loading}>Delete</LightBlueButton>
            </TableCell>
            <TableCell align="right">
                <LightBlueButton variant="contained" fullWidth size="small" onClick={edit} disabled={loading}>Edit</LightBlueButton>
            </TableCell>
        </TableRow>
    );
}