﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Typography from '@material-ui/core/Typography';

export default function SubFooter() {
    return (
        <React.Fragment>

            <Grid container alignContent="space-between" alignItems="center" spacing={4}>
                <Grid item xs={9}>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="body2">© RIAI (The Royal Institute of the Architects of Ireland) <a href="https://smashedcrabsoftware.com" target="_blank">Built by</a><a href="https://smashedcrabsoftware.com" target="_blank"><img src='crab.png' alt="Crab Logo" style={{ marginLeft: '5px', height: '14px', marginBottom: '-3px' }} /></a></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="body2">Company Limited by Guarantee. Registered in Ireland, No. 003498. RCN 20008109</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <VerifiedUser style={{ color: "#929292" }} />
                        </Grid>
                        <Grid item>
                            <img src='paymentslogos.png' alt="Payment Logos" style={{ marginLeft: '10px', height: '24px', marginBottom: '-6px' }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}