﻿import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormElementViewModel from '../../ViewModels/FormElementViewModel';
import HelpIcon from '@material-ui/icons/Help';
import LightTooltip from '../../Utilities/LightTooltip';

interface IProps {
    element: FormElementViewModel;
}

export default function DisplayText(props: IProps) {

    var elem = <React.Fragment />;

    switch (props.element.textTypeId) {
        case 1:
            elem = <Typography variant="h4">{props.element.text} {props.element.helpText &&
                <LightTooltip title={props.element.helpText} arrow>
                    <HelpIcon style={{ height: '20px' }} color="secondary" />
                </LightTooltip>
            }</Typography>
            break;
        case 2:
            elem = <Typography variant="h5">{props.element.text} {props.element.helpText &&
                <LightTooltip title={props.element.helpText} arrow>
                    <HelpIcon style={{ height: '20px' }} color="secondary" />
                </LightTooltip>
            }</Typography>
            break;
        case 3:
            elem = <Typography variant="h6">{props.element.text} {props.element.helpText &&
                <LightTooltip title={props.element.helpText} arrow>
                    <HelpIcon style={{ height: '20px' }} color="secondary" />
                </LightTooltip>
            }</Typography>
            break;
        case 4:
            elem = <Typography>{props.element.text} {props.element.helpText &&
                <LightTooltip title={props.element.helpText} arrow>
                    <HelpIcon style={{ height: '20px' }} color="secondary" />
                </LightTooltip>
            }</Typography>
            break;
        default:
            elem = <Typography>{props.element.text} {props.element.helpText &&
                <LightTooltip title={props.element.helpText} arrow>
                    <HelpIcon style={{ height: '20px' }} color="secondary" />
                </LightTooltip>
            }</Typography>
            break;
    }

    return (
        <Grid item xs={12}>            
                    {elem}           
        </Grid>
    );
}