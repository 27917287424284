﻿import * as React from 'react';
import TemplateListViewModel from "../ViewModels/TemplateListViewModel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LightBlueButton from '../Utilities/LightBlueButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import SquareButton from '../Utilities/SquareButton';
import WarningDialog from '../Utilities/WarningDialog';
import Grid from '@material-ui/core/Grid';
import Bugsnag from '@bugsnag/js';

interface IProps {
    projectTemplate: TemplateListViewModel;
    refresh: () => void;
}

export default function TemplateTableRow(props: IProps) {
    const { projectTemplate, refresh } = props;
    const [open, setOpen] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const toggleWarning = () => {
        setOpen(!open);
    }

    const editLink = React.useMemo(() =>
        React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
            <RouterLink
                ref={ref}
                to={{
                    pathname: `/Documents/EditTemplate`,
                    state: {
                        projectTemplate: projectTemplate,
                    }
                }}
                {...props} />
        )), [0, 1]);

    const deleteProjectTemplate = async () => {

        const token = await authService.getAccessToken();

        fetch('Forms/DeleteProjectTemplate?id=' + projectTemplate.id, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
        }).then(response => {
            setDeleting(false);
            toggleWarning();
            refresh();
        }).catch((error) => {
            Bugsnag.notify(error);
            setDeleting(false);
        });

    }

    return (
        <TableRow
            tabIndex={-1}
            key={projectTemplate.id}
        >
            <TableCell>{projectTemplate.id}</TableCell>
            <TableCell>{projectTemplate.projectName}</TableCell>

            <TableCell align="right">
                <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                        <SquareButton color="primary" variant="outlined" size="small" onClick={toggleWarning}>Delete</SquareButton>

                        <WarningDialog
                            close={toggleWarning}
                            open={open}
                            okClick={deleteProjectTemplate}
                            disabled={deleting}
                            buttonText="Delete"
                            text="Are you sure you want to delete this project template?"
                        />
                    </Grid>
                    <Grid item>
                        <LightBlueButton size="small" component={editLink}>Edit</LightBlueButton>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
}