﻿import * as React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import { UserDropdownViewModel } from '../ViewModels/UserViewModel';
import Bugsnag from '@bugsnag/js';

interface IProps {
    label: string;
    mainOwner: UserDropdownViewModel | null;
    onChange: (event: any, newValue: UserDropdownViewModel | null) => void;
}

export default function MainOwnerDropdown(props: IProps) {
    const { mainOwner, onChange, label } = props;
    const [users, setUsers] = React.useState<UserDropdownViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch('User/GetUserDropdown', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setUsers(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    };

    const optionLabel = (option: UserDropdownViewModel) => {
        return option.name;
    }

    return (
        <React.Fragment>
            <InputLabel htmlFor="main-owner-select">{label}</InputLabel>
            <Autocomplete
                clearOnEscape
                options={users}
                getOptionLabel={optionLabel}
                id="main-owner-select"
                onChange={onChange}
                value={mainOwner}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            disabled: loading,
                            startAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress size={20} /> : null}
                                </React.Fragment>
                            )
                        }}
                    />
                }
            />
        </React.Fragment>
    );
}