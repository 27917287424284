﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LightBlueButton from '../Utilities/LightBlueButton';
import ColourPaper from '../Utilities/ColourPaper';
import Divider from '@material-ui/core/Divider';
import { PurchaseItemViewModel } from '../ViewModels/CartItemViewModel';
import { useHistory, useLocation } from 'react-router-dom';
import AddressViewModel from '../ViewModels/AddressViewModel';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UtilityMethods } from '../Utilities/UtilityMethods';
import PhoneNumberViewModel from '../ViewModels/PhoneNumberViewModel';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import { MenuItem } from '@material-ui/core';
import Bugsnag from '@bugsnag/js';
import Alert from '@material-ui/lab/Alert';

interface RouteParams {
    purchaseId: string;
    error?: string;
}

export default function Checkout() {
    const history = useHistory();
    const location = useLocation<RouteParams>();
    const { purchaseId, error } = location.state || { purchaseId: '', error: '' };
    const formRef = React.useRef<ValidatorForm>(null);
    const [address, setAddress] = React.useState(new AddressViewModel());
    const [phoneNumber, setPhoneNumber] = React.useState(new PhoneNumberViewModel());
    const [summary, setSummary] = React.useState<PurchaseItemViewModel[]>([]);
    const [vat, setVat] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [countryCodes, setCountryCodes] = React.useState<DropdownViewModel[]>([]);

    React.useEffect(() => {
        getVat();
        getCartItems();
        getCountryCodes();
    }, []);

    React.useEffect(() => {
        setAddress({
            ...address,
            purchaseId: parseInt(purchaseId, 10)
        });

        setPhoneNumber({
            ...phoneNumber,
            purchaseId: parseInt(purchaseId, 10),
            countryCode: "353"
        });
    }, [purchaseId]);

    const getVat = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch('Checkout/GetVat', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setVat(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const getCartItems = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch(`Checkout/GetCurrentCart?purchaseId=${purchaseId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(async data => {
                setSummary(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const getCountryCodes = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch(`Checkout/GetCountryCodes`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setCountryCodes(data);

        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const addAddress = async () => {
        //validate address before redirect to payment
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`Checkout/SetBillingAddress`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(address)
        })
            .then(async response => {
                setSaving(false);

                if (formRef && formRef.current) {
                    const validAddress = await formRef.current.isFormValid(true);
                    if (validAddress) {
                        history.push({
                            pathname: "/Payment",
                            state: { purchaseId: purchaseId }
                        });
                    }
                }
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const addPhoneNumber = async () => {
        //add phone number, then add address once complete
        setSaving(true);
        const token = await authService.getAccessToken();
        console.log(phoneNumber);
        fetch(`Checkout/SetPhoneNumber`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(phoneNumber)
        })
            .then(async response => {
                addAddress();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress({
            ...address,
            [event.target.name]: event.target.value
        });
    }

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber({
            ...phoneNumber,
            [event.target.name]: event.target.value.toString()
        });
    }

    const items = summary.map(item => {
        return <React.Fragment key={item.id}>
            <Grid item xs={12}>
                <Grid container justify="space-between" spacing={1}>
                    <Grid item xs={10}>
                        <Typography variant="subtitle1">{item.numberOfUnits} x {item.formName}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align="right">€{(item.unitCost * item.numberOfUnits).toFixed(2)}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
        </React.Fragment>;
    });

    const subTotal: number = summary.map(({ unitCost, numberOfUnits }) => (unitCost * numberOfUnits)).reduce((sum, i) => sum + i, 0);
    const vatCost: number = summary.map(({ unitCost, numberOfUnits }) => (((unitCost * vat) / 100) * numberOfUnits)).reduce((sum, i) => sum + i, 0);

    return (
        <ValidatorForm onSubmit={addPhoneNumber} ref={formRef}>
            <Grid container spacing={3} justify="flex-end">
                {error &&
                    <Grid item xs={12}>
                        <Alert severity="error">{error}</Alert>
                    </Grid>
                }
                <Grid item xs={12} sm={6}>
                    <ColourPaper>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="h5">Billing Address</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="streetAddress1">Address Line 1</InputLabel>
                                <TextValidator
                                    id="streetAddress1"
                                    name="streetAddress1"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                    value={address.streetAddress1}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="streetAddress2">Address Line 2</InputLabel>
                                <TextValidator
                                    id="streetAddress2"
                                    name="streetAddress2"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                    value={address.streetAddress2}
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="streetAddress3">Address Line 3</InputLabel>
                                <TextValidator
                                    id="streetAddress3"
                                    name="streetAddress3"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                    value={address.streetAddress3}
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="city">Town</InputLabel>
                                <TextValidator
                                    id="city"
                                    name="city"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                    value={address.city}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="province">County</InputLabel>
                                <TextValidator
                                    id="province"
                                    name="province"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                    value={address.province}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="postalCode">Eircode</InputLabel>
                                <TextValidator
                                    id="postalCode"
                                    name="postalCode"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                    value={address.postalCode}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={handleAddressChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Associated Mobile Number</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel htmlFor="countryCode">Country Code</InputLabel>
                                <SelectValidator
                                    id="countryCode"
                                    name="countryCode"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={phoneNumber.countryCode}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={handlePhoneNumberChange}
                                    InputProps={{
                                        size:"small",
                                        disabled: loading,
                                        startAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress size={20} /> : null}
                                            </React.Fragment>
                                        )
                                    }}>
                                    {
                                        countryCodes.map((item) => {
                                            return <MenuItem key={item.key} value={item.key}>{item.value + " (+" + item.key + ")"}</MenuItem>
                                        })
                                    }

                                </SelectValidator>
                            </Grid>

                            <Grid item xs={8}>
                                <InputLabel htmlFor="number">Phone Number</InputLabel>
                                <TextValidator
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    fullWidth
                                    value={phoneNumber.phoneNumber}
                                    validators={['required', 'isNumber']}
                                    errorMessages={['This field is required']}
                                    onChange={handlePhoneNumberChange}
                                />
                            </Grid>
                        </Grid>
                    </ColourPaper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ColourPaper>
                                {loading ?
                                    <CircularProgress color="secondary" />
                                    :
                                    <Grid container spacing={2} justify="flex-end">
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Cart Summary</Typography>
                                        </Grid>
                                        {items}
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    <Typography variant="subtitle1">Sub Total</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>€{UtilityMethods.round2Decimals(subTotal).toFixed(2)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    <Typography variant="subtitle1">VAT</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>€{UtilityMethods.round2Decimals(vatCost).toFixed(2)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    <Typography variant="subtitle1">Total</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>€{(subTotal + vatCost).toFixed(2)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </ColourPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <LightBlueButton fullWidth variant="contained" disabled={loading || saving} type="submit">Next</LightBlueButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
}