﻿import * as React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DocumentsReportViewModel from '../../ViewModels/DocumentsReportViewModel';

interface IProps {
    document: DocumentsReportViewModel;
}

export default function DocumentsRow(props: IProps) {
    const { document } = props;

    return (
        <TableRow tabIndex={-1}>
            <TableCell>{document.formID}</TableCell>
            <TableCell>{document.formName}</TableCell>
            <TableCell>{document.organisationID}</TableCell>
            <TableCell>{document.organisationName}</TableCell>
            <TableCell>{document.memberID}</TableCell>
            <TableCell>{document.memberFirstName}</TableCell>
            <TableCell>{document.memberLastName}</TableCell>
            <TableCell>{new Date(document.date).toLocaleDateString('en-GB')}</TableCell>
            <TableCell align="right">{document.noOfDocuments}</TableCell>
        </TableRow>
    );
}