﻿import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import ColourPaper from '../../Utilities/ColourPaper';
import authService from '../../api-authorization/AuthorizeService';
import SearchButton from '../../Utilities/SearchButton';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import DateFnsUtils from '@date-io/date-fns';
import LightBlueButton from '../../Utilities/LightBlueButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSVLink } from "react-csv";
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import { BookshopReportViewModel } from '../../BookShop/OrderViewmodel';
import BookshopRow from './BookshopRow';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            borderBottom: `${theme.palette.secondary.main} solid 2px`
        },
        headers: {
            color: theme.palette.primary.main
        }
    }),
);

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof BookshopReportViewModel;
    label: string;
    numeric: boolean;
    property: string;
}

const headCells: HeadCell[] = [
    { id: 'orderId', property: 'OrderId', numeric: false, disablePadding: false, label: 'Order No.' },
    { id: 'transactionId', property: 'TransactionId', numeric: false, disablePadding: false, label: 'Transaction No.' },
    { id: 'customerOrderId', property: 'CustomerOrderId', numeric: false, disablePadding: false, label: 'Customer Order No.' },
    { id: 'name', property: 'Name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'memberId', property: 'MemberId', numeric: false, disablePadding: false, label: 'Member ID' },
    { id: 'email', property: 'Email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'description', property: 'Description', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'date', property: 'Date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'quantity', property: 'Quantity', numeric: true, disablePadding: false, label: 'Quantity' },
    { id: 'cost', property: 'Cost', numeric: true, disablePadding: false, label: 'Cost' },
    { id: 'shipping', property: 'Shipping', numeric: true, disablePadding: false, label: 'Shipping' },
    { id: 'total', property: 'Total', numeric: true, disablePadding: false, label: 'Total Cost' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof BookshopReportViewModel) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof BookshopReportViewModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.headers}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const csvHeaders = [
    { key: 'orderId', label: 'Order No.' },
    { key: 'transactionId', label: 'Transaction No.' },
    { key: 'customerOrderId', label: 'Customer Order No.' },
    { key: 'name', label: 'Name' },
    { key: 'memberId', label: 'Member ID' },
    { key: 'email', label: 'Email' },
    { key: 'description', label: 'Description' },
    { key: 'date', label: 'Date' },
    { key: 'quantity', label: 'Quantity' },
    { key: 'cost', label: 'Cost' },
    { key: 'shipping', label: 'Shipping' },
    { key: 'total', label: 'Total Cost' }
];

export default function BookshopReport() {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof BookshopReportViewModel>('orderId');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sales, setSales] = React.useState<BookshopReportViewModel[]>([]);
    const [fullSales, setFullSales] = React.useState<BookshopReportViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [minDate, handleMinDateChange] = React.useState<null | Date>(null);
    const [maxDate, handleMaxDateChange] = React.useState<null | Date>(null);
    const csvInstance = React.useRef<any | null>(null);

    React.useEffect(() => {
        getSales();
    }, [page, order, orderBy, rowsPerPage]);

    React.useEffect(() => {
        if (minDate instanceof Date && !isNaN(minDate.valueOf())) {
            getSales();
        }
    }, [minDate]);

    React.useEffect(() => {
        if (maxDate instanceof Date && !isNaN(maxDate.valueOf())) {
            getSales();
        }
    }, [maxDate]);

    React.useEffect(() => {
        if (csvInstance && csvInstance.current && fullSales.length > 0) {
            setTimeout(() => {
                csvInstance.current.link.click();
                setFullSales([]);
            });
        }
    }, [fullSales]);

    const getSales = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        fetch(`Reports/GetBookshopQuery?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&minDate=${minDate ? minDate.toUTCString() : ""}&maxDate=${maxDate ? maxDate.toUTCString() : ""}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setSales(data.rows);
            setCount(data.rowCount);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const getCsv = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        fetch(`Reports/GetBookshopQuery?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&minDate=${minDate ? minDate.toUTCString() : ""}&maxDate=${maxDate ? maxDate.toUTCString() : ""}&csv=true`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setFullSales(data.rows);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof BookshopReportViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const searchClick = () => {
        if (page !== 0) {
            setPage(0);
        }
        else {
            getSales();
        }
    }

    const keyPress = (event: any) => {
        if (event.keyCode == 13) {
            if (page !== 0) {
                setPage(0);
            }
            else {
                getSales();
            }
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ColourPaper>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Typography variant="h4" gutterBottom>Bookshop Orders Report</Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="space-between" spacing={1}>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                label="Start Date"
                                                clearable
                                                inputVariant="outlined"
                                                value={minDate}
                                                onChange={date => handleMinDateChange(date)}
                                                format="dd/MM/yyyy"
                                                fullWidth
                                                size="small"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                label="End Date"
                                                clearable
                                                inputVariant="outlined"
                                                value={maxDate}
                                                onChange={date => handleMaxDateChange(date)}
                                                format="dd/MM/yyyy"
                                                fullWidth
                                                size="small"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            onChange={handleSearch}
                                            value={search}
                                            placeholder="Search..."
                                            onKeyDown={keyPress}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchButton onClick={searchClick} aria-label="Order Search" />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <LightBlueButton variant="contained" disabled={loading} onClick={getCsv}>Export CSV</LightBlueButton>
                                {fullSales.length > 0 &&
                                    <CSVLink
                                        data={fullSales}
                                        headers={csvHeaders}
                                        filename={(new Date()).toLocaleDateString('en-GB') + "_bookshop_report.csv"}
                                        style={{ textDecoration: 'none' }}
                                        ref={csvInstance}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <ResponsiveTable
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                aria-label="bookshop report table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {sales.map((row: BookshopReportViewModel, index) => {
                                                return (<BookshopRow key={index} sale={row} />);
                                            })
                                    }
                                    {emptyRows > 0 &&
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={12} align="center">{loading && <CircularProgress color="secondary" />}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </ColourPaper>
                </Grid>
            </Grid>
        </div>
    );
}