﻿import LightBlueButton from '../Utilities/LightBlueButton';
import * as React from 'react';
import ColourPaper from "../Utilities/ColourPaper";
import Grid from "@material-ui/core/Grid";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import authService from "../api-authorization/AuthorizeService";
import DropdownViewModel from "../ViewModels/DropdownViewModel";
import Typography from "@material-ui/core/Typography";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import SquareButton from '../Utilities/SquareButton';
import EditUserViewModel from '../ViewModels/EditUserViewModel';
import { useHistory, useLocation } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import Bugsnag from '@bugsnag/js';

interface LocationState {
    userId: string;
}

interface AlertMessage {
    success: boolean;
    message: string;
}

export default function EditUser() {
    const location = useLocation<LocationState>();
    const { userId } = location.state || {
        userId: ''
    };
    const history = useHistory();
    const [userLevels, setUserLevels] = React.useState<DropdownViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [profile, setProfile] = React.useState(new EditUserViewModel());
    const [message, setMessage] = React.useState<AlertMessage>({ success: true, message: "" });
    const [currUserId, setCurrUser] = React.useState('');
    const [mainOwner, setMainOwner] = React.useState(false);

    React.useEffect(() => {
        getData();
        checkIfOwner();
    }, []);

    React.useEffect(() => {
        if (userId.length > 0) {
            getProfile();
        }
    }, [userId]);

    const checkIfOwner = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setCurrUser(user.sub);

        fetch('Organisation/GetMainOwnerStatus', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((response) => response.json())
            .then(data => {
                setMainOwner(data);
            }).catch(error => {
                Bugsnag.notify(error);
            });
    }

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`User/GetUserLevels`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setUserLevels(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const getProfile = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`User/GetProfileEditDetails?id=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setProfile(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const updateUserLevel = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        const logout = currUserId === userId || (profile.userLevel === 3 && mainOwner);

        fetch(`User/ChangeUserLevel`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(profile)
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    setMessage({ success: false, message: data });
                } else {
                    setMessage({ success: true, message: "User successfully updated" });

                    if (logout) {
                        const redirectUrl = {
                            pathname: `${ApplicationPaths.LogOut}`,
                            state: { local: true }
                        };
                        history.push(redirectUrl);
                    }
                }
                setSaving(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setMessage({ success: false, message: "An error has occurred, please try again" });
                setSaving(false);
            });
    }

    const updateAddress = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`User/ChangeAddress`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(profile)
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    setMessage({ success: false, message: data });
                } else {
                    setMessage({ success: true, message: "User successfully updated" });
                }
                setSaving(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setMessage({ success: false, message: "An error has occurred, please try again" });
                setSaving(false);
            });
    }

    const updatePhone = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`User/ChangePhoneNumbers`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(profile)
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    setMessage({ success: false, message: data });
                } else {
                    setMessage({ success: true, message: "User successfully updated" });
                }
                setSaving(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setMessage({ success: false, message: "An error has occurred, please try again" });
                setSaving(false);
            });
    }

    const goBack = () => {
        history.push('/Users');
    }

    const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setTimeout(() => {
            setMessage({ success: true, message: "" });
        }, 250);
    }

    const onLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setProfile({
            ...profile,
            userLevel: value
        });
    }

    const onProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProfile({
            ...profile,
            [event.target.name]: event.target.value
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography variant="h4">Edit User</Typography>
                    </Grid>
                    <Grid item>
                        <SquareButton variant="outlined" color="primary" disabled={loading || saving} onClick={goBack}>Back</SquareButton>
                    </Grid>
                </Grid>
            </Grid>
            {profile.isMember &&
                <Grid item xs={12}>
                    <Alert severity="info">Member's information can only be changed within the RIAI system</Alert>
                </Grid>
            }
            <Grid item xs={4}>
                <ColourPaper>
                    <ValidatorForm onSubmit={updateUserLevel}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Edit User Level</Typography>
                            </Grid>
                            {(currUserId === userId || (profile.userLevel === 3 && mainOwner)) &&
                                <Grid item xs={12}>
                                    <Alert severity="warning">This will change your user level for <b>the active organisation</b> and you will be logged out.</Alert>
                                </Grid>
                            }
                            {((profile.userLevel === 4)) &&
                                <Grid item xs={12}>
                                    <Alert severity="warning">Changing User Level from Admin to another level will link this user to <b>the active organisation</b></Alert>
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <InputLabel htmlFor="userLevel">User Level</InputLabel>
                                <SelectValidator
                                    id="userLevel"
                                    name="userLevel"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={profile.userLevel ?? ''}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={onLevelChange}
                                    InputProps={{
                                        disabled: loading,
                                        startAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress size={20} /> : null}
                                            </React.Fragment>
                                        )
                                    }}
                                >
                                    {userLevels.map((item) => {
                                        return <MenuItem key={item.key} value={item.key} disabled={item.value === "Signatory" && !profile.isMember}>{item.value}</MenuItem>
                                    })}
                                </SelectValidator>
                            </Grid>
                            <Grid item xs={12}>
                                <LightBlueButton fullWidth variant="contained" type="submit" disabled={loading || saving}>Update Level</LightBlueButton>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </ColourPaper>
            </Grid>
            <Grid item xs={4}>
                <ColourPaper>
                    <ValidatorForm onSubmit={updatePhone}>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="h5">Edit Phone Numbers</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="mobileNo">Mobile Number</InputLabel>
                                <TextValidator
                                    id="mobileNo"
                                    name="mobileNo"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={profile.mobileNo ?? ''}
                                    onChange={onProfileChange}
                                    InputProps={{ disabled: loading, readOnly: profile.isMember }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="phoneNo">Phone Number</InputLabel>
                                <TextValidator
                                    id="phoneNo"
                                    name="phoneNo"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={profile.phoneNo ?? ''}
                                    onChange={onProfileChange}
                                    InputProps={{ disabled: loading, readOnly: profile.isMember }}
                                />
                            </Grid>
                            {!profile.isMember &&
                                <Grid item xs={12}>
                                    <LightBlueButton fullWidth variant="contained" type="submit" disabled={loading || saving}>Update Phone</LightBlueButton>
                                </Grid>
                            }
                        </Grid>
                    </ValidatorForm>
                </ColourPaper>
            </Grid>
            <Grid item xs={4}>
                <ColourPaper>
                    <ValidatorForm onSubmit={updateAddress}>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="h5">Edit Address Details</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="addressLine1">Address Line 1</InputLabel>
                                <TextValidator
                                    id="addressLine1"
                                    name="addressLine1"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={profile.addressLine1 ?? ''}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={onProfileChange}
                                    InputProps={{ disabled: loading, readOnly: profile.isMember }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="addressLine2">Address Line 2</InputLabel>
                                <TextValidator
                                    id="addressLine2"
                                    name="addressLine2"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={profile.addressLine2 ?? ''}
                                    onChange={onProfileChange}
                                    InputProps={{ disabled: loading, readOnly: profile.isMember }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="addressLine3">Address Line 3</InputLabel>
                                <TextValidator
                                    id="addressLine3"
                                    name="addressLine3"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={profile.addressLine3 ?? ''}
                                    onChange={onProfileChange}
                                    InputProps={{ disabled: loading, readOnly: profile.isMember }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="addressLine4">Address Line 4</InputLabel>
                                <TextValidator
                                    id="addressLine4"
                                    name="addressLine4"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={profile.addressLine4 ?? ''}
                                    onChange={onProfileChange}
                                    InputProps={{ disabled: loading, readOnly: profile.isMember }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="county">County</InputLabel>
                                <TextValidator
                                    id="county"
                                    name="county"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={profile.county ?? ''}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={onProfileChange}
                                    InputProps={{ disabled: loading, readOnly: profile.isMember }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="country">Country</InputLabel>
                                <TextValidator
                                    id="country"
                                    name="country"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={profile.country ?? ''}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={onProfileChange}
                                    InputProps={{ disabled: loading, readOnly: profile.isMember }}
                                />
                            </Grid>
                            {!profile.isMember &&
                                <Grid item xs={12}>
                                    <LightBlueButton fullWidth variant="contained" type="submit" disabled={loading || saving}>Update Address</LightBlueButton>
                                </Grid>
                            }
                        </Grid>
                    </ValidatorForm>
                </ColourPaper>
            </Grid>
            <Snackbar open={message.message.length > 0} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={message.success ? "success" : "error"}>
                    {message.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}