﻿import * as React from 'react';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ColourPaper from '../Utilities/ColourPaper';
import LightBlueButton from '../Utilities/LightBlueButton';
import InputLabel from '@material-ui/core/InputLabel';
import { UserDropdownViewModel } from '../ViewModels/UserViewModel';
import MainOwnerDropdown from './MainOwnerDropdown';
import Bugsnag from '@bugsnag/js';

interface IProps {
    role: string;
}

export default function AddOrganisation(props: IProps) {
    const { role } = props;
    const [name, setName] = React.useState('');
    const [owner, setOwner] = React.useState<UserDropdownViewModel | null>(null);
    const [saving, setSaving] = React.useState(false);
    const [serverError, setError] = React.useState('');

    React.useEffect(() => {
        if (role !== 'Admin') {
            getUserName();
        }
    }, [role]);

    const getUserName = async () => {
        const user = await authService.getUser();
        const name = user ? user.name : '';
        setOwner({ id: user.sub, name });
    }

    const add = async () => {
        if (owner !== null) {
            setSaving(true);
            const token = await authService.getAccessToken();

            fetch(`Organisation/CreateOrganisation?name=${name}&id=${owner.id}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.text())
                .then(data => {
                    if (data.length > 0) {
                        setError(data);
                    } else {
                        setError('');
                        setName('');
                        setOwner(null);
                    }
                    setSaving(false);
                })
                .catch(error => {
                    Bugsnag.notify(error);
                    setSaving(false);
                    setError('A server error has occurred, please try again.');
                });
        }
    }

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const onOwnerChange = (event: any, newValue: UserDropdownViewModel | null) => {
        setOwner(newValue);
    }

    return (
        <ColourPaper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Add New Organisation</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="error">{serverError}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <ValidatorForm onSubmit={add}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="name">Organisation Name</InputLabel>
                            <TextValidator
                                id="name"
                                name="name"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={name}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onNameChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {role === 'Admin' ?
                                <MainOwnerDropdown mainOwner={owner} onChange={onOwnerChange} label="Owner" />
                                :
                                <React.Fragment>
                                    <InputLabel htmlFor="owner">Owner</InputLabel>
                                    <TextValidator
                                        id="owner"
                                        name="owner"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={owner === null ? '' : owner.name}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        readonly
                                    />
                                </React.Fragment>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <LightBlueButton fullWidth variant="contained" type="submit" disabled={saving || owner === null || owner.id === null}>Create</LightBlueButton>
                        </Grid>
                    </ValidatorForm>
                </Grid>
            </Grid>
        </ColourPaper>
    );
}