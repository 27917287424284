﻿import * as React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SalesReportViewModel from '../../ViewModels/SalesReportViewModel';

interface IProps {
    sale: SalesReportViewModel;
}

export default function SaleRow(props: IProps) {
    const { sale } = props;

    return (
        <TableRow tabIndex={-1}>
            <TableCell>{sale.formID}</TableCell>
            <TableCell>{sale.formName}</TableCell>
            <TableCell>{sale.organisationID}</TableCell>
            <TableCell>{sale.organisationName}</TableCell>
            <TableCell>{sale.memberID}</TableCell>
            <TableCell>{sale.userFirstName}</TableCell>
            <TableCell>{sale.userLastName}</TableCell>
            <TableCell>{new Date(sale.date).toLocaleDateString('en-GB')}</TableCell>
            <TableCell align="right">{sale.quantity}</TableCell>
            <TableCell align="right">{sale.netCost}</TableCell>
            <TableCell align="right">{sale.vat}</TableCell>
            <TableCell align="right">{sale.totalCost}</TableCell>
        </TableRow>
    );
}