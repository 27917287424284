﻿import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FormListViewModel from '../ViewModels/FormListViewModel';
import FormTableRow from './FormTableRow';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import ColourPaper from '../Utilities/ColourPaper';
import authService from '../api-authorization/AuthorizeService';
import SearchButton from '../Utilities/SearchButton';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import ProjectTemplateViewModel from '../ViewModels/ProjectTemplateViewModel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            borderBottom: `${theme.palette.secondary.main} solid 2px`
        },
        headers: {
            color: theme.palette.primary.main
        },
        hidden: {
            display: 'none'
        }
    }),
);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof FormListViewModel;
    label: string;
    numeric: boolean;
    property: string;
    hidden?: boolean;
}

const headCells: HeadCell[] = [
    { id: 'id', property: 'Id', numeric: false, disablePadding: false, label: '', hidden: true },
    { id: 'name', property: 'RiaiFormName', numeric: false, disablePadding: false, label: 'Document Name' },
    { id: 'credits', property: 'Credits', numeric: true, disablePadding: false, label: 'Available Credits' },
    { id: 'price', property: 'Price', numeric: true, disablePadding: false, label: 'Price (Exc VAT)' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof FormListViewModel) => void;
    order: Order;
    orderBy: string;
    children: React.ReactNode;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof FormListViewModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={headCell.hidden ? classes.hidden : ''}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.headers}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {props.children}
            </TableRow>
        </TableHead>
    );
}

interface FormTableProps {

    category: string;
    numOfRows: number;
}

export default function FormTable(props: FormTableProps) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof FormListViewModel>('id');
    const [forms, setForms] = React.useState<FormListViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [projectTemplates, setProjectTemplates] = React.useState<ProjectTemplateViewModel[]>([]);

    React.useEffect(() => {
        getForms();
        getTemplates();
    }, [order, orderBy]);

    const getForms = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        fetch(`Forms/GetForms?orderBy=${orderByProp}&order=${order}&search=${search}&category=${props.category}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setForms(data.rows);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const getTemplates = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Forms/GetProjectTemplatesForDocuments`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then((data: ProjectTemplateViewModel[]) => {
                setProjectTemplates(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof FormListViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            getForms();
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <ColourPaper>
                        <TableContainer>
                            <ResponsiveTable
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                aria-label="form table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                >
                                    <TableCell colSpan={2} align="right">
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            onChange={handleSearch}
                                            value={search}
                                            placeholder="Search..."
                                            onKeyDown={keyPress}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchButton aria-label="Search Documents" onClick={getForms} />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </TableCell>
                                </EnhancedTableHead>
                                <TableBody>
                                    {stableSort(forms, getComparator(order, orderBy))
                                        .map((row: FormListViewModel) => {
                                            return (<FormTableRow key={row.id} form={row} refreshTable={getForms} projectTemplates={projectTemplates} />);
                                        })}
                                    {loading &&
                                        <TableRow style={{ height: 53 * 5 }}>
                                            <TableCell colSpan={6} align="center">{loading && <CircularProgress color="secondary" />}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                    </ColourPaper>
                </Grid>
            </Grid>
        </div>
    );
}