﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import authService from '../api-authorization/AuthorizeService';
import ColourPaper from '../Utilities/ColourPaper';
import UserOrganisationViewModel from '../ViewModels/UserOrganisationViewModel';
import LightBlueButton from '../Utilities/LightBlueButton';
import { OrganisationContext } from '../Organisations/OrganisationContext';
import Bugsnag from '@bugsnag/js';

interface IProps {
    profileId?: string;
}

export default function UserOrganisations(props: IProps) {
    const { profileId } = props;
    const { refresh } = React.useContext(OrganisationContext);
    const [orgs, setOrgs] = React.useState<UserOrganisationViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoaded(false);

        fetch(`Organisation/GetUserOrganisations?id=${profileId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setOrgs(data);
            setLoaded(true);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoaded(true);
        });
    }

    const updateActiveOrg = async (org: number) => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`Organisation/UpdateActiveOrganisation?org=${org}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                setSaving(false);
                refresh();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    return (
        <ColourPaper>
            <Grid container spacing={2} justify="space-between">
                <Grid item xs={12}>
                    <Typography variant="h5">Organisations</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={loaded || orgs.length > 0}>
                        <Grid container spacing={2} justify="space-between">
                            {orgs.map((item) => {
                                return <React.Fragment key={item.id}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">{item.name}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography><b>User Level:</b></Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>{item.userLevel}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LightBlueButton
                                            fullWidth
                                            variant="contained"
                                            disabled={saving || item.active}
                                            onClick={() => updateActiveOrg(item.id)}
                                        >
                                            Make Active Organisation
                                        </LightBlueButton>
                                    </Grid>
                                </React.Fragment>;
                            })}
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
        </ColourPaper >
    );
}