﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import SquareButton from '../Utilities/SquareButton';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { BookshopProductViewModel } from '../BookShop/OrderViewmodel';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
    })
);

interface IProps {
    product: BookshopProductViewModel;
    open: boolean;
    close: (refresh: boolean) => void;
}

export default function BookProductDialog(props: IProps) {
    const classes = useStyles();
    const { product, open, close } = props;
    const [newProduct, setNewProduct] = React.useState(product);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        setNewProduct(product);
    }, [product]);

    const save = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        const body = newProduct;
        newProduct.weight === null || newProduct.weight.toString().match(/^ *$/) !== null ? newProduct.weight = 0 : newProduct.weight = newProduct.weight;

        fetch(`Bookshop/AddUpdateProduct`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(body)
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    setError(data);
                } else {
                    setNewProduct(product);
                    close(true);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value;

        if (event.target.name === 'cost') {
            value = event.target.valueAsNumber;
            value = value !== Number.NaN ? Math.round((value + Number.EPSILON) * 100) / 100 : 0;
        } else {
            value = event.target.value;
        }

        setNewProduct({
            ...newProduct,
            [event.target.name]: value
        });
    }

    const cancel = () => {
        setNewProduct(product);
        close(false);
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            PaperProps={{ className: classes.paper }}
        >
            <ValidatorForm onSubmit={save}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Description"
                                name="description"
                                value={newProduct.description}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                type="number"
                                margin="dense"
                                variant="outlined"
                                label="Cost"
                                name="cost"
                                value={newProduct.cost}
                                onChange={onChange}
                                inputProps={{
                                    min: 0.00,
                                    pattern: "^\d*(\.\d{0,2})?$",
                                    step: ".01"
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>
                                }}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                type="number"
                                margin="dense"
                                variant="outlined"
                                label="Weight (g)"
                                name="weight"
                                value={newProduct.weight}
                                onChange={onChange}
                                validators={['minNumber:0']}
                                errorMessages={['Weight cannot be negative']}
                            />
                        </Grid>
                    </Grid>
                    {error.length > 0 && <Typography color="error">{error}</Typography>}
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={cancel} variant="outlined" color="primary" disabled={saving}>Cancel</SquareButton>
                    <LightBlueButton variant="contained" type="submit" disabled={saving}>{newProduct.id === 0 ? 'Add' : 'Update'}</LightBlueButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}