﻿import * as React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import authService from '../api-authorization/AuthorizeService';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useLocation } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PaymentRequestDataViewModel from '../ViewModels/PaymentRequestDataViewModel';
import BookshopOrderViewModel from '../ViewModels/PurchaseViewModel - Copy';
import Bugsnag from '@bugsnag/js';

const height = 600;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'relative',
            height: height + 4 + 5 + theme.spacing(2),
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
        loading: {
            position: 'absolute',
            top: 'calc(50% - 10px)',
            left: 'calc(50% - 10px)',
            zIndex: 0
        },
    }));

interface RouteParams {
    orderId: string;
}

export default function BookshopPayment() {
    const classes = useStyles();
    const location = useLocation<RouteParams>();
    const { orderId } = location.state || { orderId: '' };
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [order, setOrder] = React.useState<BookshopOrderViewModel>(new BookshopOrderViewModel);
    const [loading, setLoading] = React.useState(true);
    const [paymentRequestData, setPaymentRequestData] = React.useState<PaymentRequestDataViewModel>(new PaymentRequestDataViewModel);

    const iframeRef = React.createRef<HTMLIFrameElement>();
    React.useEffect(() => {
        if (iframeRef.current) {
            var iframe: any = document.getElementById('realexIframe');

            if (paymentRequestData.MERCHANT_ID === "riai" && iframe.src.includes("Dummy")) {

                loadIFrame(paymentRequestData);
                setLoading(false);
            }
        }
    }, [iframeRef, paymentRequestData]);

    React.useEffect(() => {
        getPurchase();
    }, []);

    React.useEffect(() => {
        if (order.addressViewModel !== null && order.phoneNumberViewModel !== null && order.bookshopOrderItemViewModels !== null) {
            if (order.addressViewModel.streetAddress1 !== null && order.addressViewModel.streetAddress1.length > 0 && order.phoneNumberViewModel.phoneNumber !== null && order.phoneNumberViewModel.phoneNumber.length > 0 && order.bookshopOrderItemViewModels !== null && order.bookshopOrderItemViewModels.length > 0)
                realexHpp();
        }
    }, [order]);

    const getPurchase = async () => {
        const token = await authService.getAccessToken();

        fetch(`Bookshop/GetOrder?orderId=${orderId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setOrder(data);
            })
            .catch(error => {
                Bugsnag.notify(error);
            });
    }

    const realexHpp = async () => {
        const token = await authService.getAccessToken();
        const items = order.bookshopOrderItemViewModels.map(item => {
            return {
                id: item.id,
                numberOfUnits: item.numberOfUnits,
                unitCost: item.unitCost
            }
        });

        const data = JSON.stringify({
            items: items,
            orderId: orderId,
            billingAddress:
            {
                streetAddress1: order.addressViewModel.streetAddress1,
                streetAddress2: order.addressViewModel.streetAddress2,
                streetAddress3: order.addressViewModel.streetAddress3,
                city: order.addressViewModel.city,
                province: order.addressViewModel.province,
                postalCode: order.addressViewModel.postalCode,
            },
            countryCode: order.phoneNumberViewModel.countryCode,
            phoneNumber: order.phoneNumberViewModel.phoneNumber,
            email: order.email
        });

        fetch("api/Payments/RequestBookshopPayment",
            {
                method: "POST",
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                body: data
            }
        ).then(response => response.json()).then((data) => {
            setPaymentRequestData(data);
        }).catch(error => {
            Bugsnag.notify(error);
        });
    }

    const loadIFrame = (data: any) => {
        if (document.readyState === "complete") {
            window.RealexHpp.setHppUrl("https://pay.realexpayments.com/pay");
            window.RealexHpp.embedded.init("payButton", "realexIframe", window.location.origin + "/api/Payments/BookshopPaymentResponse", data);

            if (buttonRef && buttonRef.current) {
                buttonRef.current.click();
            }
        }
    }

    return (
        <Grid container justify="center" alignItems="center">

            {loading && <Grid item xs={12}><LinearProgress color="secondary" /></Grid>}

            <Fade in={!loading}>
                <Grid item sm={6} xs={12}>
                    <Button id="payButton" hidden ref={buttonRef} />
                    <Paper className={classes.paper}>
                        <div className={classes.loading}>
                            <CircularProgress color="secondary" />
                        </div>
                        <iframe ref={iframeRef} id="realexIframe" height={height + 4} width={'100%'} style={loading ? { opacity: 0, border: 'none', position: 'relative', zIndex: 100 } : { opacity: 1, border: 'none', position: 'relative', zIndex: 100 }} src="iframeDummyLoader.html" />
                    </Paper>
                </Grid>
            </Fade>
        </Grid>
    );
}