﻿export default class OrderViewmodel {
    public id: number = 0;
    public name: string = '';
    public memberId: string = '';
    public streetAddress1: string = '';
    public streetAddress2: string = '';
    public streetAddress3: string = '';
    public city: string = '';
    public province: string = '';
    public postalCode: string = '';
    public shipStreetAddress1: string = '';
    public shipStreetAddress2: string = '';
    public shipStreetAddress3: string = '';
    public shipCity: string = '';
    public shipProvince: string = '';
    public shipPostalCode: string = '';
    public email: string = '';
    public countryCode: string = '353';
    public number: string = '';
    public note: string = '';

    public order: OrderItemViewModel[] = [];

    public shipping: number = 0;
    public total: number = 0;
}

export class OrderItemViewModel {
    public id: number = 0;
    public productId: number = 0;
    public cost: number = 0;
    public quantity: number = 0;
    public weight: number = 0;
}

export class BookshopProductViewModel {
    public id: number = 0;
    public cost: number = 0;
    public description: string = '';
    public weight: number = 0;
    public quantity: number = 0;
}

export class ShippingViewModel {
    public id: number = 0;
    public cost: number = 0;
    public upperWeight: number = 0;
    public lowerWeight: number = 0;
    public description: string = '';
    public international: boolean = false;
}

export class BookshopReportViewModel {
    public orderId: string = '';
    public transactionId: string = '';
    public customerOrderId: string = '';
    public name: string = '';
    public memberId: string = '';
    public email: string = '';
    public description: string = '';
    public date: string = '';
    public quantity: number = 0;
    public cost: string = '';
    public total: string = '';
    public shipping: string = '';
}