﻿import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TemplateTableRow from './TemplateTableRow';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import ColourPaper from '../Utilities/ColourPaper';
import TemplateListViewModel from '../ViewModels/TemplateListViewModel';
import LightBlueButton from '../Utilities/LightBlueButton';
import { useLocation } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import SearchButton from '../Utilities/SearchButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            borderBottom: `${theme.palette.secondary.main} solid 2px`
        },
        headers: {
            color: theme.palette.primary.main
        }
    }),
);

type Order = 'asc' | 'desc';




interface HeadCell {
    disablePadding: boolean;
    id: keyof TemplateListViewModel;
    label: string;
    numeric: boolean;
    property: string;
}

const headCells: HeadCell[] = [
    { id: 'id', property: 'Id', numeric: true, disablePadding: false, label: 'Id' },
    { id: 'projectName', property: 'projectName', numeric: false, disablePadding: false, label: 'Project Name' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TemplateListViewModel) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof TemplateListViewModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.headers}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell />
            </TableRow>
        </TableHead>
    );
}

interface RouteParams {
    formId: number;
}

export default function TemplateTable() {
    const classes = useStyles();
    const location = useLocation<RouteParams>();
    const { formId } = location.state || {
        formId: 0
    };
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof TemplateListViewModel>('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [forms, setForms] = React.useState<TemplateListViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getTemplates();
    }, [page, order, orderBy, rowsPerPage, formId]);

    const getTemplates = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        fetch(`Forms/GetProjectTemplates?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&formId=${formId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setForms(data.rows);
            setCount(data.rowCount);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TemplateListViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const addTemplateLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <RouterLink to={{
                    pathname: `/Documents/NewTemplate`,
                }} ref={ref} {...itemProps} />
            )),
        [],
    );

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);
    const keyPress = (event: any) => {
        if (event.keyCode == 13) {
            getTemplates();
        }
    }
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography variant="body1">Create, edit and delete templates for reusable project details.</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <ColourPaper>
                        <Grid container justify="space-between" spacing={2}>
                            <Grid item>
                                <Typography variant="h4">{formId > 0 && forms.length > 0 ? forms[0].id + ' ' : ''}Project Templates</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <LightBlueButton variant="contained" component={addTemplateLink}>Add Template</LightBlueButton>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            onChange={handleSearch}
                                            value={search}
                                            placeholder="Search..."
                                            onKeyDown={keyPress}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchButton onClick={getTemplates} aria-label="Template Search" />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="template table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {forms.map((row: TemplateListViewModel) => {
                                        return (<TemplateTableRow key={row.id} projectTemplate={row} refresh={getTemplates} />);
                                        })}
                                    {emptyRows > 0 &&
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={5} align="center">{loading && <CircularProgress color="secondary" />}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </ColourPaper>
                </Grid>
            </Grid>
        </div>
    );
}