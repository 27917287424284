﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ColourPaper from '../Utilities/ColourPaper';
import ProfileViewModel from '../ViewModels/ProfileViewModel';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import Grow from '@material-ui/core/Grow';
import SigningFiles from './SigningFiles';
import { useParams } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SquareButton from '../Utilities/SquareButton';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import ChangePasswordViewModel from '../ViewModels/ChangePasswordViewModel';
import UserOrganisations from './UserOrganisations';
import Bugsnag from '@bugsnag/js';

interface RouteParams {
    profileId: string;
}

export default function ProfileDetails() {
    const { profileId } = useParams<RouteParams>();
    const [profile, setProfile] = React.useState(new ProfileViewModel());
    const [loaded, setLoaded] = React.useState(false);
    const [role, setRole] = React.useState('');
    const [canSign, setCanSign] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [passwordAnchorEl, setPasswordAnchorEl] = React.useState<null | HTMLElement>(null);
    const isPasswordDialogOpen = Boolean(passwordAnchorEl);
    const [password, setPassword] = React.useState(new ChangePasswordViewModel());
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        getDetails();
    }, []);

    ValidatorForm.addValidationRule('validPassword', (value) => {
        if (/^(?=.{6,})(?=.*[\W])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/.test(value)) {
            return true;
        }

        return false;
    });

    ValidatorForm.addValidationRule('passwordMatch', (value) => {
        if (value === password.newPassword) {
            return true;
        }
        return false;
    });

    const getDetails = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setRole(user ? user.role : '');
        setLoaded(false);

        fetch(`User/GetProfileDetails?id=${profileId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setProfile(data);
            setLoaded(true);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoaded(true);
        });

        fetch(`User/GetCanSign`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setCanSign(data);
            setLoaded(true);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoaded(true);
        });
    }

    const openDialog = (event: React.MouseEvent<HTMLElement>) => {
        setPasswordAnchorEl(event.currentTarget);
    }

    const closeDialog = () => {
        setPasswordAnchorEl(null);
        setPassword(new ChangePasswordViewModel());
        setError('');
    }

    const changePassword = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch('User/ChangePassword', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(password)
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    setError(data);
                } else {
                    closeDialog();
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
                setError("Unexpected error occurred, please try again.");
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword({
            ...password,
            [event.target.name]: event.target.value
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4">Profile</Typography>
            </Grid>
            {profile.email.length > 0 ?
                <React.Fragment>
                    <Grid item xs={4}>
                        <ColourPaper>
                            <Grid container spacing={2} justify="flex-end">
                                <Grid item xs={12}>
                                    <Typography variant="h5">User Details</Typography>
                                </Grid>
                                <Grow in={false}>
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} justify="space-between">
                                                <Grid item>
                                                    <Typography><b>Name</b></Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>{profile.firstName + ' ' + profile.lastName} </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} justify="space-between">
                                                <Grid item>
                                                    <Typography><b>Member No.</b></Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>{profile.memberNo}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} justify="space-between">
                                                <Grid item>
                                                    <Typography><b>Email</b></Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>{profile.email}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} justify="space-between">
                                                <Grid item>
                                                    <Typography><b>Mobile Number</b></Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>{profile.mobileNo}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} justify="space-between">
                                                <Grid item>
                                                    <Typography><b>Phone Number</b></Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography>{profile.phoneNo}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {(role !== 'Admin' || profileId === undefined) &&
                                            <Grid item xs={12}>
                                                <LightBlueButton fullWidth variant="contained" disabled={!loaded} onClick={openDialog}>Change Password</LightBlueButton>
                                            </Grid>
                                        }
                                    </React.Fragment>
                                </Grow>
                            </Grid>
                        </ColourPaper>
                    </Grid>
                    <Grid item xs={4}>
                        <ColourPaper>
                            <Grid container spacing={2} justify="flex-end">
                                <Grid item xs={12}>
                                    <Typography variant="h5">Address Details</Typography>
                                </Grid>
                                {loaded &&
                                    <Grow in={loaded}>
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} justify="space-between">
                                                    <Grid item>
                                                        <Typography><b>Address Line 1</b></Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>{profile.addressLine1}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} justify="space-between">
                                                    <Grid item>
                                                        <Typography><b>Address Line 2</b></Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>{profile.addressLine2}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} justify="space-between">
                                                    <Grid item>
                                                        <Typography><b>Address Line 3</b></Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>{profile.addressLine3}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} justify="space-between">
                                                    <Grid item>
                                                        <Typography><b>Address Line 4</b></Typography>
                                                    </Grid>

                                                    <Grid item>
                                                        <Typography>{profile.addressLine4}</Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} justify="space-between">
                                                    <Grid item>
                                                        <Typography><b>County</b></Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>{profile.county}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} justify="space-between">
                                                    <Grid item>
                                                        <Typography><b>Country</b></Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>{profile.country}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    </Grow>
                                }
                            </Grid>
                        </ColourPaper>
                    </Grid>
                    {((role === 'Admin' && profileId === undefined) || canSign) &&
                        <Grid item xs={4}>
                            <ColourPaper>
                                <Grid container spacing={2} justify="flex-end">
                                    <SigningFiles />
                                </Grid>
                            </ColourPaper>
                        </Grid>
                    }
                    <Grid item xs={4}>
                        <UserOrganisations profileId={profileId}/>
                    </Grid>
                    <Dialog
                        fullWidth
                        open={isPasswordDialogOpen}
                        onClose={closeDialog}
                        aria-labelledby="change-password-title"
                        PaperComponent={ColourPaper}
                    >
                        <ValidatorForm onSubmit={changePassword}>
                            <DialogTitle id="change-password-title">Change Password</DialogTitle>
                            {profile.memberNo !== null && profile.memberNo.length > 0 ?
                                <DialogContent dividers>
                                    <Typography>Please note:</Typography>
                                    <Typography>This will change your password across all RIAI services.</Typography>
                                </DialogContent>
                                :
                                <DialogContent dividers>
                                    <InputLabel htmlFor="currentPassword">Current Password</InputLabel>
                                    <TextValidator
                                        id="currentPassword"
                                        name="currentPassword"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        type="password"
                                        value={password.currentPassword}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        onChange={onChange}
                                    />
                                    <InputLabel htmlFor="newPassword">New Password</InputLabel>
                                    <TextValidator
                                        id="newPassword"
                                        name="newPassword"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        type="password"
                                        value={password.newPassword}
                                        validators={['required', 'validPassword']}
                                        errorMessages={['This field is required', 'Must be 6 characters long and contain 1 digit, 1 uppercase letter, 1 lowercase letter and 1 special character']}
                                        onChange={onChange}
                                    />
                                    <InputLabel htmlFor="repeatPassword">Repeat Password</InputLabel>
                                    <TextValidator
                                        id="repeatPassword"
                                        name="repeatPassword"
                                        variant="outlined"
                                        margin="normal"
                                        type="password"
                                        fullWidth
                                        value={password.repeatPassword}
                                        validators={['required', 'passwordMatch']}
                                        errorMessages={['This field is required', 'Passwords do not match']}
                                        onChange={onChange}
                                    />
                                    <Typography color="error">{error}</Typography>
                                </DialogContent>
                            }
                            <DialogActions>
                                <SquareButton onClick={closeDialog} variant="outlined" color="primary" disabled={saving}>Cancel</SquareButton>
                                {profile.memberNo !== null && profile.memberNo.length > 0 ?
                                    <LightBlueButton variant="contained" disabled={saving || !loaded} target="_blank" href={"https://secure.riai.ie/myriai/uiid456614285/amd604.xsp"}>Change</LightBlueButton>
                                    :
                                    <LightBlueButton type="submit" variant="contained" disabled={saving || !loaded}>Change</LightBlueButton>
                                }
                            </DialogActions>
                        </ValidatorForm>
                    </Dialog>
                </React.Fragment>
                :
                <Grid item xs={12}>
                    {
                        loaded &&
                        <Typography variant="h6">Profile not found.</Typography>
                    }
                </Grid>
            }
        </Grid>
    );
}