﻿import * as React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CreditReportViewModel from '../../ViewModels/CreditReportViewModel';

interface IProps {
    credit: CreditReportViewModel;
}

export default function CreditRow(props: IProps) {
    const { credit } = props;

    return (
        <TableRow tabIndex={-1}>
            <TableCell>{credit.formID}</TableCell>
            <TableCell>{credit.formName}</TableCell>
            <TableCell>{credit.organisationID}</TableCell>
            <TableCell>{credit.organisationName}</TableCell>
            <TableCell>{credit.ownerMemberId}</TableCell>
            <TableCell>{credit.ownerMemberFirstName}</TableCell>
            <TableCell>{credit.ownerMemberLastName}</TableCell>
            <TableCell align="right">{credit.remainingCredits}</TableCell>
        </TableRow>
    );
}