﻿import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import ColourPaper from '../Utilities/ColourPaper';
import UserViewModel from '../ViewModels/UserViewModel';
import UserTableRow from './UserTableRow';
import AddUser from './AddUser';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import SearchButton from '../Utilities/SearchButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            borderBottom: `${theme.palette.secondary.main} solid 2px`
        },
        headers: {
            color: theme.palette.primary.main
        },
        button: {
            color: '#fff'
        }
    }),
);


type Order = 'asc' | 'desc';


interface HeadCell {
    disablePadding: boolean;
    id: keyof UserViewModel;
    label: string;
    numeric: boolean;
    property: string;
}

const headCells: HeadCell[] = [
    { id: 'memberId', property: 'MemberId', numeric: false, disablePadding: false, label: 'Member Id.' },
    { id: 'firstName', property: 'FirstName', numeric: false, disablePadding: false, label: 'First Name' },
    { id: 'lastName', property: 'LastName', numeric: false, disablePadding: false, label: 'Last Name' },
    { id: 'email', property: 'Email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'userLevel', property: 'UserLevel', numeric: false, disablePadding: false, label: 'User Level' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof UserViewModel) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof UserViewModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.headers}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell />
            </TableRow>
        </TableHead>
    );
}

interface UserTableProps {
    admins: boolean;
    title: string;
    description: string;
}

export default function UserTable(props: UserTableProps) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof UserViewModel>('firstName');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [users, setUsers] = React.useState<UserViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [openAdd, setOpenAdd] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [role, setRole] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        getRole();
    }, []);

    React.useEffect(() => {
        getUsers();
    }, [page, order, orderBy, rowsPerPage, role]);

    const getRole = async () => {
        const user = await authService.getUser();
        setRole(user ? user.role : '');
    }

    const getUsers = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const url = props.admins ? "GetAdminUsers" : "GetUsers";

        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        fetch(`User/${url}?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setUsers(data.rows);
            setCount(data.rowCount);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof UserViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const toggleDialog = () => {
        setOpenAdd(!openAdd);
    }

    const closeDialog = (refresh: boolean) => {
        setOpenAdd(false);

        if (refresh) {
            getUsers();
        }
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const keyPress = (event: any) => {
        if (event.keyCode == 13) {
            getUsers();
        }
    }
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography variant="body1">{props.description}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ColourPaper>
                        <Grid container justify="space-between" spacing={2}>
                            <Grid item>
                                <Typography variant="h4">{props.title}</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        {!props.admins &&
                                            <LightBlueButton variant="contained" onClick={toggleDialog}>Add User To Organisation</LightBlueButton>
                                        }
                                        {props.admins &&
                                            <LightBlueButton variant="contained" onClick={toggleDialog}>Add Admin User</LightBlueButton>
                                        }
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            onChange={handleSearch}
                                            value={search}
                                            placeholder="Search..."
                                            onKeyDown={keyPress}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchButton aria-label="Search Users" onClick={getUsers} />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="form table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {users.map((row: UserViewModel, index) => {
                                        return (<UserTableRow key={index} user={row} refresh={getUsers} />);
                                    })}
                                    {emptyRows > 0 &&
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={5} align="center">{loading && <CircularProgress color="secondary" />}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </ColourPaper>
                </Grid>
            </Grid>
            <AddUser open={openAdd} onClose={closeDialog} />
        </div>
    );
}