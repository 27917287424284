﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LightBlueButton from '../Utilities/LightBlueButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import ColourPaper from '../Utilities/ColourPaper';
import Divider from '@material-ui/core/Divider';
import TableHead from '@material-ui/core/TableHead';
import CartItem from './CartItem';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink, LinkProps as RouterLinkProps, useHistory } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import { useCartState } from './CartContext';
import { UtilityMethods } from '../Utilities/UtilityMethods';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            backgroundColor: theme.palette.secondary.main,
            height: 2
        },
    }));

export default function ShoppingCart() {
    const classes = useStyles();
    const [vat, setVat] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const history = useHistory();
    const { cart } = useCartState();


    React.useEffect(() => {
        getVat();
    }, []);

    const getVat = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch('Checkout/GetVat', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setVat(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const addPurchase = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();
        
        fetch('Checkout/AddPurchase', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(cart)
        })
            .then(response => response.json())
            .then(data => {
                setSaving(false);
                history.push({
                    pathname: '/Checkout',
                    state: { purchaseId: data }
                });
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const continueLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Documents" ref={ref} {...itemProps} />
    ));

    const items = cart.map((item, index) => {
        return <CartItem key={index} item={item}/>
    });

    const subTotal: number = cart.map(({ unitCost, numberOfUnits }) => (unitCost * numberOfUnits)).reduce((sum, i) => sum + i, 0);
    const vatCost: number = cart.map(({ unitCost, numberOfUnits }) => (((unitCost * vat) / 100) * numberOfUnits)).reduce((sum, i) => sum + i, 0);

    return (
        <ColourPaper>
            <Grid container spacing={2} justify="flex-end">
                <Grid item xs={12}>
                    <Typography variant="h4">Shopping Cart</Typography>
                </Grid>
                <Grid item xs={12}><Divider className={classes.divider} /></Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table aria-label="shopping cart">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Unit Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items}
                                <TableRow>
                                    <TableCell rowSpan={3} colSpan={2} />
                                    <TableCell>Subtotal</TableCell>
                                    <TableCell align="right">€{UtilityMethods.round2Decimals(subTotal).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>VAT</TableCell>
                                    <TableCell align="right">€{UtilityMethods.round2Decimals(vatCost).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell align="right">€{(subTotal + vatCost).toFixed(2)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <LightBlueButton variant="contained" component={continueLink}>Continue Shopping</LightBlueButton>
                </Grid>
                <Grid item>
                    <LightBlueButton variant="contained" disabled={cart.length <= 0 || loading || saving} onClick={addPurchase}>Checkout Now</LightBlueButton>
                </Grid>
            </Grid>
        </ColourPaper>
    );
}