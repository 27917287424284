import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
    apiKey: '8f9fedf052c345f931ad837e9bb1f8d8',
    plugins: [new BugsnagPluginReact()]
})

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

declare global {
    interface Window { RealexHpp: any; }
}

ReactDOM.render(
    <React.Fragment>
        {ErrorBoundary !== undefined &&
            <ErrorBoundary>
                <BrowserRouter basename={baseUrl ?? undefined}>
                    <App />
                </BrowserRouter>
            </ErrorBoundary>
        }

        {ErrorBoundary === undefined &&
            <BrowserRouter basename={baseUrl ?? undefined}>
                <App />
            </BrowserRouter>
        }

    </React.Fragment>,
    rootElement);

