﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import ColourPaper from '../Utilities/ColourPaper';
import Divider from '@material-ui/core/Divider';
import TableHead from '@material-ui/core/TableHead';
import { PurchaseItemViewModel } from '../ViewModels/CartItemViewModel';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useParams } from 'react-router';
import { useCartDispatch} from './CartContext';
import LightBlueButton from '../Utilities/LightBlueButton';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            backgroundColor: theme.palette.secondary.main,
            height: 2
        },
    }));

interface RouteParams {
    orderId: string;
}

export default function CompleteOrder() {
    const { orderId } = useParams<RouteParams>();
    const classes = useStyles();
    const [cart, setCart] = React.useState<PurchaseItemViewModel[]>([]);
    const [transactionId, setTransactionId] = React.useState('');
    const [invoiceId, setInvoiceId] = React.useState(0);
    const [vat, setVat] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const cartDispatch = useCartDispatch();

    React.useEffect(() => {
        getCartItems();
        getVat();
        cartDispatch({ type: 'clear' });
    }, []);

    const getVat = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch('Checkout/GetVat', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setVat(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const getCartItems = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch(`Checkout/GetOrder?orderId=${orderId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setTransactionId(data.transactionId);
                setCart(data.cart);
                setInvoiceId(data.id);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const items = cart.map(item => {
        return <TableRow key={item.id}>
            <TableCell>{item.formName}</TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell>{item.numberOfUnits}</TableCell>
            <TableCell align="right">€{item.unitCost.toFixed(2)}</TableCell>
        </TableRow>
    });

    const subTotal = cart.map(({ unitCost, numberOfUnits }) => unitCost * numberOfUnits).reduce((sum, i) => sum + i, 0);
    const vatCost: number = cart.map(({ unitCost, numberOfUnits }) => (((unitCost * vat) / 100) * numberOfUnits)).reduce((sum, i) => sum + i, 0);

    return (
        <React.Fragment>
            {loading ?
                <LinearProgress color="secondary" />
                :
                <ColourPaper>
                    <Grid container spacing={2} justify="flex-end">
                        <Grid item xs={12}>
                            <Typography variant="h4">Purchase Confirmation</Typography>
                        </Grid>
                        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table aria-label="purchase confirmation">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Order Reference</TableCell>
                                            <TableCell>Transaction ID</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{invoiceId}</TableCell>
                                            <TableCell>{transactionId}</TableCell>
                                            <TableCell align="right"><LightBlueButton disabled={loading || invoiceId === 0} variant="contained" size="small" target="blank" href={`Invoice/GetInvoicePdf?id=${invoiceId}&download=${false}`}>View Invoice</LightBlueButton></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table aria-label="purchase confirmation">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Item</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>Unit Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items}
                                        <TableRow>
                                            <TableCell rowSpan={3} colSpan={2} />
                                            <TableCell>Subtotal</TableCell>
                                            <TableCell align="right">€{subTotal.toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>VAT</TableCell>
                                            <TableCell align="right">€{vatCost.toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Total</TableCell>
                                            <TableCell align="right">€{(subTotal + vatCost).toFixed(2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </ColourPaper>
            }
        </React.Fragment>
    );
}