﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.primary.main,
            textDecoration: "none",
            '&:hover': {
                color: theme.palette.tertiary.main
            }
        },
    }));

export default function Footer() {
    const classes = useStyles();

    return (
        <Grid container spacing={4}>
            <Grid item xs={2}>
                <Grid container spacing={1} justify="space-between">
                    <Grid item xs={12}>
                        <a href="https://www.riai.ie/" target="_blank"><img src="logo.png" alt="RIAI" style={{ width: '100%' }} /></a>
                    </Grid>
                    <Grid item>
                        <a href="https://www.facebook.com/RIAI.ie/" target="_blank"><FacebookIcon color="primary" /></a>
                    </Grid>
                    <Grid item>
                        <a href="https://twitter.com/RIAIOnline" target="_blank"><TwitterIcon color="primary" /></a>
                    </Grid>
                    <Grid item>
                        <a href="https://ie.linkedin.com/company/riai" target="_blank"><LinkedInIcon color="primary" /></a>
                    </Grid>
                    <Grid item>
                        <a href="https://www.instagram.com/riaionline/" target="_blank"><InstagramIcon color="primary" /></a>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <Typography gutterBottom color="primary" variant="h6">Main Pages</Typography>
                <Link className={classes.link} to="/"><Typography gutterBottom>Home</Typography></Link>
                <Link className={classes.link} to="/Documents"><Typography gutterBottom>Documents</Typography></Link>
                <Link className={classes.link} to="/Invoices"><Typography gutterBottom>Invoices</Typography></Link>
                <Link className={classes.link} to="/FAQ"><Typography gutterBottom>FAQ</Typography></Link>
            </Grid>
            <Grid item xs={2}>
                <Typography gutterBottom color="primary" variant="h6">Policy</Typography>
                <Link className={classes.link} to="/TermsofUse"><Typography gutterBottom >Terms of Use</Typography></Link>
                <Link className={classes.link} to="/PrivacyPolicy"><Typography gutterBottom>Privacy Policy</Typography></Link>
            </Grid>
        </Grid>
    );
}