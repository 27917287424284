﻿import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import ColourPaper from '../Utilities/ColourPaper';
import { SortFaqViewModel } from '../ViewModels/FaqViewModel';
import FaqTableRow from './FaqTableRow';
import QuestionEditor from './QuestionEditor';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import SearchButton from '../Utilities/SearchButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            borderBottom: `${theme.palette.secondary.main} solid 2px`
        },
        headers: {
            color: theme.palette.primary.main
        },
    }),
);

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof SortFaqViewModel;
    label: string;
    numeric: boolean;
    property: string;
}

const headCells: HeadCell[] = [
    { id: 'category', property: 'Category', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'question', property: 'Question', numeric: false, disablePadding: false, label: 'Question' },
    { id: 'answer', property: 'Answer', numeric: false, disablePadding: false, label: 'Answer' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof SortFaqViewModel) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof SortFaqViewModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.headers}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell colSpan={2} />
            </TableRow>
        </TableHead>
    );
}

export default function FaqTable() {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof SortFaqViewModel>('category');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [faqs, setFaqs] = React.useState<SortFaqViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [openEditor, setOpenEditor] = React.useState(false);
    const [editingQuestion, setEditingQuestion] = React.useState<number>(0);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [role, setRole] = React.useState('');

    React.useEffect(() => {
        getRole();
        getFaqs();
    }, []);

    React.useEffect(() => {
        getFaqs();
    }, [page, order, orderBy, rowsPerPage]);

    const getRole = async () => {
        const user = await authService.getUser();
        setRole(user ? user.role : '');
    }

    const getFaqs = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        fetch(`Faq/GetFaqs?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${encodeURIComponent(search)}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setFaqs(data.rows);
            setCount(data.rowCount);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof SortFaqViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const toggleEditor = (id: number | undefined, refresh: boolean) => {
        if (id !== undefined) {
            setEditingQuestion(id);
        }
        setOpenEditor(!openEditor);

        if (refresh) {
            getFaqs();
        }
    }

    const newQuestion = () => {
        toggleEditor(0, false);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);
    const keyPress = (event: any) => {
        if (event.keyCode == 13) {
            getFaqs();
        }
    }
    return (
        <div className={classes.root}>
            <ColourPaper>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography variant="h4">FAQ Admin</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            {role && role === "Admin" &&
                                <Grid item>
                                    <LightBlueButton variant="contained" onClick={newQuestion}>Add Question</LightBlueButton>
                                </Grid>
                            }
                            <Grid item>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    onChange={handleSearch}
                                    value={search}
                                    placeholder="Search..."
                                    onKeyDown={keyPress}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchButton onClick={getFaqs} aria-label="Search FAQs" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="faq table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {faqs.map((row: SortFaqViewModel) => {
                                return (<FaqTableRow key={row.id} faq={row} edit={toggleEditor} refresh={getFaqs} />);
                            })}
                            {emptyRows > 0 &&
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={5} align="center">{loading && <CircularProgress color="secondary" />}</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </ColourPaper>
            <QuestionEditor questionId={editingQuestion ?? 0} open={openEditor} onClose={toggleEditor} />
        </div>
    );
}