﻿export default class ProjectTemplateViewModel
{
    public id: number = 0;
    public projectName: string = '';
    public architectName: string = '';
    public architectAddress: string = '';
    public clientName: string = '';
    public clientAddress: string = '';
    public projectJobReference: string = '';
    public projectAddress: string = '';
    public projectDescription: string = '';
}