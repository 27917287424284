﻿export default class EditUserViewModel {
    public id: string = '';
    public isMember: boolean = false;
    public userLevel: number = 0;
    public mobileNo: string = '';
    public phoneNo: string = '';
    public addressLine1: string = '';
    public addressLine2: string = '';
    public addressLine3: string = '';
    public addressLine4: string = '';
    public county: string = '';
    public country: string = '';
}