﻿import * as React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import UserViewModel from '../ViewModels/UserViewModel';
import LightBlueButton from '../Utilities/LightBlueButton';
import Grid from '@material-ui/core/Grid';
import SquareButton from '../Utilities/SquareButton';
import authService from '../api-authorization/AuthorizeService';
import { useHistory, Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import WarningDialog from '../Utilities/WarningDialog';
import Bugsnag from '@bugsnag/js';

interface IProps {
    user: UserViewModel;
    refresh: () => void;
}

export default function UserTableRow(props: IProps) {
    const { user, refresh } = props;
    const history = useHistory();
    const [saving, setSaving] = React.useState(false);
    const [role, setRole] = React.useState('');
    const [openWarning, setOpen] = React.useState(false);

    React.useEffect(() => {
        updateAuth();
    }, []);

    const remove = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`User/RemoveUser?id=${user.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length <= 0) {
                    setOpen(false);
                    refresh();
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const updateAuth = async () => {
        const currUser = await authService.getUser();
        setRole(currUser ? currUser.role : '');
    }

    const viewProfile = () => {
        history.push("/Profile/" + user.id);
    }

    const editLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
                <RouterLink
                    ref={ref}
                    to={{
                        pathname: `/Users/Edit`,
                        state: {
                            userId: user.id
                        }
                    }}
                    {...props} />
            )),
        [user.id]);

    const toggleWarning = () => {
        setOpen(!openWarning);
    }

    return (
        <TableRow tabIndex={-1}>
            <TableCell>{user.memberId}</TableCell>
            <TableCell>{user.userLevel !== "Pending" && user.firstName}</TableCell>
            <TableCell>{user.userLevel !== "Pending" && user.lastName}</TableCell>
            <TableCell>{(user.userLevel !== "Pending" || user.memberId.length <= 0) && user.email}</TableCell>
            <TableCell>{user.userLevel}</TableCell>
            <TableCell align="right">
                <Grid container spacing={1} justify="flex-end" style={{ flexWrap: 'nowrap' }}>
                    {user.userLevel !== "Admin" && !(role !== "Admin" && user.userLevel === "Owner") &&
                        <Grid item>
                            <SquareButton notextwrap={true} variant="outlined" color="primary" fullWidth size="small" disabled={saving} onClick={toggleWarning}>Remove From Organisation</SquareButton>
                            <WarningDialog open={openWarning} close={toggleWarning} okClick={remove} text={`This will remove ${user.firstName} from this organisation.`} buttonText="Remove" />
                        </Grid>
                    }

                    {(role !== "Admin" && user.userLevel !== "Pending") &&
                        <Grid item>
                            <LightBlueButton notextwrap={true} variant="contained" fullWidth size="small" disabled={saving} component={editLink}>Edit User Level</LightBlueButton>
                        </Grid>
                    }

                    {(role === "Admin" && user.userLevel !== "Pending") &&
                        <React.Fragment>
                            <Grid item>
                                <LightBlueButton notextwrap={true} variant="contained" fullWidth size="small" disabled={saving} component={editLink}>Edit User Level & Profile</LightBlueButton>
                            </Grid>

                            <Grid item>
                                <LightBlueButton notextwrap={true} variant="contained" fullWidth size="small" onClick={viewProfile} disabled={saving}>View Profile</LightBlueButton>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </TableCell>
        </TableRow>
    );
}