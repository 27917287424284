﻿import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import LightBlueButton from '../Utilities/LightBlueButton';
import UserViewModel from '../ViewModels/UserViewModel';
import SquareButton from '../Utilities/SquareButton';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import authService from '../api-authorization/AuthorizeService';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import blue from '@material-ui/core/colors/blue';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
        icon: {
            marginRight: theme.spacing(1),
            color: blue[400]
        },
        alert: {
            display: 'flex',
            paddingBottom: theme.spacing(2)
        }
    })
);

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function AddUser(props: IProps) {
    const classes = useStyles();
    const { open, onClose } = props;
    const [newUser, setUser] = React.useState(new UserViewModel());
    const [userLevelsAdd, setUserLevelsAdd] = React.useState<DropdownViewModel[]>([]);
    const [userLevelsCreate, setUserLevelsCreate] = React.useState<DropdownViewModel[]>([]);
    const [userLevel, setUserLevel] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState('');
    const [creating, setCreating] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`User/GetUserLevels`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setUserLevelsAdd(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });

        fetch(`User/GetUserLevelsCreate`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setUserLevelsCreate(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const close = () => {
        setUser(new UserViewModel());
        setCreating(false);
        onClose(false);
    }

    const save = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        const user = newUser;
        if (creating)
            newUser.userLevel = userLevelsCreate.find(f => f.key === userLevel)?.value as string;
        else
            newUser.userLevel = userLevelsAdd.find(f => f.key === userLevel)?.value as string;

        fetch('User/AddUser', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(user)
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);
                if (data.length > 0) {
                    if (data == 'User does not exist') {
                        setCreating(true);
                    } else {
                        setError(data);
                    }
                } else {
                    setUser(new UserViewModel());
                    onClose(true);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const create = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        const user = newUser;
        if (creating)
            newUser.userLevel = userLevelsCreate.find(f => f.key === userLevel)?.value as string;
        else
            newUser.userLevel = userLevelsAdd.find(f => f.key === userLevel)?.value as string;

        fetch('User/CreateUser', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(user)
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);
                if (data.length > 0) {
                    setError(data);
                } else {
                    setUser(new UserViewModel());
                    onClose(true);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...newUser,
            [event.target.name]: event.target.value
        });
    }

    const onLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setUserLevel(value);
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="add-user-title"
            PaperProps={{ className: classes.paper }}
        >
            <ValidatorForm onSubmit={creating ? create : save}>
                <DialogTitle id="add-user-title">{creating ? 'Create New User' : 'Add User to Organisation'}</DialogTitle>
                <DialogContent dividers>
                    {creating ?
                        <Typography className={classes.alert}><InfoOutlinedIcon className={classes.icon} /> This user does not exist. This will create a new user and send them an email inviting them to join.</Typography>
                        :
                        <Typography className={classes.alert}><InfoOutlinedIcon className={classes.icon} /> The user will be sent an email inviting them to confirm and join the organisation.</Typography>
                    }
                    <InputLabel htmlFor="email">{creating ? 'Email' : 'Email / Membership Number'}</InputLabel>
                    <TextValidator
                        id="email"
                        name="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={newUser.email}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={onChange}
                    />
                    {creating &&
                        <React.Fragment>
                            <InputLabel htmlFor="firstName">First Name</InputLabel>
                            <TextValidator
                                id="firstName"
                                name="firstName"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={newUser.firstName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                            <InputLabel htmlFor="lastName">Last Name</InputLabel>
                            <TextValidator
                                id="lastName"
                                name="lastName"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={newUser.lastName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </React.Fragment>
                    }
                    <InputLabel htmlFor="userLevel">User Level</InputLabel>
                    <SelectValidator
                        id="userLevel"
                        name="userLevel"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={userLevel ?? ''}
                        validators={['required']}
                        errorMessages={['This field is required']}
                        onChange={onLevelChange}
                        InputProps={{
                            disabled: loading,
                            startAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress size={20} /> : null}
                                </React.Fragment>
                            )
                        }}
                    >
                        {creating &&
                            userLevelsCreate.map((item) => {
                                return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                            })
                        }
                        {!creating &&
                            userLevelsAdd.map((item) => {
                                return <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                            })
                        }

                    </SelectValidator>
                    <Typography color="error">{error}</Typography>
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={close} variant="outlined" color="primary" disabled={loading || saving}>Cancel</SquareButton>
                    <LightBlueButton type="submit" variant="contained" disabled={loading || saving}>{creating ? 'Create' : 'Add'}</LightBlueButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}