﻿export default class FaqViewModel {
    public id: number = 0;
    public category: number = 0;
    public question: string = '';
    public answer: string = '';
    public popular: boolean = false;
}

export class SortFaqViewModel {
    public id: number = 0;
    public category: string = '';
    public question: string = '';
    public answer: string = '';
}