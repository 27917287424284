import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import authService from '../../api-authorization/AuthorizeService';
import LightBlueButton from '../../Utilities/LightBlueButton';
import { FormElementListViewModel } from '../../ViewModels/FormElementViewModel';
import FormTypeDropdown from './FormTypeDropdown';
import NewFormElement from './NewFormElement';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormElementsTableRow from './FormElementsTableRow';
import ColourPaper from '../../Utilities/ColourPaper';
import { UtilityMethods } from '../../Utilities/UtilityMethods';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            borderBottom: `${theme.palette.secondary.main} solid 2px`
        },
        headers: {
            color: theme.palette.primary.main
        }
    }),
);

interface HeadCell {
    disablePadding: boolean;
    id: keyof FormElementListViewModel;
    label: string;
    numeric: boolean;
    property: string;
}

const headCells: HeadCell[] = [
    { id: 'order', property: 'order', numeric: false, disablePadding: false, label: 'Order' },
    { id: 'friendlyName', property: 'FriendlyName', numeric: false, disablePadding: false, label: 'Friendly Name' },
    { id: 'formElementType', property: 'FormElementType', numeric: false, disablePadding: false, label: 'Element Type' },
    { id: 'placeholder', property: 'Placeholder', numeric: false, disablePadding: false, label: 'Placeholder' },

];

export default function FormBuilder() {
    const classes = useStyles();
    const [formElements, setElements] = React.useState<FormElementListViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [form, setForm] = React.useState(0);
    const [editElement, setEditElement] = React.useState(0);
    const [visibleInShop, setVisibleInShop] = React.useState(true);

    React.useEffect(() => {
        if (form > 0) {
            getElements();
            getVisibility();
        }
    }, [form]);

    const getElements = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`FormBuilder/GetFormElements?formType=${form}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setElements(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const getVisibility = async () => {
        const token = await authService.getAccessToken();
        fetch(`FormBuilder/GetFormVisibility?id=${form}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setVisibleInShop(data);
        }).catch((error) => {
            Bugsnag.notify(error);
        });
    }

    const changeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setForm(value);
    }

    const toggleOpen = () => {
        setOpen(true);
    }

    const onRefresh = () => {
        getElements();
        window.scrollTo(0, document.body.scrollHeight);
    }

    const onClose = (refresh: boolean) => {
        if (refresh) {
            onRefresh();
        }

        setOpen(false);
    }

    const onEdit = (id: number) => {
        setEditElement(id);
        setOpen(!open);
    }

    const addAdvice = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target !== null && event.target.files !== null) {
            const file = { file: event.target.files[0] };
            const token = await authService.getAccessToken();
            setLoading(true);

            let formData = new FormData();
            formData.append('id', form.toString());
            UtilityMethods.convertModelToFormData(file, formData);

            fetch('FormBuilder/UploadAdviceNotes', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                body: formData
            }).then(response => {
                setLoading(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
        }
    }

    const addAuxiliary = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target !== null && event.target.files !== null) {
            const file = { file: event.target.files[0] };
            const token = await authService.getAccessToken();
            setLoading(true);

            let formData = new FormData();
            formData.append('id', form.toString());
            UtilityMethods.convertModelToFormData(file, formData);

            fetch('FormBuilder/UploadAuxiliaryNotes', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                body: formData
            }).then(response => {
                setLoading(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
        }
    }

    const addWord = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target !== null && event.target.files !== null) {
            const file = { file: event.target.files[0] };
            const token = await authService.getAccessToken();
            setLoading(true);

            let formData = new FormData();
            formData.append('id', form.toString());
            UtilityMethods.convertModelToFormData(file, formData);

            fetch('FormBuilder/UploadWordFile', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                body: formData
            }).then(response => {
                setLoading(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
        }
    }

    const addPreview = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null) {
            const file = { file: event.target.files[0] };
            const token = await authService.getAccessToken();
            setLoading(true);

            let formData = new FormData();
            formData.append('id', form.toString());
            UtilityMethods.convertModelToFormData(file, formData);

            fetch('FormBuilder/UploadPreview', {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                body: formData
            }).then(response => {
                setLoading(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoading(false);
            });
        }
    }

    const toggleVisibility = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`FormBuilder/ToggleFormVisibilty?id=${form}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        }).then(response => {
            setLoading(false);
            getVisibility();
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const previewForm = async () => {

        const token = await authService.getAccessToken();

        let url = `Forms/PreviewFormDummyData?formTypeId=` + form.toString();

        fetch(url, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
        })
            .then(response =>
                response.blob())
            .then(blob => {
                var file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            })
            .catch((error) => {
                Bugsnag.notify(error);
            });
    }


    return (
        <React.Fragment>
            <div className={classes.root}>
                <ColourPaper>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography variant="h4">Form Elements</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <FormTypeDropdown id={form} onChange={changeForm} />
                        </Grid>
                        {form > 0 &&
                            <React.Fragment>
                                <Grid item xs={12} sm={8}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <input id={"preview"} name="preview" type="file" hidden onChange={addPreview} />
                                            <label htmlFor={"preview"}>
                                                <LightBlueButton fullWidth variant="contained" component="span" disabled={loading}>Add Preview</LightBlueButton>
                                            </label>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <input id={"adviceNotes"} name="adviceNotes" type="file" hidden onChange={addAdvice} />
                                            <label htmlFor={"adviceNotes"}>
                                                <LightBlueButton fullWidth variant="contained" component="span" disabled={loading}>Add Advice Notes</LightBlueButton>
                                            </label>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <input id={"auxiliaryNotes"} name="auxiliaryNotes" type="file" hidden onChange={addAuxiliary} />
                                            <label htmlFor={"auxiliaryNotes"}>
                                                <LightBlueButton fullWidth variant="contained" component="span" disabled={loading}>Add Auxiliary Notes</LightBlueButton>
                                            </label>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <input id={"wordfile"} name="wordfile" type="file" hidden onChange={addWord} />
                                            <label htmlFor={"wordfile"}>
                                                <LightBlueButton fullWidth variant="contained" component="span" disabled={loading}>Upload New Word Doc</LightBlueButton>
                                            </label>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <LightBlueButton fullWidth variant="contained" onClick={toggleOpen} disabled={loading}>Add Element</LightBlueButton>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <LightBlueButton fullWidth variant="contained" onClick={previewForm} disabled={loading}>See Example</LightBlueButton>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <LightBlueButton fullWidth variant="contained" onClick={toggleVisibility} disabled={loading}>{visibleInShop ? 'Hide In Shop' : 'Show In Shop'}</LightBlueButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="form table"
                        >
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.numeric ? 'right' : 'left'}
                                            padding={headCell.disablePadding ? 'none' : 'default'}

                                        >
                                            {headCell.label}

                                        </TableCell>
                                    ))}
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formElements.map((row: FormElementListViewModel, index: number) => {
                                    return (<FormElementsTableRow key={row.id} formTypeId={form} element={row} edit={onEdit} refresh={getElements} last={index === formElements.length - 1} />);
                                })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <LightBlueButton fullWidth variant="contained" onClick={toggleOpen} disabled={loading}>Add Element</LightBlueButton>
                </ColourPaper>
            </div>
            <NewFormElement elementId={editElement} formId={form} open={open} onClose={onClose} onRefresh={onRefresh} />
        </React.Fragment>
    );
}