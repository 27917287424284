﻿import * as React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { BookshopReportViewModel } from '../../BookShop/OrderViewmodel';

interface IProps {
    sale: BookshopReportViewModel;
}

export default function BookshopRow(props: IProps) {
    const { sale } = props;

    return (
        <TableRow>
            <TableCell>{sale.orderId}</TableCell>
            <TableCell>{sale.transactionId}</TableCell>
            <TableCell>{sale.customerOrderId}</TableCell>
            <TableCell>{sale.name}</TableCell>
            <TableCell>{sale.memberId}</TableCell>
            <TableCell>{sale.email}</TableCell>
            <TableCell>{sale.description}</TableCell>
            <TableCell>{new Date(sale.date).toLocaleDateString('en-GB')}</TableCell>
            <TableCell align="right">{sale.quantity}</TableCell>
            <TableCell align="right">{sale.cost}</TableCell>
            <TableCell align="right">{sale.shipping}</TableCell>
            <TableCell align="right">{sale.total}</TableCell>
        </TableRow>
    );
}