﻿import FormElementViewModel from "./FormElementViewModel";

export default class FormViewModel {
    public id: number = 0;
    public formType: number = 0;
    public name: string = '';
    public userReference: string = '';
    public formElements: FormElementViewModel[] = [];
    public adviceNotes: string = '';
    public category: string = '';
    public maxPreviews: number = 0;
    public previewCount: number = 0;
}