﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ColourPaper from '../Utilities/ColourPaper';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import Bugsnag from '@bugsnag/js';

export default function AdjustVat() {
    const [vat, setVat] = React.useState(0);
    const [saving, setSaving] = React.useState(false);
    const [serverError, setError] = React.useState('');

    React.useEffect(() => {
        getCurrent();
    }, []);

    const getCurrent = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`Invoice/GetCurrentVat`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setSaving(false);
                setVat(data);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const submit = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`Invoice/AdjustVat?vat=${vat}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => {
                setSaving(false);
                setError('');
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
                setError('A server error has occurred, please try again.');
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.valueAsNumber.toFixed(2));
        setVat(value);
    }

    return (
        <ColourPaper>
            <ValidatorForm onSubmit={submit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>Adjust VAT</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="info">Up to two decimal places allowed.</Alert>
                    </Grid>
                    <Grid item xs={12}>
                        {serverError.length > 0 && <Alert severity="error">{serverError}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <TextValidator
                            id="vat"
                            name="vat"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            type="number"
                            value={vat}
                            validators={['required', 'isPositive']}
                            errorMessages={['This field is required', 'Invalid']}
                            inputProps={{
                                min: 0.00,
                                step: ".01"
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LightBlueButton fullWidth variant="contained" type="submit" disabled={saving}>Save</LightBlueButton>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </ColourPaper>
    );
}