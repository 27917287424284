﻿import * as React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { TransitionProps } from '@material-ui/core/transitions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            width: '100%',
            height: 150,
            backgroundColor: '#f1f1f1'
        },
        icon: {
            backgroundColor: '#fff',
            transform: 'translateY(-20px)'
        },
        span: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
        },
        title: {
            transform: 'translateY(20px)'
        }
    }),
);

interface IProps extends TransitionProps {
    title: string;
    index: number;
    icon: string;
}

export default function CategoryTile(props: IProps) {
    const { title, icon } = props;
    const classes = useStyles();

    const link = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <RouterLink to={`/FAQ/${title}`} ref={ref} {...itemProps} />
            )),
        [title],
    );

    return (
        <Grid item xs={12} sm={6} md={3} {...props}>
            <ButtonBase component={link} className={classes.button}>
                <span className={classes.span}>
                    <Avatar className={classes.icon}>{icon.length > 1 ?
                        <Icon color="secondary">{icon}</Icon> :
                        <Typography variant="h6" color="secondary">{icon}</Typography>}
                    </Avatar>
                </span>
                <span className={classes.span}><Typography variant="subtitle1" className={classes.title}>{title}</Typography></span>
            </ButtonBase>
        </Grid>
    );
}