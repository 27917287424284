﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FaqViewModel from '../ViewModels/FaqViewModel';
import authService from '../api-authorization/AuthorizeService';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            margin: '16px 0'
        },
    }),
);

interface RouteParams {
    faqId: string;
}

export default function Faq() {
    const { faqId } = useParams<RouteParams>();
    const classes = useStyles();
    const [question, setQuestion] = React.useState(new FaqViewModel());
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getQuestion();
    }, []);

    const getQuestion = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch(`Faq/GetQuestion?id=${faqId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setQuestion(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    return (
        <React.Fragment>
            {loading ? <LinearProgress color="secondary" /> :
                <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="h4">{question.question}</Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{question.answer}</Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    );
}