﻿import * as React from 'react';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
    })
);

export default function ColourPaper(props: PaperProps) {
    const classes = useStyles();

    return (
        <Paper square className={classes.paper} {...props} />
    );
}