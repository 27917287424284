﻿import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormElementViewModel from '../../ViewModels/FormElementViewModel';
import HelpIcon from '@material-ui/icons/Help';
import LightTooltip from '../../Utilities/LightTooltip';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import KeyboardDateValidator from '../../Utilities/KeyboardDateValidator';

interface IProps {
    element: FormElementViewModel;
    onChange: (value: string, key: number) => void;
    setShouldSubmit: (key: number) => void;
}

export default function DateInput(props: IProps) {
    const { element, onChange, setShouldSubmit } = props;
    const [date, setDate] = React.useState<Date | null>(null);

    const handleChange = (date: MaterialUiPickersDate, value?: string | null) => {
        if (!value) {
            value = '';
        }
        onChange(value, element.id);
        setDate(date);
    }

    React.useEffect(() => {
        setShouldSubmit(element.id);
    }, []);

    return (
        <React.Fragment>
            <Grid item><Typography>{element.text}   {element.helpText &&
                <LightTooltip title={element.helpText} arrow>
                    <HelpIcon style={{ height: '20px' }} color="secondary" />
                </LightTooltip>
            }</Typography>
            </Grid>

            <Grid item xs={12}>
                <KeyboardDateValidator
                    clearable
                    format="dd/MM/yyyy"
                    name={element.documentPlaceholder}
                    fullWidth
                    value={date}
                    inputValue={element.value}
                    onChange={handleChange}
                    KeyboardButtonProps={{
                        style: { color: '#1616b1' }
                    }}
                    validators={element.required ? ['required'] : []}
                    errorMessages={element.required ? ['This field is required'] : []}
                />
            </Grid>
        </React.Fragment>
    );
}