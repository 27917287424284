﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import SquareButton from '../Utilities/SquareButton';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
    })
);

interface IProps {
    currentPrice: number;
    formId: number;
    open: boolean;
    close: (refresh: boolean) => void;
}

export default function UpdatePriceDialog(props: IProps) {
    const classes = useStyles();
    const { currentPrice, formId, open, close } = props;
    const [price, setPrice] = React.useState(currentPrice);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState('');

    const save = async () => {
        const token = await authService.getAccessToken();
        setSaving(true);

        fetch(`Forms/UpdateFormPrice?formId=${formId}&price=${price}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                setSaving(false);

                if (data.length > 0) {
                    setError(data);
                } else {
                    close(true);
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.valueAsNumber;
        value = value !== Number.NaN ? Math.round((value + Number.EPSILON) * 100) / 100 : 0;
        setPrice(value);
    }

    const cancel = () => {
        setPrice(currentPrice);
        close(false);
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="new-price-title"
            PaperProps={{ className: classes.paper }}
        >
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type="number"
                            margin="dense"
                            variant="outlined"
                            label="New Price"
                            name="price"
                            value={price}
                            onChange={onChange}
                            disabled={saving}
                            inputProps={{
                                min: 0.00,
                                pattern: "^\d*(\.\d{0,2})?$",
                                step: ".01"
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>
                {error.length > 0 && <Typography color="error">{error}</Typography>}
            </DialogContent>
            <DialogActions>
                <SquareButton onClick={cancel} variant="outlined" color="primary">Cancel</SquareButton>
                <LightBlueButton variant="contained" onClick={save}>Save</LightBlueButton>
            </DialogActions>
        </Dialog>
    );
}