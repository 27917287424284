﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ColourPaper from '../Utilities/ColourPaper';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import { Alert } from '@material-ui/lab';
import Bugsnag from '@bugsnag/js';

export default function MemberCreditsUpload() {
    const [text, setText] = React.useState('');
    const [saving, setSaving] = React.useState(false);
    const [serverError, setError] = React.useState('');

    const submit = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`User/UploadMemberCredits`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify({ upload: text })
        })
            .then(response => {
                setSaving(false);
                setError('');
                setText('');
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
                setError('A server error has occurred, please try again.');
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    }

    return (
        <ColourPaper>
            <ValidatorForm onSubmit={submit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>Upload Member Credits</Typography>
                        <Alert severity="error"><b>SMASHED CRAB USER ONLY - DO NOT USE</b></Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="error">{serverError}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextValidator
                            id="text"
                            name="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            multiline
                            value={text}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LightBlueButton fullWidth variant="contained" type="submit" disabled={saving}>Upload</LightBlueButton>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </ColourPaper>
    );
}