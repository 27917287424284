﻿import * as React from 'react';
import UserTable from './UserTable';
import authService from '../api-authorization/AuthorizeService';

export default function UserContainer() {

    const [role, setRole] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        getRole();
    }, []);

    const getRole = async () => {
        const user = await authService.getUser();
        setRole(user ? user.role : '');
    }

    return (
        <React.Fragment>
            <UserTable description="Manage your Organisation's Users." title="Organisation User Management" admins={false} />
            {role === "Admin" &&
                <UserTable description="Manage Admin Users." title="Admin User Management" admins={true} />
            }
        </React.Fragment>
    );
}