﻿import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SquareButton from '../Utilities/SquareButton';
import LightBlueButton from '../Utilities/LightBlueButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        select: {
            minWidth: 120,
            borderRadius: 0,
            margin: theme.spacing(1)
        },
    }),
);

interface IProps {
    open: boolean;
    close: (refresh: boolean) => void;
}

export default function OrganisationDropdown(props: IProps) {
    const classes = useStyles();
    const { open, close } = props;
    const [loading, setLoading] = React.useState(false);
    const [orgs, setOrgs] = React.useState<DropdownViewModel[]>([]);
    const [org, setOrg] = React.useState<DropdownViewModel | null>(null);
    const [search, setSearch] = React.useState("");
    const [role, setRole] = React.useState('');


    const updateAuth = async () => {

        const user = await authService.getUser();
        setRole(user ? user.role : '');
    }

    React.useEffect(() => {
        if (open) {
            getData();
        }
        updateAuth();
    }, [open]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch('Organisation/GetOrganisations', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setOrgs(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });

        fetch('Organisation/GetActiveOrganisation', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setOrg(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const updateUser = async () => {
        if (org !== null && org !== undefined && org.key !== undefined) {
            setLoading(true);
            const token = await authService.getAccessToken();

            fetch(`Organisation/UpdateActiveOrganisation?org=${org.key}`, {
                method: 'POST',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(response => {
                    setLoading(false);
                    close(true);
                })
                .catch(error => {
                    Bugsnag.notify(error);
                    setLoading(false);
                });
        }
    }

    const adminOrgSearch = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch('Organisation/SearchOrganisations?SearchString=' + encodeURIComponent(search), {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                if (data !== null) {
                    setOrgs(data);
                }
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });

    }

    const onChange = (event: any, newValue: DropdownViewModel | null) => {
        setOrg(newValue);
    }

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const handleClose = () => {
        close(false);
    }

    const optionLabel = (option: DropdownViewModel) => {
        return option.value;
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="organisation-dialog">
            <DialogTitle id="organisation-dialog">Change current organisation</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {(role && role === 'Admin') &&
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                label="Admin Search"
                                placeholder="Email / Member ID / Full Name"
                                onChange={onSearchChange}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Autocomplete
                            clearOnEscape
                            options={orgs}
                            getOptionLabel={optionLabel}
                            id="organisation"
                            onChange={onChange}
                            value={org}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    SelectProps={{
                                        className: classes.select
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        disabled: loading,
                                        startAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress size={20} /> : null}
                                            </React.Fragment>
                                        )
                                    }}
                                />

                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SquareButton onClick={handleClose} variant="outlined" color="primary" disabled={loading}>Cancel</SquareButton>
                <LightBlueButton onClick={updateUser} disabled={loading || org === null}>Change</LightBlueButton>
                {(role && role === 'Admin') &&
                    <LightBlueButton onClick={adminOrgSearch} disabled={loading}>Search</LightBlueButton>
                }
            </DialogActions>
        </Dialog>
    );
}