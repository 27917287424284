﻿import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FaqViewModel from '../ViewModels/FaqViewModel';
import Divider from '@material-ui/core/Divider';
import { useParams, Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import authService from '../api-authorization/AuthorizeService';
import Fade from '@material-ui/core/Fade';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            margin: '16px 0'
        },
        link: {
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.secondary.main,
            textDecoration: "none",
            '&:hover': {
                color: theme.palette.tertiary.main
            }
        },
    }),
);

interface RouteParams {
    category: string;
}

export default function CategoryPage() {
    const { category } = useParams<RouteParams>();
    const classes = useStyles();
    const [questions, setQuestions] = React.useState<FaqViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getQuestions();
    }, []);

    const getQuestions = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch(`Faq/GetCategoryQuestions?category=${encodeURIComponent(category)}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setQuestions(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="h4">FAQ Answers</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="h5">{category}</Typography>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            {questions.map((item, index) => {
                return <Fade in={!loading} timeout={75 + (index * 75)} key={item.id}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{item.question}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{item.answer}</Typography>
                        </Grid>
                        <Grid item>
                            <Link to={`/FAQ/${category}/${item.id}`} className={classes.link}>Read More</Link>
                        </Grid>
                    </Grid>
                </Fade>
            })}
        </React.Fragment>
    );
}