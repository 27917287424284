import * as React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import authService from '../api-authorization/AuthorizeService';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useHistory, useLocation } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PurchaseViewModel from '../ViewModels/PurchaseViewModel';
import PaymentRequestDataViewModel from '../ViewModels/PaymentRequestDataViewModel';
import Bugsnag from '@bugsnag/js';

const height = 600;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'relative',
            height: height + 4 + 5 + theme.spacing(2),
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
        loading: {
            position: 'absolute',
            top: 'calc(50% - 10px)',
            left: 'calc(50% - 10px)',
            zIndex: 0
        },
    }));

interface RouteParams {
    purchaseId: string;
}

export default function Payment() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation<RouteParams>();
    const { purchaseId } = location.state || { purchaseId: '' };
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [purchase, setPurchase] = React.useState<PurchaseViewModel>(new PurchaseViewModel);
    const [loading, setLoading] = React.useState(true);
    const [iframeLoaded, setIframeLoaded] = React.useState(false);
    const [paymentRequestData, setPaymentRequestData] = React.useState<PaymentRequestDataViewModel>(new PaymentRequestDataViewModel);

    React.useEffect(() => {
        getPurchase();
    }, []);

    React.useEffect(() => {
        var iframe: any = document.getElementById('realexIframe');

        // For testing payment locally
        //if (paymentRequestData.MERCHANT_ID === "dev861691010943345097" && iframe.src.includes("Dummy")) {
        //    loadIFrame(paymentRequestData);
        //    setLoading(false);
        //}

        if (paymentRequestData.MERCHANT_ID === "riai" && iframe.src.includes("Dummy")) {
            loadIFrame(paymentRequestData);
            setLoading(false);
        }
    }, [iframeLoaded, paymentRequestData]);

    React.useEffect(() => {
        if (purchase.addressViewModel !== null && purchase.phoneNumberViewModel !== null && purchase.purchaseItemViewModels !== null
        ) {
            if (purchase.addressViewModel.streetAddress1 !== null && purchase.addressViewModel.streetAddress1.length > 0 && purchase.phoneNumberViewModel.phoneNumber !== null && purchase.phoneNumberViewModel.phoneNumber.length > 0 && purchase.purchaseItemViewModels !== null && purchase.purchaseItemViewModels.length > 0)
                realexHpp();
        }
    }, [purchase]);

    const getPurchase = async () => {
        const token = await authService.getAccessToken();

        fetch(`Checkout/GetPurchase?purchaseId=${purchaseId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setPurchase(data);
            })
            .catch(error => {
                Bugsnag.notify(error);
            });
    }

    const realexHpp = async () => {
        const token = await authService.getAccessToken();
        const items = purchase.purchaseItemViewModels.map(item => {
            return {
                id: item.id,
                numberOfUnits: item.numberOfUnits,
                unitCost: item.unitCost
            }
        });

        const info = JSON.stringify({
            items: items,
            purchaseId: purchaseId,
            billingAddress:
            {
                streetAddress1: purchase.addressViewModel.streetAddress1,
                streetAddress2: purchase.addressViewModel.streetAddress2,
                streetAddress3: purchase.addressViewModel.streetAddress3,
                city: purchase.addressViewModel.city,
                province: purchase.addressViewModel.province,
                postalCode: purchase.addressViewModel.postalCode,
            },
            countryCode: purchase.phoneNumberViewModel.countryCode,
            phoneNumber: purchase.phoneNumberViewModel.phoneNumber
        });

        fetch("api/Payments/RequestPayment", {
            method: "POST",
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: info
        })
            .then(response => response.json())
            .then((data) => {
                if (data === "") {
                    history.push({
                        state: {
                            purchaseId: purchaseId,
                            error: "An error happened when setting up the payment. Please check the details you have entered."
                        }
                        , pathname: '/Checkout'
                    });
                }
                else if (data.url) {
                    history.push({ pathname: data.url });
                }
                else {
                    setPaymentRequestData(data);
                }
            }).catch(error => {
                Bugsnag.notify(error);
            });
    }

    const loadIFrame = (data: any) => {
        if (document.readyState === "complete") {
            //For testing on local
            //window.RealexHpp.setHppUrl("https://pay.sandbox.realexpayments.com/pay");
            window.RealexHpp.setHppUrl("https://pay.realexpayments.com/pay");
            window.RealexHpp.embedded.init("payButton", "realexIframe", window.location.origin + "/api/Payments/PaymentResponse", data);

            if (buttonRef && buttonRef.current) {
                buttonRef.current.click();
            }
        }
    }

    const frameLoaded = () => {
        setIframeLoaded(true);
    }

    return (
        <Grid container justify="center" alignItems="center">
            {loading && <Grid item xs={12}><LinearProgress color="secondary" /></Grid>}
            <Fade in={!loading}>
                <Grid item sm={6} xs={12}>
                    <Button id="payButton" hidden ref={buttonRef} />
                    <Paper className={classes.paper}>
                        <div className={classes.loading}>
                            <CircularProgress color="secondary" />
                        </div>
                        <iframe id="realexIframe" height={height + 4} width={'100%'} style={loading ? { opacity: 0, border: 'none', position: 'relative', zIndex: 100 } : { opacity: 1, border: 'none', position: 'relative', zIndex: 100 }} onLoad={frameLoaded} src="iframeDummyLoader.html" />
                    </Paper>
                </Grid>
            </Fade>
        </Grid>
    );
}