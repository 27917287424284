import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import BannerViewModel from './ViewModels/BannerViewModel';
import authService from './api-authorization/AuthorizeService';
import Box from '@material-ui/core/Box';
import Bugsnag from '@bugsnag/js';

export default function Home() {
    const [alertMessage, setAlertMessage] = React.useState(new BannerViewModel());

    React.useEffect(() => {
        getCurrentMessage();
    }, []);

    const getCurrentMessage = async () => {
        const token = await authService.getAccessToken();

        fetch(`Notification/GetBanner`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setAlertMessage(data);
            })
            .catch(error => {
                Bugsnag.notify(error);
            });
    }

    return (
        <React.Fragment>
            {alertMessage.visible &&
                <Box marginBottom={2}>
                    <Alert severity={alertMessage.alertType}>{alertMessage.message} {alertMessage.linkText !== "" && alertMessage.link !== "" ? <a target="blank" href={alertMessage.link}>{alertMessage.linkText}</a> : 
                        alertMessage.link !== "" ? <a target="blank" href={alertMessage.link}>{alertMessage.link}</a> : ""
                    }</Alert>
                </Box>
            }

            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" style={{ color: '#5EC4B6' }}>Welcome to RIAI Documents Online</Typography>
                    <br />
                    <br />
                    <Typography variant="h6" color="primary">We have developed a full suite of <b>34 RIAI Contracts and Documents</b> on a new, enhanced and easy-to-use online platform.</Typography>
                    <br />
                    <Typography variant="h6" color="primary">Our main objective is to support our Members so we have designed this service to be clear and easy to use. We have introduced new practical features including: digital signatures; flexible field lengths; templated project information; organisation accounts (ability to switch between organisations); improved search and retrieval. Registered Architects with RIAI Membership can buy and complete documents; retrieve generated documents; find receipts and assign 'sub-users' to their organisation.</Typography>
                    <br />
                    <Typography variant="h6" color="primary">For any queries or comments about RIAI Documents Online, please contact RIAI Bookshop manager Br&oacute;na King on <a href="tel:+353(0)16761703">+353 (0)1 676 1703</a> or email <a href="mailto:bking@riai.ie">bking@riai.ie</a>.</Typography>
                    <br />
                    <br />
                </Grid>

                <Grid container item xs={12} spacing={3}>
                    <Grid container item xs={12} sm={3} lg={3} spacing={2} direction="column" justify="center">
                        <Grid item container justify="center"><Grid item><Link to="/Documents"><img src="welcomeimages/icon_1.png" width={70} /></Link></Grid></Grid>
                        <Grid item container justify="center"><Grid item><Typography variant="h6" color="primary" align="center">Buy & Create</Typography></Grid></Grid>
                    </Grid>
                    <Grid container item xs={12} sm={3} lg={3} spacing={2} direction="column" justify="center">
                        <Grid item container justify="center"><Grid item><Link to="/Documents/History"><img src="welcomeimages/icon_2.png" width={70} /></Link></Grid></Grid>
                        <Grid item container justify="center"><Grid item><Typography variant="h6" color="primary" align="center">Generated Documents</Typography></Grid></Grid>
                    </Grid>
                    <Grid container item xs={12} sm={3} lg={3} spacing={2} direction="column" justify="center">
                        <Grid item container justify="center"><Grid item><Link to="/Invoices"><img src="welcomeimages/icon_3.png" width={70} /></Link></Grid></Grid>
                        <Grid item container justify="center"><Grid item><Typography variant="h6" color="primary" align="center">Invoices</Typography></Grid></Grid>
                    </Grid>
                    <Grid container item xs={12} sm={3} lg={3} spacing={2} direction="column" justify="center">
                        <Grid item container justify="center"><Grid item><a href="https://www.riai.ie/discover-architecture/riai-bookshop"><img src="welcomeimages/bookshop.png" width={70} /></a></Grid></Grid>
                        <Grid item container justify="center"><Grid item><Typography variant="h6" color="primary" align="center">Order Hard Copies</Typography></Grid></Grid>
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}
