﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function UsageTerms() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h3" color="primary" gutterBottom>Terms of Use</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Definitions</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">Site – RIAI Documents Online at www.documentsonline.riai.ie</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">Terms – This “Terms of Use” document which may be amended from time to time by the RIAI.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">User – An individual or organisation acting in the course of business who has set up an account or an employee of a User who is authorised by the User to access the account and is registered as a Sub-User.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>Product – a digital tool, known as RIAI Documents Online, delivered by the RIAI though the Site that provides Users with the ability to create and print out documents and contracts published by the RIAI including but not limited to RIAI Client Architect Agreements, RIAI Construction Contracts, RIAI Standard Forms, RIAI Warranty for Professional Services, RIAI Collateral Agreements, and RIAI Health and Safety Documents.  </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Acceptance of Terms</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">By using the Site you agree to these Terms including limitations on liability. If you do not agree to these Terms including limitations on liability you should leave the website immediately.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>The RIAI has the right to disable any User’s access to the Site if, in the opinion of the RIAI, they breach any of the Terms.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Copyright and Licencing</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>The RIAI grants the User a non-exclusive, non-transferable licence to access forms and generate documents through their account. The licence does not include any permission to reproduce, duplicate, amend or resell any form or documents generated on the Site. The ownership and copyright of all forms and documents generated on the Site are exclusively that of the RIAI. Neither forms nor documents generated may be reproduced in whole or in part without the written permission of the RIAI. Purchase and/or generation of any document does not give or imply any right or licence to reproduce copies or use the text in any other format or medium.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Authorised Access</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>Users are solely responsible for maintaining the confidentiality and security of their account and are responsible for activities that are carried out under their username and account details. Users must not reveal their logon information to anyone else or allow any person access to their account. Users must notify the RIAI of any unauthorised use of their account. The RIAI is not responsible for any losses arising out of the unauthorised use of an account.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Signing of Documents</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>Only architects registered under Part 3 of the Building Control Act 2007 are permitted to sign documents generated on the Site.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Payments, Refunds and Exchanges</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">Credits for each document type are purchased by credit/debit card through the online payment method contained within the Product. </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">The RIAI shall make no refunds for the purchase of credits.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">It is the responsibility of the User to ensure that the document that they are purchasing is the document that they intended to purchase. The RIAI accepts no responsibility for the errors made by the User when they are purchasing document credits.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">The RIAI shall not facilitate any exchange of credits between document types.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">All documents available within the Product are for single use. Documents cannot be amended or reused once they have been generated.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">The RIAI shall make no refunds or exchanges for Generated PDFs. It is the responsibility of the User to ensure that all the Project Data entered into the Product is accurate and correct before they select ‘Generate PDF’. RIAI accepts no responsibility for any errors or omission made by the User when entering the project data into the form and shall not make any refunds for contracts once they have been generated. Users will have to purchase a new contract / credit and re-enter the project data if they wish to change the project data on the Generated PDF.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">Users are liable for all transactions carried out on their account by anyone, with or without their authority or consent.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>If the Product is suspended for a period longer that 14 days the User may contact the RIAI to arrange an exchange of credits for hardcopy documents.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Suspension of the Site</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">The RIAI does not guarantee, represent or warrant that the service provided on the Site will be uninterrupted or delay free. The RIAI may have to suspend the Site from time to time to carry out routine updates to reflect changes in the relevant laws and regulatory requirements or to deal with technical problems, make minor technical changes or carry our routine maintenance on the Site.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">The RIAI will contact Users in advance to inform them that the supply of the Product will be suspended, unless the problem is urgent or an emergency.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>You may contact us for a refund of credits if the Product is suspended for an unreasonable period of time.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Limitation on Liability</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">The RIAI cannot guarantee that the documents generated on the Site will be fault free or that their contents will be correct, therefore the User agrees that the RIAI shall not be liable for any loss or damage whatsoever arising out of or in connection with the Site or the contents thereof, whether in contract, tort (including negligence), strict liability or otherwise.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>In no case will the RIAI, its servants, or agents be liable for any direct, indirect, incidental, special and/or consequential damages or loss of profits arising out of the usage of the Site, any of the documents generated by the Site or the unavailability of the Site.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Governing Law and Dispute Resolution</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>The Terms of Use for this Site are governed and construed in accordance with the laws of Ireland and Users hereby submit to the jurisdiction of the Irish Courts.</Typography>
            </Grid>
        </Grid>
    );
}