﻿import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import LightBlueButton from '../../Utilities/LightBlueButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SquareButton from '../../Utilities/SquareButton';
import authService from '../../api-authorization/AuthorizeService';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormElementLookupViewModel from '../../ViewModels/FormElementLookupViewModel';
import SelectItemRow from './SelectItemRow';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
    })
);

interface IProps {
    elementId: number;
    open: boolean;
    onClose: () => void;
}

export default function AddSelectItemDialog(props: IProps) {
    const classes = useStyles();
    const { open, onClose, elementId } = props;
    const [items, setItems] = React.useState<FormElementLookupViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [serverError, setError] = React.useState('');
    const [newItem, setItem] = React.useState('');

    React.useEffect(() => {
        getExisting();
    }, [elementId]);

    const getExisting = async () => {
        if (elementId > 0) {
            setLoading(true);
            const token = await authService.getAccessToken();
            fetch(`FormBuilder/GetSelectItems?id=${elementId}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.json())
                .then(data => {
                    setItems(data);
                    setLoading(false);
                })
                .catch(error => {
                    Bugsnag.notify(error);
                    setLoading(false);
                });
        }
    }

    const save = async () => {
        const token = await authService.getAccessToken();

        fetch('FormBuilder/ModifySelectItems', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(items)
        })
            .then(response => response.text())
            .then(data => {
                close();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setError('A server error has occurred, please try again.');
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem(event.target.value);
    }

    const remove = (id: number) => {
        const copy = [...items];
        const itemIndex = copy.findIndex(i => i.id === id);

        copy[itemIndex].delete = true;

        setItems(copy);
    }

    const add = () => {
        if (newItem.length > 0) {
            const copy = [...items];
            copy.push({
                id: 0,
                formElementId: elementId,
                optionIndex: items.length + 1,
                optionValue: newItem,
                delete: false
            });
            setItems(copy);
            setItem("");
        }
    }

    const close = () => {
        setItem("");
        onClose();
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="add-item-title"
            PaperProps={{ className: classes.paper }}
        >
            <ValidatorForm onSubmit={save}>
                <DialogTitle id="add-item-title">Modify Select Items</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">{serverError}</Typography>
                        </Grid>
                        {items.filter(f => f.delete === false).map((item, index) => {
                            return <SelectItemRow key={index} item={item} remove={remove} />;
                        })}
                        <Grid item xs={12}>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item xs={6}>
                                    <TextValidator
                                        id="text"
                                        name="text"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="New Item"
                                        value={newItem}
                                        validators={[]}
                                        errorMessages={[]}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <LightBlueButton variant="contained" onClick={add}>Add</LightBlueButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={close} variant="outlined" color="primary">Cancel</SquareButton>
                    <LightBlueButton type="submit" variant="contained" disabled={loading}>Save</LightBlueButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}