﻿import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InvoiceViewModel from '../ViewModels/InvoiceViewModel';
import InvoiceTableRow from './InvoiceTableRow';
import Typography from '@material-ui/core/Typography';
import ColourPaper from '../Utilities/ColourPaper';
import authService from '../api-authorization/AuthorizeService';
import SearchButton from '../Utilities/SearchButton';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            borderBottom: `${theme.palette.secondary.main} solid 2px`
        },
        headers: {
            color: theme.palette.primary.main
        },
        pad: {
            padding: theme.spacing(1),
            maxWidth: 200
        }
    }),
);

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof InvoiceViewModel;
    label: string;
    numeric: boolean;
    property: string;
}

const headCells: HeadCell[] = [
    { id: 'id', property: 'Id', numeric: false, disablePadding: false, label: 'Invoice No.' },
    { id: 'paymentOverview', property: 'PaymentOverview', numeric: false, disablePadding: false, label: 'Transaction ID' },
    { id: 'purchasedBy', property: 'PurchasedBy', numeric: false, disablePadding: false, label: 'Purchased By' },
    { id: 'cost', property: 'Cost', numeric: true, disablePadding: false, label: 'Amount (Inc. VAT)' },
    { id: 'date', property: 'Date', numeric: true, disablePadding: false, label: 'Date' },
];
const headCellsAdmin: HeadCell[] = [
    { id: 'id', property: 'Id', numeric: false, disablePadding: false, label: 'No.' },
    { id: 'paymentOverview', property: 'PaymentOverview', numeric: false, disablePadding: false, label: 'Transaction ID' },
    { id: 'purchasedBy', property: 'PurchasedBy', numeric: false, disablePadding: false, label: 'Purchased By' },
    { id: 'netCost', property: 'NetCost', numeric: true, disablePadding: false, label: 'Net' },
    { id: 'vat', property: 'Vat', numeric: true, disablePadding: false, label: 'VAT' },
    { id: 'cost', property: 'Cost', numeric: true, disablePadding: false, label: 'Amount (Inc. VAT)' },
    { id: 'date', property: 'Date', numeric: true, disablePadding: false, label: 'Date' },
];
interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof InvoiceViewModel) => void;
    order: Order;
    orderBy: string;
}
function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const [role, setRole] = React.useState('');
    const createSortHandler = (property: keyof InvoiceViewModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    React.useEffect(() => {
        updateAuth();
    }, []);

    const updateAuth = async () => {
        const user = await authService.getUser();
        setRole(user ? user.role : '');
    }

    return (
        <React.Fragment>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    {role === "Admin"
                        ?
                        headCellsAdmin.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                    className={classes.headers}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ))
                        :
                        headCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                    className={classes.headers}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ))
                    }
                    <TableCell colSpan={2} />
                    {
                        role === "Admin" &&
                        <TableCell colSpan={2} />
                    }
                </TableRow>
            </TableHead>

        </React.Fragment>
    );
}

export default function InvoiceTable() {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof InvoiceViewModel>('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [invoices, setInvoices] = React.useState<InvoiceViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [minDate, handleMinDateChange] = React.useState<null | Date>(null);
    const [maxDate, handleMaxDateChange] = React.useState<null | Date>(null);

    React.useEffect(() => {
        getInvoices();
    }, [page, order, orderBy, rowsPerPage]);

    React.useEffect(() => {
        if (minDate instanceof Date && !isNaN(minDate.valueOf())) {
            getInvoices();
        }
    }, [minDate]);

    React.useEffect(() => {
        if (maxDate instanceof Date && !isNaN(maxDate.valueOf())) {
            getInvoices();
        }
    }, [maxDate]);

    const getInvoices = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        fetch(`Invoice/GetInvoices?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&minDate=${minDate ? minDate.toUTCString() : ""}&maxDate=${maxDate ? maxDate.toUTCString() : ""}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setInvoices(data.rows);
            setCount(data.rowCount);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof InvoiceViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }
    const keyPress = (event: any) => {
        if (event.keyCode == 13) {
            getInvoices();
        }
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <ColourPaper>
                <Grid container justify="space-between" spacing={2}>
                    <Grid item>
                        <Typography variant="h4">Invoices</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        label="Start Date"
                                        clearable
                                        inputVariant="outlined"
                                        value={minDate}
                                        onChange={date => handleMinDateChange(date)}
                                        format="dd/MM/yyyy"
                                        fullWidth
                                        size="small"
                                    />
                                </MuiPickersUtilsProvider>

                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        label="End Date"
                                        clearable
                                        inputVariant="outlined"
                                        value={maxDate}
                                        onChange={date => handleMaxDateChange(date)}
                                        format="dd/MM/yyyy"
                                        fullWidth
                                        size="small"
                                    />
                                </MuiPickersUtilsProvider>

                            </Grid>
                            <Grid item>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    onChange={handleSearch}
                                    value={search}
                                    placeholder="Search..."
                                    onKeyDown={keyPress}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchButton aria-label="Search Invoices" onClick={getInvoices} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="form table"

                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {invoices.map((row: InvoiceViewModel) => {
                                return (<InvoiceTableRow key={row.id} invoice={row} />);
                            })}
                            {emptyRows > 0 &&
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={7} align="center">{loading && <CircularProgress color="secondary" />}</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </ColourPaper>
        </div>
    );

}