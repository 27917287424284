﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ColourPaper from '../Utilities/ColourPaper';
import LightBlueButton from '../Utilities/LightBlueButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export default function ReportingLinks() {
    const salesLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Admin/SalesReport" ref={ref} {...itemProps} />
    ));

    const creditsLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Admin/CreditsReport" ref={ref} {...itemProps} />
    ));

    const creditMvmtLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Admin/CreditMovementsReport" ref={ref} {...itemProps} />
    ));

    const docsLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Admin/DocumentsGeneratedReport" ref={ref} {...itemProps} />
    ));

    const ordersLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Admin/BookshopOrdersReport" ref={ref} {...itemProps} />
    ));

    return (
        <ColourPaper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>Reports</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LightBlueButton fullWidth variant="contained" component={salesLink}>Sales</LightBlueButton>
                </Grid>
                <Grid item xs={12}>
                    <LightBlueButton fullWidth variant="contained" component={creditsLink}>Credits</LightBlueButton>
                </Grid>
                <Grid item xs={12}>
                    <LightBlueButton fullWidth variant="contained" component={creditMvmtLink}>Credit Movements</LightBlueButton>
                </Grid>
                <Grid item xs={12}>
                    <LightBlueButton fullWidth variant="contained" component={docsLink}>Documents Generated</LightBlueButton>
                </Grid>
                <Grid item xs={12}>
                    <LightBlueButton fullWidth variant="contained" component={ordersLink}>Bookshop Orders</LightBlueButton>
                </Grid>
            </Grid>
        </ColourPaper>
    );
}