﻿import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { SortFaqViewModel } from '../ViewModels/FaqViewModel';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import LightBlueButton from '../Utilities/LightBlueButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            margin: '16px 0'
        },
        link: {
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.secondary.main,
            textDecoration: "none",
            '&:hover': {
                color: theme.palette.tertiary.main
            }
        },
    }),
);

interface IProps {
    questions: SortFaqViewModel[];
    stopSearch: () => void;
    loading: boolean;
}

export default function SearchPage(props: IProps) {
    const { loading, questions, stopSearch } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container spacing={2} justify="space-between">
                <Grid item>
                    <Typography variant="h5">Search Results</Typography>
                </Grid>
                <Grid item>
                    <LightBlueButton variant="contained" onClick={stopSearch}>Back</LightBlueButton>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            {questions.map((item, index) => {
                return <Fade in={!loading} timeout={500 + (index * 500)} key={item.id}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{item.question}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{item.answer}</Typography>
                        </Grid>
                        <Grid item>
                            <Link to={`/FAQ/${item.category}/${item.id}`} className={classes.link}>Read More</Link>
                        </Grid>
                    </Grid>
                </Fade>
            })}
        </React.Fragment>
    );
}