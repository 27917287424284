﻿import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CartItemViewModel from '../ViewModels/CartItemViewModel';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useCartDispatch } from './CartContext';

interface IProps {
    item: CartItemViewModel;
}

export default function CartItem(props: IProps) {
    const { item } = props;

    const cartDispatch = useCartDispatch();

    const subtract = () => {
        cartDispatch({ type: 'removeItem', item: item });
    }

    const add = () => {
        cartDispatch({ type: 'addItem', item: item });
    }

    const remove = () => {
        cartDispatch({ type: 'removeAllItem', item: item });
    }

    return (
        <TableRow>
            <TableCell>{item.formName}</TableCell>
            <TableCell>{''}</TableCell>
            <TableCell>
                <Grid container spacing={2}>
                    <Grid item>
                        <ButtonGroup>
                            <Button onClick={subtract}>-</Button>
                            <Button disabled style={{ color: '#000000de'}}>{item.numberOfUnits}</Button>
                            <Button onClick={add}>+</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <Button onClick={remove}>Remove</Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell align="right">€{item.unitCost.toFixed(2)}</TableCell>
        </TableRow>
    );
}