﻿import * as React from 'react';
import ColourPaper from '../Utilities/ColourPaper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LightBlueButton from '../Utilities/LightBlueButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import WarningDialog from '../Utilities/WarningDialog';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ProjectTemplateViewModel from '../ViewModels/ProjectTemplateViewModel';
import authService from '../api-authorization/AuthorizeService';
import { useHistory } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';

export default function NewProjectTemplate() {
    const [warningOpen, setOpen] = React.useState(false);
    const [projectTemplate, setProjectTemplate] = React.useState(new ProjectTemplateViewModel());

    const history = useHistory();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectTemplate({
            ...projectTemplate,
            [event.target.name]: event.target.value
        });
    }

    const toggleWarning = () => {
        setOpen(!warningOpen);
    }

    const cancelLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Documents/Templates" ref={ref} {...itemProps} />
    ));

    const saveProjectTemplate = async () => {

            const token = await authService.getAccessToken();

            fetch('Forms/CreateProjectTemplate', {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(projectTemplate)
            }).then(response => {
                history.push('Templates');
            }).catch((error) => {
                Bugsnag.notify(error);
            });
    }

    return (
        <React.Fragment>
            <ColourPaper>
                <ValidatorForm onSubmit={saveProjectTemplate}>
                    <Grid container spacing={2} justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h4">Add New Project Template</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Typography>Project Name</Typography>
                        </Grid>
                        <Grid item xs={11}>
                        <TextValidator
                            id="Project Name"
                            variant="outlined"
                                name="projectName"
                                fullWidth
                                value={projectTemplate.projectName}
                            onChange={handleChange}
                            validators={['required']}
                            errorMessages={['This field is required']}
                        />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>Architect Name</Typography>
                        </Grid>
                        <Grid item xs={11}>
                        <TextValidator
                                id="Architect Name"
                                variant="outlined"
                                name="architectName"
                                fullWidth
                                value={projectTemplate.architectName}
                                onChange={handleChange}
                            />
                    </Grid>

                        <Grid item xs={12}>
                            <Typography>Architects Address</Typography>
                        </Grid>
                        <Grid item xs={11}>
                        <TextValidator
                                id="Architect Address"
                                multiline
                                variant="outlined"
                                name="architectAddress"
                                fullWidth
                                value={projectTemplate.architectAddress}
                                onChange={handleChange}
                                />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>Client Name</Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <TextValidator
                                id="Client Name"
                                variant="outlined"
                                name="clientName"
                                fullWidth
                                value={projectTemplate.clientName}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>Client Address</Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <TextValidator
                                id="Client Address"
                                multiline
                                variant="outlined"
                                name="clientAddress"
                                fullWidth
                                value={projectTemplate.clientAddress}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>Project Job Reference</Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <TextValidator
                                id="Project Job Reference"
                                multiline
                                variant="outlined"
                                name="projectJobReference"
                                fullWidth
                                value={projectTemplate.projectJobReference}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>Project Address</Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <TextValidator
                                id="Project Address"
                                multiline
                                variant="outlined"
                                name="projectAddress"
                                fullWidth
                                value={projectTemplate.projectAddress}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>Project Description</Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <TextValidator
                                id="Project Description"
                                multiline
                                variant="outlined"
                                name="projectDescription"
                                fullWidth
                                value={projectTemplate.projectDescription}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justify="space-between" alignItems="flex-end">
                        <Grid item>
                            <Grid container spacing={2} justify="flex-end">
                                <Grid item>
                                    <LightBlueButton variant="contained" disabled={false} onClick={toggleWarning}>Cancel</LightBlueButton>
                                    <WarningDialog open={warningOpen} close={toggleWarning} okLink={cancelLink} text="Any changes you have made will be lost." buttonText="Okay" />
                                </Grid>
                                <Grid item>
                                    <LightBlueButton variant="contained" onClick={saveProjectTemplate} disabled={false}>{'Save Template'}</LightBlueButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ValidatorForm>
                </ColourPaper>
        </React.Fragment>
    );
}