﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function PrivacyPolicy() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h3" color="primary" gutterBottom>Privacy Policy</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Introduction</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">The Royal Institute of the Architects of Ireland (the “RIAI”, “we”, “us”, “our”) is a Data Controller for personal information provided by you. We will use this information subject to the General Data Protection Regulation (GDPR) and any applicable data protection legislation in Ireland and the E.U (“Applicable Data Protection legislation”). We are committed to safeguarding the privacy of our users and their data. We have created this Privacy Statement in order to demonstrate our firm commitment to the privacy of your information and to explain to you our use of your information.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>The Documents Online website (our “Website”) is operated by the RIAI (“we”, “us”, “our”, “RIAI”). In order to fully understand your rights, we encourage you to read this Privacy Statement before continuing to use our Website. If you have any queries in relation to our use of your information you may contact dataprotection@riai.ie.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Information we may Collect from you</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">We collect the following information:</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	Information that you provide to us when using our Website. This includes information provided at the time of logging in to use our Website.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	Information you provide to us when completing any forms or documents which will typically include your name and email address, your client’s name and details, details of contractors, sub-contractors and other design and construction professionals involved in your architectural projects.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	If you contact us for any reason, we may keep a record of that correspondence for as long as it is necessary regarding the nature of your contact.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	Details of your visits to our Website including, but not limited to, traffic data, location data and other communication data and the resources, advertisements and linked sites that you access.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	Our Website uses cookies. The purpose of the cookies is solely to assist us in redesigning our Website to ensure we provide relevant information in an easily accessible format. Please click <a target="_blank" href="https://www.riai.ie/about_this_site/cookies/">here</a> for our Cookie Policy.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>•	The search facility on our Website is an internal search function and returns only information that appears on our Website.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>How we Use your Information</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">We may use your personal information for the purposes of:</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	Administering our website;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	Allowing us to perform our functions and objectives as set out in the Building Control Act 2007 and our <a target="_blank" href="https://www.riai.ie/uploads/legacy/Constitution_14_Sep_2017.pdf">Constitution</a>;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	Enabling your access to and use of the Website services;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	To ensure that content from our Website is presented in the most effective manner for you and for your computer;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	To provide you with information that you request from us or which we feel may interest you, where you have consented to be contacted for such purposes or where it is within the performance of the functions of the RIAI and the Website to provide you with this information;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	To carry out our obligations arising from any contracts entered into between you and us;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	To allow you to participate in interactive features of our Website;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>•	To notify you about changes to our Website and/or services.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Legal Basis</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">We collect and use your personal information on one or more of the following grounds:</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	You have given your consent;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	It is necessary to fulfil our contract with you;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">•	It is necessary and proportionate for the performance of our functions under the Building Control Act 2007 and as set out in our Constitution; namely but not limited to, supporting, regulating and promoting the practice of architecture in Ireland;</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>We may also use your information to promote the need for research and development in architecture, conservation, construction, urban design, the built environment and associated arts and sciences and to provide you with information which may be of interest to you (e.g. CPD Courses, publications) and we may contact you about these by post and telephone. You are free to unsubscribe and opt-out of this communication at any time and we will always provide you with a way of doing so.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Disclosure of your Information</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>In certain circumstances we may disclose your personal information to data processers working directly on our behalf. In all instances we will ensure that your information is given the appropriate level of data protection under the Applicable Data Protection Legislation and that such data processors are subject to a contractual arrangement ensuring that your information is protected. </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Storage of your Information</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">In certain cases we may transfer your personal information to recipients outside the European Economic Area (“EEA”)</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">We only transfer your information to recipients outside the EEA when it is necessary for the services we provide you, or subject to safeguards that assure the protection of your information.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">Where we transfer your information we do so in accordance with Applicable Data Protection Legislation. We may rely on different legal mechanism to ensure the transfer is lawful. If the recipient is in a country that is not deemed ‘adequate’ by the European Commission we may rely on ‘standard contractual clauses’ with the recipient. These clauses contain standard commitments approved by the EU Commission to protect the privacy and security of your information.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">If the recipient is in the United States, we may alternatively rely on EU-US Privacy Shield whereby recipients are certified under the Privacy Shield Programme to receive your information.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone. We will only store your information for as long as is necessary given the purpose for which it was gathered.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" style={{ color: '#5EC4B6' }} gutterBottom>Your Rights</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">If you would like to request access to your personal information or ask that we amend your information or seek an erasure of your information please do so by emailing dataprotection@riai.ie.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary">If you have consented to the use of your personal information you have the right to withdraw that consent at any time.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="primary" gutterBottom>We hope you are happy with how we use your information but if you are not you may lodge a complaint with the Data Protection Commissioner at info@dataprotection.ie.</Typography>
            </Grid>
        </Grid>
    );
}