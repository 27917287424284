﻿import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import FormTable from './FormTable';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

export default function FormContainer() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography variant="h4">Buy & Create Documents</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h5">Client Architect Agreements</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormTable category="Client Architect Agreements" numOfRows={7} />
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h5">Construction Contracts</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormTable category="Construction Contracts" numOfRows={4} />
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography variant="h5">RIAI Standard Forms</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormTable category="RIAI Standard Forms" numOfRows={6} />
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel7a-content"
                        id="panel7a-header"
                    >
                        <Typography variant="h5">Warranty for Professional Services</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormTable category="Warranty for Professional Services" numOfRows={1} />
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                    >
                        <Typography variant="h5">Opinions on Compliance</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormTable category="Opinions on Compliance" numOfRows={5} />
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5a-content"
                        id="panel5a-header"
                    >
                        <Typography variant="h5">Collateral Agreements</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormTable category="Collateral Agreements" numOfRows={2} />
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6a-content"
                        id="panel6a-header"
                    >
                        <Typography variant="h5">Health and Safety Documents</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormTable category="Health and Safety Documents" numOfRows={9} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}