﻿import * as React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CreditMovementViewModel from '../../ViewModels/CreditMovementViewModel';

interface IProps {
    credit: CreditMovementViewModel;
}

export default function MovementRow(props: IProps) {
    const { credit } = props;

    return (
        <TableRow tabIndex={-1}>
            <TableCell>{credit.formID}</TableCell>
            <TableCell>{credit.formName}</TableCell>
            <TableCell>{credit.organisationID}</TableCell>
            <TableCell>{credit.organisationName}</TableCell>
            <TableCell>{credit.changedByFirstName}</TableCell>
            <TableCell>{credit.changedByLastName}</TableCell>
            <TableCell>{new Date(credit.date).toLocaleDateString()}</TableCell>
            <TableCell align="right">{credit.creditChange}</TableCell>
            <TableCell>{credit.reason}</TableCell>
        </TableRow>
    );
}