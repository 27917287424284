﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import CategoryTile from './CategoryTile';
import PopularQuestions from './PopularQuestions';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import LightBlueButton from '../Utilities/LightBlueButton';
import CategoryViewModel from '../ViewModels/CategoryViewModel';
import authService from '../api-authorization/AuthorizeService';
import Fade from '@material-ui/core/Fade';
import SearchPage from './SearchPage';
import { SortFaqViewModel } from '../ViewModels/FaqViewModel';
import SearchButton from '../Utilities/SearchButton';
import Bugsnag from '@bugsnag/js';

export default function FaqContainer() {
    const [search, setSearch] = React.useState('');
    const [categories, setCategories] = React.useState<CategoryViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [role, setRole] = React.useState('');
    const [searching, setSearching] = React.useState(false);
    const [questions, setQuestions] = React.useState<SortFaqViewModel[]>([]);

    React.useEffect(() => {
        getRole();
        getCategories();
    }, []);

    const getCategories = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch('Faq/GetCategories', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            credentials: 'same-origin'
        })
            .then(response => response.json())
            .then(data => {
                setCategories(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const searchQuestions = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch(`Faq/SearchQuestions?search=${encodeURIComponent(search)}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            credentials: 'same-origin'
        })
            .then(response => response.json())
            .then(data => {
                setQuestions(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const getRole = async () => {
        const user = await authService.getUser();
        setRole(user ? user.role : '');
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const searchFaqs = () => {
        if (search.length > 0) {
            setSearching(true);
            searchQuestions();
        }
    }

    const stopSearching = () => {
        setSearching(false);
        setSearch('');
    }

    const tiles = categories.map((item, index) => {
        return <Fade key={item.id} in={!loading} timeout={500 + index * 500}>
            <CategoryTile index={item.id} title={item.name} icon={item.icon} />
        </Fade>;
    });

    const manageLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={`/FAQ/Manage`} ref={ref} {...itemProps} />
    ));
    const keyPress = (event: any) => {
        if (event.keyCode == 13) {
            searchFaqs();
        }
    }
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Grid item>
                                <Typography variant="h4" gutterBottom>Frequently Asked Questions</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} alignItems="center">
                                {role === 'Admin' &&
                                    <Grid item>
                                        <LightBlueButton variant="contained" component={manageLink}>Manage Questions</LightBlueButton>
                                    </Grid>
                                }
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    onChange={handleSearch}
                                    value={search}
                                    placeholder="Search..."
                                    onKeyDown={keyPress}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchButton onClick={searchFaqs} aria-label="Search FAQs" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {searching ?
                <SearchPage questions={questions} stopSearch={stopSearching} loading={loading} />
                :
                <React.Fragment>
                    <Grid container spacing={2}>
                        {tiles}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PopularQuestions />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    );
}