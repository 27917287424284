﻿import * as React from 'react';
//import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormElementViewModel from '../../ViewModels/FormElementViewModel';
import HelpIcon from '@material-ui/icons/Help';
import LightTooltip from '../../Utilities/LightTooltip';
import { TextValidator } from 'react-material-ui-form-validator';
import ProjectTemplateViewModel from '../../ViewModels/ProjectTemplateViewModel';
import Typography from '@material-ui/core/Typography';

interface IProps {
    element: FormElementViewModel;
    onChange: (value: string, key: number) => void;
    setShouldSubmit: (key: number) => void;
    projectTemplate: ProjectTemplateViewModel;
}

export default function FreeText(props: IProps) {
    const { element, onChange, setShouldSubmit } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value, element.id);
    }

    const insertProjectTemplate = () => {
        if (props.projectTemplate !== undefined && props.projectTemplate !== null) {

            var value = '';

            switch (element.projectTemplateId) {
                case 0:
                    value = '';
                    break;
                case 1:
                    value = props.projectTemplate.projectName;
                    break;
                case 2:
                    value = props.projectTemplate.architectName;
                    break;
                case 3:
                    value = props.projectTemplate.architectAddress;
                    break;
                case 4:
                    value = props.projectTemplate.clientName;
                    break;
                case 5:
                    value = props.projectTemplate.clientAddress;
                    break;
                case 6:
                    value = props.projectTemplate.projectJobReference;
                    break;
                case 7:
                    value = props.projectTemplate.projectAddress;
                    break;
                case 8:
                    value = props.projectTemplate.projectDescription;
                    break;
                default:
                    value = '';
                    break;
            }

            if (element.charLimit > 0)
            {
                value = value.substring(0, element.charLimit);
            }

            onChange(value, element.id);
        }
    }

    React.useEffect(() => {
        insertProjectTemplate();
        setShouldSubmit(element.id);
    }, []);

    const limit = element.charLimit > 0 ? { maxLength: element.charLimit } : {};

    return (
        <React.Fragment>


            <Grid item xs={12}>
                <Typography style={{ textAlign: 'justify' }}>{element.text}   {element.helpText &&
                    <LightTooltip title={element.helpText} arrow>
                        <HelpIcon style={{ height: '20px' }} color="secondary" />
                    </LightTooltip>
                }</Typography>

            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
                <TextValidator
                    multiline
                    name={element.documentPlaceholder}
                    fullWidth
                    value={element.value ?? ''}
                    onChange={handleChange}
                    inputProps={limit}
                    InputProps={{
                        style: { color: '#1616b1' }
                    }}
                    validators={element.required ? ['required'] : []}
                    errorMessages={element.required ? ['This field is required'] : []}
                />
            </Grid>

        </React.Fragment>
    );
}