﻿import * as React from 'react';
import Grid from "@material-ui/core/Grid";
import MoreVertIcon from '@material-ui/icons/MoreVert';

export default function Spacer() {
  

    return (
        <React.Fragment>
            <Grid item xs={12} style={{ textAlign: "center" }}>
                <MoreVertIcon color="primary" style={{ fontSize: '3.5rem', opacity: '50%'}}/>

            </Grid>
        </React.Fragment>
    );
}