﻿export class UtilityMethods {
    public static round2Decimals(value: number): number {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

    public static convertModelToFormData(model: any, form: FormData, namespace = ''): FormData {
        let formData = form || new FormData();
        let formKey: string;



        if (model instanceof Array) {
            model.forEach((element, index) => {
                const tempFormKey = `${namespace}[${index}]`;

                if (element instanceof File)
                    formData.append(formKey, element);
                else
                    this.convertModelToFormData(element, formData, tempFormKey);
            });
        } else {
            for (let propertyName in model) {



                if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;

                formKey = namespace ? `${namespace}.${propertyName}` : propertyName;
                if (model[propertyName] instanceof Date)
                    formData.append(formKey, model[propertyName].toISOString());
                else if (model[propertyName] instanceof File)
                    formData.append(formKey, model[propertyName]);
                else if (model[propertyName] instanceof Array) {
                    model[propertyName].forEach((element: any, index: number) => {
                        const tempFormKey = `${formKey}[${index}]`;

                        if (element instanceof File)
                            formData.append(formKey, element);
                        else
                            this.convertModelToFormData(element, formData, tempFormKey);
                    });
                }
                else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
                    this.convertModelToFormData(model[propertyName], formData, formKey);
                else {

                    formData.append(formKey, model[propertyName].toString());

                }
            }
        }

        return formData;
    }
}