﻿import AddressViewModel from "./AddressViewModel";
import { BookshopOrderItemViewModel} from "./CartItemViewModel";
import PhoneNumberViewModel from "./PhoneNumberViewModel";

export default class BookshopOrderViewModel {
    public addressViewModel: AddressViewModel = new AddressViewModel();
    public phoneNumberViewModel: PhoneNumberViewModel = new PhoneNumberViewModel();
    public bookshopOrderItemViewModels: BookshopOrderItemViewModel[] = [];
    public email: string = "";
}

