﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useParams, Redirect } from 'react-router';
import ColourPaper from '../Utilities/ColourPaper';
import Alert from '@material-ui/lab/Alert';
import LightBlueButton from '../Utilities/LightBlueButton';
import SquareButton from '../Utilities/SquareButton';
import Bugsnag from '@bugsnag/js';

interface RouteParams {
    orgId: string;
    requestId: string;
    code: string;
}

export default function OrganisationConfirmation() {
    const { orgId, requestId, code } = useParams<RouteParams>();

    const [message, setMessage] = React.useState('');
    const [redirect, setRedirect] = React.useState(false);
    const [confirming, setConfirming] = React.useState(false);

    const confirmJoin = async () => {
        setConfirming(true);

        fetch(`User/ConfirmOrganisationAdd?token=${encodeURI(code)}&orgId=${orgId}&requestId=${requestId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                setConfirming(false);

                if (data.length > 0) {
                    setMessage(data);
                } else {
                    setRedirect(true);
                }
            }).catch(error => {
                Bugsnag.notify(error);
            });
    }

    const denyJoin = async () => {
        setConfirming(true);

        fetch(`User/DenyOrganisationAdd?token=${encodeURI(code)}&orgId=${orgId}&requestId=${requestId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                setConfirming(false);

                if (data.length > 0) {
                    setMessage(data);
                } else {
                    setRedirect(true);
                }
            }).catch(error => {
                Bugsnag.notify(error);
            });
    }

    return (
        <React.Fragment>
            {redirect ?
                <Redirect to="/" />
                :
                <Grid container>
                    <Grid item xs={4}>
                        <ColourPaper>
                            <Typography variant="h4" gutterBottom>Join Organisation</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Alert severity="info">You will be redirected to login after choosing an action.</Alert>
                                </Grid>
                                {message.length > 0 &&
                                    <Grid item xs={12}>
                                        <Alert severity="warning">{message}</Alert>
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    <SquareButton fullWidth variant="contained" color="primary" disabled={confirming} onClick={denyJoin}>Deny</SquareButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <LightBlueButton fullWidth variant="contained" disabled={confirming} onClick={confirmJoin}>Confirm</LightBlueButton>
                                </Grid>
                            </Grid>
                        </ColourPaper>
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}