﻿import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormElementViewModel from '../../ViewModels/FormElementViewModel';
import HelpIcon from '@material-ui/icons/Help';
import LightTooltip from '../../Utilities/LightTooltip';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


interface IProps {
    element: FormElementViewModel;
    onChange: (value: string, key: number) => void;
    setShouldSubmit: (key: number) => void;
}

export default function CheckboxInput(props: IProps) {
    const { element, onChange, setShouldSubmit } = props;

    React.useEffect(() => {
        setShouldSubmit(element.id);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked ? "True" : "False", element.id);
    }

    return (
        <React.Fragment>

            <Grid item xs={12} md={10}>
                <Typography style={{ textAlign: 'justify' }}>{element.text}   {element.helpText &&
                    <LightTooltip title={element.helpText} arrow>
                        <HelpIcon style={{ height: '20px' }} color="secondary" />
                    </LightTooltip>
                }</Typography>
            </Grid>
            <Grid item xs={12} md={2} style={{ textAlign: 'right' }}>
                <Checkbox
                    name={element.documentPlaceholder}
                    color="secondary"
                    checked={element.value === "True"}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': element.friendlyName + ' checkbox' }}
                    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '2.5rem' }} />}
                    checkedIcon={<CheckBoxIcon style={{ fontSize: '2.5rem' }} color="primary" />}
                />
            </Grid>



        </React.Fragment>
    );
}