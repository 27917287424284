﻿export default class ProfileViewModel {
    public email: string = '';
    public memberNo: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public mobileNo: number = 0;
    public phoneNo: number = 0;
    public addressLine1: string = '';
    public addressLine2: string = '';
    public addressLine3: string = '';
    public addressLine4: string = '';
    public county: string = '';
    public country: string = '';
}