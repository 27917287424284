import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import ColourPaper from '../Utilities/ColourPaper';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import BannerViewModel from '../ViewModels/BannerViewModel';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Bugsnag from '@bugsnag/js';

export default function AlertManagement() {
    const [alertMessage, setAlertMessage] = React.useState(new BannerViewModel());
    const [saving, setSaving] = React.useState(false);
    const [serverError, setError] = React.useState('');
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        getCurrentMessage();
    }, []);

    const getCurrentMessage = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`Notification/GetBanner`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setSaving(false);
                setAlertMessage(data);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
            });
    }

    const submit = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();
        const update = { ...alertMessage };
        update.visible = true;

        fetch(`Notification/AddBanner`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(update)
        })
            .then(response => {
                response.json().then(data => {
                    setSaving(false);

                    if (response.ok) {
                        setError('');
                        setAlertMessage(data);
                    } else {
                        setError('A server error has occurred, please try again.');
                    }
                })
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
                setError('A server error has occurred, please try again.');
            });
    }

    const hideShowBanner = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`Notification/UpdateBannerVisibility?id=${alertMessage.id}&visible=${!alertMessage.visible}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => {
                setSaving(false);
                setError('');
                setAlertMessage({
                    ...alertMessage,
                    visible: !alertMessage.visible
                });
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
                setError('A server error has occurred, please try again.');
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = isNaN(event.target.valueAsNumber) ?
            event.target.value :
            event.target.valueAsNumber;

        setAlertMessage({
            ...alertMessage,
            [event.target.name]: value
        });
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const alertTypes = ['error', 'success', 'warning', 'info'] as const;

    return (
        <ColourPaper>
            <ValidatorForm onSubmit={submit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>Update Warning Message</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LightBlueButton fullWidth variant="contained" disabled={saving || alertMessage.message.length <= 0} onClick={hideShowBanner}>{alertMessage.visible ? 'Disable' : 'Enable'} Warning</LightBlueButton>
                    </Grid>
                    <Grid item xs={12}>
                        {serverError.length > 0 && <Alert severity="error">{serverError}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Warning Type</InputLabel>
                        <SelectValidator
                            name="alertType"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={alertMessage.alertType}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        >
                            {alertTypes.map((item, index) => (
                                <MenuItem key={index} value={item}>{item.charAt(0).toUpperCase() + item.slice(1)}</MenuItem>
                            ))}
                        </SelectValidator>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Message</InputLabel>
                        <TextValidator
                            name="message"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            multiline
                            rows={4}
                            value={alertMessage.message}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Link Text</InputLabel>
                        <TextValidator
                            name="linkText"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={alertMessage.linkText}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Link</InputLabel>
                        <TextValidator
                            name="link"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={alertMessage.link}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LightBlueButton fullWidth variant="contained" type="submit" disabled={saving}>Update & Display Message</LightBlueButton>
                    </Grid>
                </Grid>
            </ValidatorForm>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={"success"}>
                    Warning Message successfully updated
                </Alert>
            </Snackbar>
        </ColourPaper>
    );
}