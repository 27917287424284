import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Success from './components/Success';
import { ThemeProvider } from '@material-ui/styles';
import FormContainer from './components/Forms/FormContainer';
import InvoiceTable from './components/Invoices/InvoiceTable';
import UserContainer from './components/Users/UserContainer';
import FaqContainer from './components/FAQ/FaqContainer';
import CategoryPage from './components/FAQ/CategoryPage';
import FaqTable from './components/FAQ/FaqTable';
import ShoppingCart from './components/Checkout/ShoppingCart';
import NotificationContainer from './components/Notifications/NotificationContainer';
import EditFormDraft from './components/Forms/EditFormDraft';
import NewProjectTemplate from './components/Forms/NewProjectTemplate';
import ProfileDetails from './components/Profile/ProfileDetails';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import CssBaseline from '@material-ui/core/CssBaseline';
import CustomTheme from './components/Utilities/CustomTheme';
import HistoryTable from './components/Forms/HistoryTable';
import TemplateTable from './components/Forms/TemplateTable';
import AdminContainer from './components/Admin/AdminContainer';
import Faq from './components/FAQ/Faq';
import Checkout from './components/Checkout/Checkout';
import DraftsTable from './components/Forms/DraftsTable';
import CompleteOrder from './components/Checkout/CompleteOrder';
import Payment from './components/Checkout/Payment';
import RegisterConfirmation from './components/Profile/RegisterConfirmation';
import EditUser from './components/Users/EditUser';
import SalesReport from './components/Admin/Reports/SalesReport';
import CreditsReport from './components/Admin/Reports/CreditsReport';
import DocumentsReport from './components/Admin/Reports/DocumentsReport';
import EditProjectTemplate from './components/Forms/EditProjectTemplate';
import ManageOrganisations from './components/Organisations/ManageOrganisation';
import OrganisationProvider from './components/Organisations/OrganisationContext';
import UsageTerms from './components/Footer/UsageTerms';
import PrivacyPolicy from './components/Footer/PrivacyPolicy';
import OrganisationConfirmation from './components/Users/OrganisationConfirmation';
import FormBuilder from './components/Forms/FormBuilder/FormBuilder';
import CreditMovementsReport from './components/Admin/Reports/CreditMovementReport';
import BookshopReport from './components/Admin/Reports/BookshopReport';
import UpdateBookshop from './components/Admin/UpdateBookshop';
import BookshopPayment from './components/BookShop/BookshopPayment';
import OrderComplete from './components/BookShop/OrderComplete';
import InformationForm from './components/BookShop/InformationForm';

const atlasFont = {
    fontFamily: 'Atlas Grotesk',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `local('AtlasGrotesk'),
        local('AtlasGrotesk-Regular'),
        url('./fonts/AtlasGrotesk-Regular.otf')`,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = CustomTheme({
    typography: {
        fontFamily: 'Atlas Grotesk'
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [atlasFont]
            }
        }
    },
    palette: {
        primary: { main: '#1E196A' },
        secondary: {
            main: '#00AEEF',
        },
        tertiary: {
            main: '#F05B72'
        }
    }
});

export default function App() {

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <OrganisationProvider>
                <Layout>
                    <Switch>
                        <AuthorizeRoute exact path='/' component={Home} />
                        <AuthorizeRoute exact path='/Documents' component={FormContainer} />
                        <AuthorizeRoute exact path='/Documents/NewForm' component={EditFormDraft} />
                        <AuthorizeRoute exact path='/Documents/NewTemplate' component={NewProjectTemplate} />
                        <AuthorizeRoute exact path='/Documents/EditTemplate' component={EditProjectTemplate} />
                        <AuthorizeRoute exact path='/Documents/EditDraft' component={EditFormDraft} />
                        <AuthorizeRoute exact path='/Documents/History' component={HistoryTable} />
                        <AuthorizeRoute exact path='/Documents/Templates' component={TemplateTable} />
                        <AuthorizeRoute exact path='/Documents/Drafts' component={DraftsTable} />
                        <AuthorizeRoute exact path='/Invoices' component={InvoiceTable} />
                        <AuthorizeRoute admin owner exact path='/Users' component={UserContainer} />
                        <AuthorizeRoute admin owner exact path='/Users/Edit' component={EditUser} />
                        <AuthorizeRoute exact path='/Organisations' component={ManageOrganisations} />
                        <AuthorizeRoute exact path='/FAQ' component={FaqContainer} />
                        <AuthorizeRoute admin exact path='/FAQ/Manage' component={FaqTable} />
                        <AuthorizeRoute exact path='/FAQ/:category' component={CategoryPage} />
                        <AuthorizeRoute exact path='/FAQ/:category/:faqId' component={Faq} />
                        <AuthorizeRoute exact path='/ShoppingCart' component={ShoppingCart} />
                        <AuthorizeRoute exact path='/Checkout/' component={Checkout} />
                        <AuthorizeRoute exact path='/Payment/' component={Payment} />
                        <AuthorizeRoute exact path='/CompleteOrder/:orderId' component={CompleteOrder} />
                        <AuthorizeRoute exact path='/Notifications' component={NotificationContainer} />
                        <AuthorizeRoute exact path='/Profile' component={ProfileDetails} />
                        <AuthorizeRoute exact path='/Profile/:profileId' component={ProfileDetails} />
                        <AuthorizeRoute admin exact path='/Admin' component={AdminContainer} />
                        <AuthorizeRoute admin exact path='/Admin/SalesReport' component={SalesReport} />
                        <AuthorizeRoute admin exact path='/Admin/CreditsReport' component={CreditsReport} />
                        <AuthorizeRoute admin exact path='/Admin/CreditMovementsReport' component={CreditMovementsReport} />
                        <AuthorizeRoute admin exact path='/Admin/DocumentsGeneratedReport' component={DocumentsReport} />
                        <AuthorizeRoute admin exact path='/Admin/BookshopOrdersReport' component={BookshopReport} />
                        <AuthorizeRoute admin exact path='/Admin/ManageBookshop' component={UpdateBookshop} />
                        <AuthorizeRoute admin exact path='/FormBuilder' component={FormBuilder} />
                        <AuthorizeRoute exact path='/api/Payments/Success' component={Success} />
                        <Route path='/TermsofUse' component={UsageTerms} />
                        <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
                        <Route path='/BookshopOrderFailed/:orderId' component={InformationForm} />
                        <Route path='/Bookshop' exact component={InformationForm} />
                        <Route path='/BookshopPayment' component={BookshopPayment} />
                        <Route path='/BookshopCompleteOrder/:orderId' component={OrderComplete} />
                        <Route path='/RegisterConfirmation/:userId/:code' component={RegisterConfirmation} />
                        <Route path='/OrganisationConfirmation/:orgId/:requestId/:code' component={OrganisationConfirmation} />
                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} ><ApiAuthorizationRoutes /></Route>
                    </Switch>
                </Layout>
            </OrganisationProvider>
        </ThemeProvider>
    );
}
