﻿import * as React from 'react';
import ColourPaper from '../Utilities/ColourPaper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import NotificationViewModel from '../ViewModels/NotificationViewModel';
import Notification from './Notification';
import Grid from '@material-ui/core/Grid';
import LightBlueButton from '../Utilities/LightBlueButton';
import NotificationEditor from './NotificationEditor';
import authService from '../api-authorization/AuthorizeService';
import Fade from '@material-ui/core/Fade';
import TablePagination from '@material-ui/core/TablePagination';
import Bugsnag from '@bugsnag/js';

export default function NotificationContainer() {
    const [notifications, setNotifications] = React.useState<NotificationViewModel[]>([]);
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [pageRows, setPageRows] = React.useState(5);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [role, setRole] = React.useState('');

    React.useEffect(() => {
        getNotifications();
        getRole();
    }, []);

    React.useEffect(() => {
        getNotifications();
    }, [page, pageRows]);

    const getRole = async () => {
        const user = await authService.getUser();
        setRole(user ? user.role : '');
    }

    const getNotifications = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Notification/GetNotifications?pageRows=${pageRows}&page=${page}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setNotifications(data.rows);
            setCount(data.rowCount);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const clearNotifications = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Notification/MarkAllAsRead`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => {
            setLoading(false);
            getNotifications();
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const toggleEditor = () => {
        setOpen(!open);
    }

    const closeEditor = (refresh: boolean) => {
        setOpen(false);

        if (refresh) {
            getNotifications();
        }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        setPageRows(parseInt(event.target.value, 10));
        setPage(0);
    };

    const items = notifications.map((item, index) => {
        return <Fade in={!loading} timeout={500 + (index * 500)} key={item.id}>
            <Grid item xs={10}>
                <Notification notification={item} role={role} refresh={getNotifications} />
            </Grid>
        </Fade>;
    });

    return (
        <ColourPaper>
            <Grid container spacing={2} justify="space-between">
                <Grid item>
                    <Typography variant="h4">Notifications</Typography>
                </Grid>
                {role === "Admin" &&
                    <Grid item>
                        <LightBlueButton variant="contained" onClick={toggleEditor}>New Notification</LightBlueButton>
                    </Grid>
                }
                {role !== "Admin" &&
                    <Grid item>
                        <LightBlueButton variant="contained" onClick={clearNotifications} disabled={loading}>Clear All</LightBlueButton>
                    </Grid>
                }
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
            <Grid container spacing={2} justify="center">
                {items}
            </Grid>
            {notifications.length > 0 && <Grid container spacing={2} justify="flex-end">
                <Grid item>
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25]}
                        count={count}
                        page={page}
                        rowsPerPage={pageRows}
                        onPageChange={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage} />
                </Grid>
            </Grid>
            }
            <NotificationEditor open={open} onClose={closeEditor} />
        </ColourPaper>
    );
}