﻿import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import ColourPaper from '../../Utilities/ColourPaper';
import authService from '../../api-authorization/AuthorizeService';
import SearchButton from '../../Utilities/SearchButton';
import MovementRow from './MovementRow';
import LightBlueButton from '../../Utilities/LightBlueButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSVLink } from "react-csv";
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import CreditMovementViewModel from '../../ViewModels/CreditMovementViewModel';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import DateFnsUtils from '@date-io/date-fns';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            borderBottom: `${theme.palette.secondary.main} solid 2px`
        },
        headers: {
            color: theme.palette.primary.main
        }
    }),
);

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof CreditMovementViewModel;
    label: string;
    numeric: boolean;
    property: string;
}

const headCells: HeadCell[] = [
    { id: 'formID', property: 'FormID', numeric: false, disablePadding: false, label: 'Form ID' },
    { id: 'formName', property: 'FormName', numeric: false, disablePadding: false, label: 'Form Name' },
    { id: 'organisationID', property: 'OrganisationID', numeric: false, disablePadding: false, label: 'Organisation ID' },
    { id: 'organisationName', property: 'OrganisationName', numeric: false, disablePadding: false, label: 'Organisation' },
    { id: 'changedByFirstName', property: 'ChangedByFirstName', numeric: false, disablePadding: false, label: 'Changed By First Name' },
    { id: 'changedByLastName', property: 'ChangedByLastName', numeric: false, disablePadding: false, label: 'Changed By Last Name' },
    { id: 'date', property: 'Date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'creditChange', property: 'CreditChange', numeric: true, disablePadding: false, label: 'Credit Change' },
    { id: 'reason', property: 'Reason', numeric: false, disablePadding: false, label: 'Reason' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof CreditMovementViewModel) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof CreditMovementViewModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.headers}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const csvHeaders = [
    { key: 'formID', label: 'Form ID' },
    { key: 'formName', label: 'Form Name' },
    { key: 'organisationID', label: 'Organisation ID' },
    { key: 'organisationName', label: 'Organisation' },
    { key: 'changedByFirstName', label: 'Changed By First Name' },
    { key: 'changedByLastName', label: 'Owner Last Name' },
    { key: 'date', label: 'Date' },
    { key: 'creditChange', label: 'Credit Change' },
    { key: 'reason', label: 'Reason' }
];

export default function CreditMovementsReport() {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof CreditMovementViewModel>('formID');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [results, setResults] = React.useState<CreditMovementViewModel[]>([]);
    const [fullResults, setFullResults] = React.useState<CreditMovementViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [minDate, handleMinDateChange] = React.useState<null | Date>(null);
    const [maxDate, handleMaxDateChange] = React.useState<null | Date>(null);
    const csvInstance = React.useRef<any | null>(null);

    React.useEffect(() => {
        getResults();
    }, [page, order, orderBy, rowsPerPage]);

    React.useEffect(() => {
        if (csvInstance && csvInstance.current && fullResults.length > 0) {
            setTimeout(() => {
                csvInstance.current.link.click();
                setFullResults([]);
            });
        }
    }, [fullResults]);

    React.useEffect(() => {
        if (minDate instanceof Date && !isNaN(minDate.valueOf())) {
            getResults();
        }
    }, [minDate]);

    React.useEffect(() => {
        if (maxDate instanceof Date && !isNaN(maxDate.valueOf())) {
            getResults();
        }
    }, [maxDate]);

    const getResults = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const orderByProp = headCells.find(f => f.id === orderBy)!.property;
        fetch(`Reports/GetCreditMovements?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&minDate=${minDate ? minDate.toUTCString() : ""}&maxDate=${maxDate ? maxDate.toUTCString() : ""}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            credentials: 'same-origin'
        }).then(response => response.json()).then((data) => {
            setResults(data.rows);
            setCount(data.rowCount);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }


    const getCsv = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        fetch(`Reports/GetCreditMovements?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}&minDate=${minDate ? minDate.toUTCString() : ""}&maxDate=${maxDate ? maxDate.toUTCString() : ""}&csv=true`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8', 'credentials': 'same-origin' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8', 'credentials': 'same-origin' },
        }).then(response => response.json()).then((data) => {
            setFullResults(data.rows);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof CreditMovementViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const searchClick = () => {
        if (page !== 0) {
            setPage(0);
        }
        else {
            getResults();
        }
    }

    const keyPress = (event: any) => {
        if (event.keyCode == 13) {
            if (page !== 0) {
                setPage(0);
            }
            else {
                getResults();
            }
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ColourPaper>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>Credit Movement Report</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                label="Start Date"
                                                clearable
                                                inputVariant="outlined"
                                                value={minDate}
                                                onChange={date => handleMinDateChange(date)}
                                                format="dd/MM/yyyy"
                                                fullWidth
                                                size="small"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                label="End Date"
                                                clearable
                                                inputVariant="outlined"
                                                value={maxDate}
                                                onChange={date => handleMaxDateChange(date)}
                                                format="dd/MM/yyyy"
                                                fullWidth
                                                size="small"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            onChange={handleSearch}
                                            value={search}
                                            placeholder="Search..."
                                            onKeyDown={keyPress}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchButton onClick={searchClick} aria-label="Credit Search" />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <LightBlueButton variant="contained" disabled={loading} onClick={getCsv}>Export CSV</LightBlueButton>
                                {fullResults.length > 0 &&
                                    <CSVLink
                                        data={fullResults}
                                        headers={csvHeaders}
                                        filename={(new Date()).toLocaleDateString('en-GB') + "_credit_movements_report.csv"}
                                        style={{ textDecoration: 'none' }}
                                        ref={csvInstance}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <ResponsiveTable
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                aria-label="credit movement report table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {results.map((row: CreditMovementViewModel, index) => {
                                        return (<MovementRow key={index} credit={row} />);
                                    })
                                    }
                                    {emptyRows > 0 &&
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={headCells.length} align="center">{loading && <CircularProgress color="secondary" />}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </ColourPaper>
                </Grid>
            </Grid>
        </div>
    );
}