﻿import * as React from 'react';
import authService from '../api-authorization/AuthorizeService';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LightBlueButton from '../Utilities/LightBlueButton';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Bugsnag from '@bugsnag/js';

interface IProps {
    keyValue: number;
    onChange: (value: number) => void;
}

export default function OrganisationDropdown(props: IProps) {
    const { onChange } = props;
    const [list, setList] = React.useState<DropdownViewModel[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [search, setSearch] = React.useState("");
    const [inputValue, setInputValue] = React.useState("");
    const [value, setValue] = React.useState<DropdownViewModel | null>(null);

    React.useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`Organisation/GetOrganisations`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setList(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const adminOrgSearch = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch('Organisation/SearchOrganisations?SearchString=' + encodeURIComponent(search), {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                if (data !== null) {
                    setList(data);
                }
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });

    }

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const optionLabel = (option: DropdownViewModel) => {
        return option.value;
    }

    const onDropdownChange = (event: any, newValue: DropdownViewModel | null) => {
        setValue(newValue);

        if (newValue) {
            onChange(newValue.key);
        }
    }

    return (
        <Grid container spacing={1} alignItems="center" justify="space-between">
            <Grid item xs={12}>
                <InputLabel>Organisation</InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="Email / Member ID / Full Name"
                    onChange={onSearchChange}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <LightBlueButton onClick={adminOrgSearch} disabled={loading}>Search</LightBlueButton>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    clearOnEscape
                    options={list}
                    getOptionLabel={optionLabel}
                    id="organisation"
                    onChange={onDropdownChange}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    inputValue={inputValue}
                    value={value}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            InputProps={{
                                ...params.InputProps,
                                disabled: loading,
                                startAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress size={20} /> : null}
                                    </React.Fragment>
                                )
                            }}
                        />

                    }
                />
            </Grid>
        </Grid>
    );
}