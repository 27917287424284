﻿import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormElementViewModel from '../../ViewModels/FormElementViewModel';
import HelpIcon from '@material-ui/icons/Help';
import LightTooltip from '../../Utilities/LightTooltip';
import LightBlueButton from '../../Utilities/LightBlueButton';
import authService from '../../api-authorization/AuthorizeService';
import Bugsnag from '@bugsnag/js';

interface IProps {
    element: FormElementViewModel;
    onChange: (value: string, key: number) => void;
    setShouldSubmit: (key: number) => void;
    owner: boolean;
}

export default function SignatureInput(props: IProps) {
    const { element, onChange, setShouldSubmit, owner } = props;
    const [exists, setExists] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [mySigFiles, setMySigFiles] = React.useState<any>();
    const [mySigPath, setMySigPath] = React.useState("");
    const [sigFiles, setSigFiles] = React.useState<any>();

    React.useEffect(() => {
        setShouldSubmit(element.id);

        checkExists();

        if (element.value !== null && element.value.length > 0)
        {
            getCurrentFile();
        }
    }, []);

    const checkExists = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`User/CheckSignatureExists`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            if (data === true) {
                setExists(true);
            } else {
                setExists(false);
            }
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setExists(false);
            setLoading(false);
        });
        fetch(`User/GetSignatureFiles`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then((data) => {
            setMySigFiles(data.url);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
        fetch(`User/GetSignatureFilePath`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.text()).then((data) => {
            setMySigPath(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const getCurrentFile = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`User/GetSignatureFileFromPath?sigPath=` + element.value, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then((data) => {
            setSigFiles(data.url);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const removeSig = () => {
        onChange("", element.id);
        setSigFiles(null);
    }

    const handleClick = () => {
        onChange(mySigPath, element.id);
        setSigFiles(mySigFiles);
    }

    return (
        <React.Fragment>

            <Grid item>
                <Typography>{element.text}   {element.helpText &&
                    <LightTooltip title={element.helpText} arrow>
                        <HelpIcon style={{ height: '20px' }} color="secondary" />
                    </LightTooltip>
                }</Typography>
            </Grid>

            <Grid item xs={12}>
                {!exists && (element.value === null || element.value === "") &&

                    <Typography color="error">Please upload a signature to your profile if you are a registered architect</Typography>
                }
                {
                    (element.value !== "" && element.value !== null) &&
                    <img style={{ "maxWidth": "100%" }} src={sigFiles} />
                }
            </Grid>


            <Grid item xs={12}>
                {exists && owner && (element.value === null || element.value === "") &&
                    <LightBlueButton
                        variant="contained"
                        name={element.documentPlaceholder}
                        disabled={loading || !exists}
                        onClick={handleClick}
                    >Click to add your signature</LightBlueButton>
                }
                {owner && (element.value !== "" && element.value !== null) &&
                    <LightBlueButton
                        variant="contained"
                        name={element.documentPlaceholder}
                        onClick={removeSig}
                    >Remove signature</LightBlueButton>
                }
            </Grid>

        </React.Fragment>
    );
}