﻿import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NotificationViewModel from '../ViewModels/NotificationViewModel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import LightBlueButton from '../Utilities/LightBlueButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SquareButton from '../Utilities/SquareButton';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
    })
);

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function NotificationEditor(props: IProps) {
    const classes = useStyles();
    const { open, onClose } = props;
    const [notification, setNotification] = React.useState(new NotificationViewModel());
    const [saving, setSaving] = React.useState(false);
    const [serverError, setError] = React.useState('');

    const close = () => {
        onClose(false);
    }

    const save = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch('Notification/AddNotification', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(notification)
        })
            .then(response => {
                setSaving(false);
                setNotification(new NotificationViewModel());
                onClose(true);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
                setError('A server error has occurred, please try again.');
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotification({
            ...notification,
            [event.target.name]: event.target.value
        });
    }

    const cancel = () => {
        setNotification(new NotificationViewModel());
        onClose(false);
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="notification-editor-title"
            PaperProps={{ className: classes.paper }}
        >
            <ValidatorForm onSubmit={save}>
                <DialogTitle id="notification-editor-title">New Notification</DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">{serverError}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="title">Headline</InputLabel>
                            <TextValidator
                                id="title"
                                name="title"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={notification.title}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="subTitle">Title</InputLabel>
                            <TextValidator
                                id="subTitle"
                                name="subTitle"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={notification.subTitle}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="body">Description</InputLabel>
                            <TextValidator
                                id="body"
                                name="body"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                multiline
                                rows={4}
                                value={notification.body}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={cancel} variant="outlined" color="primary" disabled={saving}>Cancel</SquareButton>
                    <LightBlueButton type="submit" variant="contained" disabled={saving}>Save</LightBlueButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}