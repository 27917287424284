﻿import * as React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LightBlueButton from '../Utilities/LightBlueButton';
import Grid from '@material-ui/core/Grid';
import { BookshopProductViewModel } from '../BookShop/OrderViewmodel';
import BookProductDialog from './BookProductDialog';
import SquareButton from '../Utilities/SquareButton';
import WarningDialog from '../Utilities/WarningDialog';
import authService from '../api-authorization/AuthorizeService';
import Bugsnag from '@bugsnag/js';

interface IProps {
    product: BookshopProductViewModel;
    refreshTable: () => void;
}

export default function UpdateBookshopRow(props: IProps) {
    const { product, refreshTable } = props;
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openWarning, setOpenWarning] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const toggleDialog = (refresh: boolean) => {
        if (refresh) {
            refreshTable();
        }

        setOpenDialog(!openDialog);
    }

    const toggleWarning = () => {
        setOpenWarning(!openWarning);
    }

    const archive = async () => {
        const token = await authService.getAccessToken();
        setDeleting(true);

        fetch(`Bookshop/ArchiveProduct?id=${product.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => {
            setDeleting(false);
            toggleWarning();
            refreshTable();
        }).catch((error) => {
            Bugsnag.notify(error);
            setDeleting(false);
        });
    }

    return (
        <TableRow>
            <TableCell>{product.description}</TableCell>
            <TableCell align="right">{product.weight}</TableCell>
            <TableCell align="right">€{product.cost.toFixed(2)}</TableCell>
            <TableCell align="right">
                <Grid container spacing={1} justify="flex-end" style={{ flexWrap: 'nowrap' }}>
                    <Grid item>
                        <LightBlueButton notextwrap={true} fullWidth size="small" variant="contained" onClick={() => setOpenDialog(true)}>Update</LightBlueButton>
                    </Grid>
                    <Grid item>
                        <SquareButton color="primary" notextwrap={true} fullWidth size="small" variant="contained" onClick={toggleWarning}>Delete</SquareButton>
                        <WarningDialog
                            close={toggleWarning}
                            open={openWarning}
                            okClick={archive}
                            disabled={deleting}
                            buttonText="Delete"
                            text="Are you sure you want to delete this product?"
                        />
                    </Grid>
                </Grid>
                <BookProductDialog open={openDialog} close={toggleDialog} product={product} />
            </TableCell>
        </TableRow>
    );
}