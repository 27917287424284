﻿import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormElementViewModel from '../../ViewModels/FormElementViewModel';
import HelpIcon from '@material-ui/icons/Help';
import LightTooltip from '../../Utilities/LightTooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextValidator } from 'react-material-ui-form-validator';

interface IProps {
    element: FormElementViewModel;
    onChange: (value: string, key: number) => void;
    setShouldSubmit: (key: number) => void;
}

export default function CurrencyInput(props: IProps) {
    const { element, onChange, setShouldSubmit } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value, element.id);
    }

    React.useEffect(() => {
        setShouldSubmit(element.id);
    }, []);

    return (
        <React.Fragment>

            <Grid item>
                <Typography style={{ textAlign: 'justify' }}>{element.text}   {element.helpText &&
                    <LightTooltip title={element.helpText} arrow>
                        <HelpIcon style={{ height: '20px' }} color="secondary" />
                    </LightTooltip>
                }</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextValidator
                    name={element.documentPlaceholder}
                    fullWidth
                    value={element.value ?? ''}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        style: { color: '#1616b1' }
                    }}
                    validators={element.required ? ['required'] : []}
                    errorMessages={element.required ? ['This field is required'] : []}
                />
            </Grid>

        </React.Fragment>
    );
}