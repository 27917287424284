﻿import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        tertiary: {
            main: React.CSSProperties['color'],
        }
    }
    interface PaletteOptions {
        tertiary: {
            main: React.CSSProperties['color']
        }
    }
}

export default function CustomTheme(options: ThemeOptions) {
    return createMuiTheme({
        palette: {
            tertiary: {
                main: '#F05B72'
            }
        },
        ...options
    });
}