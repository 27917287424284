﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GreenButton from './GreenButton';
import { Link, LinkProps, useParams } from 'react-router-dom';

interface RouteParams {
    orderId: string;
}

export default function OrderComplete() {
    const { orderId } = useParams<RouteParams>();

    const bookshopLink = React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link to="/Bookshop" ref={ref} {...itemProps} />
    ));

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}><Typography color="secondary" variant="h5">Thank you for your order.</Typography></Grid>
            <Grid item xs={12}>
                <Typography color="primary">Your order number is: {orderId}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography color="primary">Your order is being processed and an email confirmation has been sent to you.</Typography>
            </Grid>
            <Grid item>
                <GreenButton variant="contained" size="large" component={bookshopLink}><b>Return to Bookshop</b></GreenButton>
            </Grid>
        </Grid>
    );
}