﻿import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormElementViewModel from '../../ViewModels/FormElementViewModel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import LightBlueButton from '../../Utilities/LightBlueButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SquareButton from '../../Utilities/SquareButton';
import authService from '../../api-authorization/AuthorizeService';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormElementDropdown from './ElementTypeDropdown';
import TextTypeDropdown from './TextTypeDropdown';
import ProjectTemplateDropdown from './ProjectTemplateDropdown';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
    })
);

interface IProps {
    formId: number;
    elementId: number;
    open: boolean;
    onClose: (refresh: boolean) => void;
    onRefresh: () => void;
}

export default function NewFormElement(props: IProps) {
    const classes = useStyles();
    const { open, onClose, elementId, formId, onRefresh } = props;
    const [newElement, setElement] = React.useState(new FormElementViewModel());
    const [loading, setLoading] = React.useState(false);
    const [serverError, setError] = React.useState('');

    React.useEffect(() => {
        const element = new FormElementViewModel();
        element.formTypeId = formId;
        setElement(element);
    }, [formId]);

    React.useEffect(() => {
        getElement();
    }, [elementId]);

    const getElement = async () => {
        // get element if not adding
        if (elementId > 0) {
            setLoading(true);
            const token = await authService.getAccessToken();
            fetch(`FormBuilder/GetFormElement?id=${elementId}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.json())
                .then(data => {
                    setElement(data);
                    setLoading(false);
                })
                .catch(error => {
                    Bugsnag.notify(error);
                    setLoading(false);
                });
        }
    }

    const close = () => {
        onClose(false);
    }

    const save = async () => {
        const token = await authService.getAccessToken();

        fetch('FormBuilder/AddFormElement', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(newElement)
        })
            .then(response => response.text())
            .then(data => {
                const element = new FormElementViewModel();
                element.formTypeId = formId;
                element.formElementTypeId = newElement.formElementTypeId;
                element.textTypeId = newElement.textTypeId;
                element.order = 0;
                setElement(element);
                onRefresh();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setError('A server error has occurred, please try again.');
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setElement({
            ...newElement,
            [event.target.name]: event.target.value
        });
    }

    const onCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setElement({
            ...newElement,
            [event.target.name]: event.target.checked
        });
    }

    const cancel = () => {
        onClose(false);
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="add-element-title"
            PaperProps={{ className: classes.paper }}
        >
            <ValidatorForm onSubmit={save}>
                <DialogTitle id="add-element-title">{newElement.id === 0 ? 'Add' : 'Edit'} Form Element</DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">{serverError}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormElementDropdown id={newElement.formElementTypeId} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="text">Text</InputLabel>
                            <TextValidator
                                id="text"
                                name="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                multiline
                                rows={4}
                                value={newElement.text}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextTypeDropdown id={newElement.textTypeId} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="helpText">Help Text</InputLabel>
                            <TextValidator
                                id="helpText"
                                name="helpText"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                multiline
                                rows={4}
                                value={newElement.helpText ?? ''}
                                onChange={onChange}
                            />
                        </Grid>
                        {newElement.formElementTypeId == 11 &&
                            <Grid item xs={12}>
                                <InputLabel htmlFor="text">Value on False ✗</InputLabel>
                                <TextValidator
                                    id="replaceOnFalse"
                                    name="replaceOnFalse"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    value={newElement.replaceOnFalse}
                                    onChange={onChange}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <InputLabel htmlFor="charLimit">Character Limit</InputLabel>
                            <TextValidator
                                id="charLimit"
                                name="charLimit"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                type="number"
                                rows={4}
                                value={newElement.charLimit ?? 0}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ProjectTemplateDropdown id={newElement.projectTemplateId} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={newElement.required}
                                        onChange={onCheckChange}
                                        name="required"
                                        color="secondary"
                                    />
                                }
                                label="Required"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={cancel} variant="outlined" color="primary">Cancel</SquareButton>
                    <LightBlueButton type="submit" variant="contained" disabled={loading}>Save</LightBlueButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}