﻿import * as React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: 0
        },
    }));

interface IProps extends ButtonProps {
    component?: any;
    notextwrap?: boolean;
}

export default function SquareButton(props: IProps) {
    const classes = useStyles();
    const { component, notextwrap, ...rest } = props;

    return (
        <React.Fragment>
            {component ?
                <Button {...rest} className={classes.root} style={notextwrap ? { whiteSpace: 'nowrap' } : {}} component={component} />
                :
                <Button {...rest} style={notextwrap ? { whiteSpace: 'nowrap' } : {}} className={classes.root} />
            }
        </React.Fragment>
    );
}