﻿import * as React from 'react';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ColourPaper from '../Utilities/ColourPaper';
import LightBlueButton from '../Utilities/LightBlueButton';
import InputLabel from '@material-ui/core/InputLabel';
import { UserDropdownViewModel } from '../ViewModels/UserViewModel';
import MainOwnerDropdown from './MainOwnerDropdown';
import { OrganisationContext } from './OrganisationContext';
import Bugsnag from '@bugsnag/js';

interface IProps {
    role: string;
}

export default function EditOrganisation(props: IProps) {
    const { role } = props;
    const [name, setName] = React.useState('');
    const [owner, setOwner] = React.useState<UserDropdownViewModel | null>(null);
    const [saving, setSaving] = React.useState(false);
    const [serverError, setError] = React.useState('');
    const { organisation, refresh } = React.useContext(OrganisationContext);

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        setName(organisation);
    }, [organisation]);

    const getData = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`Organisation/GetMainOwner`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setOwner(data);
                setSaving(false);
                setError('');
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
                setError('A server error has occurred, please try again.');
            });
    }

    const updateName = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        fetch(`Organisation/ChangeName?name=${name}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    setError(data);
                } else {
                    setError('');
                    refresh();
                }
                setSaving(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setSaving(false);
                setError('A server error has occurred, please try again.');
            });
    }

    const updateOwner = async () => {
        if (owner !== null) {
            setSaving(true);
            const token = await authService.getAccessToken();

            fetch(`Organisation/ChangeMainOwner?id=${owner.id}`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.text())
                .then(data => {
                    if (data.length > 0) {
                        setError(data);
                    } else {
                        setError('');
                    }
                    setSaving(false);
                })
                .catch(error => {
                    Bugsnag.notify(error);
                    setSaving(false);
                    setError('A server error has occurred, please try again.');
                });
        }
    }

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const onOwnerChange = (event: any, newValue: UserDropdownViewModel | null) => {
        setOwner(newValue);
    }

    return (
        <ColourPaper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Edit Organisation</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="error">{serverError}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <ValidatorForm onSubmit={updateName}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="name">Change Name</InputLabel>
                            <TextValidator
                                id="name"
                                name="name"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={name}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onNameChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LightBlueButton fullWidth variant="contained" type="submit" disabled={saving}>Update Name</LightBlueButton>
                        </Grid>
                    </ValidatorForm>
                </Grid>
                {role === 'Admin' &&
                    <Grid item xs={12}>
                        <ValidatorForm onSubmit={updateOwner}>
                            <Grid item xs={12}>
                                <MainOwnerDropdown mainOwner={owner} onChange={onOwnerChange} label="Change Owner" />
                            </Grid>
                            <Grid item xs={12}>
                                <LightBlueButton fullWidth variant="contained" type="submit" disabled={saving || owner === null || owner.id === null}>Update Owner</LightBlueButton>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                }
            </Grid>
        </ColourPaper>
    );
}