 import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormElementViewModel from '../../ViewModels/FormElementViewModel';
import HelpIcon from '@material-ui/icons/Help';
import LightTooltip from '../../Utilities/LightTooltip';
import FormElementLookupViewModel from '../../ViewModels/FormElementLookupViewModel';
import MenuItem from '@material-ui/core/MenuItem';
import authService from '../../api-authorization/AuthorizeService';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SelectValidator } from 'react-material-ui-form-validator';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Bugsnag from '@bugsnag/js';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            whiteSpace: 'normal'
        },
        select: {
            whiteSpace: 'normal'
        },
    }),
);

interface IProps {
    element: FormElementViewModel;
    onChange: (value: string, key: number) => void;
    setShouldSubmit: (key: number) => void;
}

export default function SelectInput(props: IProps) {
    const classes = useStyles();
    const { element, onChange, setShouldSubmit } = props;
    const [items, setItems] = React.useState<FormElementLookupViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        setLoaded(false);
        setShouldSubmit(element.id);
        getItems();
    }, []);

    const getItems = async () => {
        const token = await authService.getAccessToken();

        fetch(`Forms/GetElementLookups?id=${element.id}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setItems(data);
                setLoaded(true);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoaded(true);
            });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value.toString(), element.id);
    }

    return (
        <React.Fragment>
            <Grid item><Typography>{element.text}</Typography></Grid>
            <Grid item>{element.helpText &&
                <LightTooltip title={element.helpText} arrow>
                    <HelpIcon color="secondary" />
                </LightTooltip>
            }</Grid>
            <Grid item xs={12}>
                <SelectValidator
                    select
                    name={element.documentPlaceholder}
                    fullWidth
                    value={element.value ?? ''}
                    onChange={handleChange}
                    InputProps={{
                        required: element.required,
                        disabled: !loaded,
                        startAdornment: (
                            <React.Fragment>
                                {loaded ? null : <CircularProgress size={20} />}
                            </React.Fragment>
                        ),
                        style: { color: '#1616b1' }
                    }}
                    inputProps={{
                        className: classes.select
                    }}
                    validators={element.required ? ['required'] : []}
                    errorMessages={element.required ? ['This field is required'] : []}
                >
                    {items.map(item => {
                            return <MenuItem key={item.id} value={item.optionIndex} classes={{ root: classes.root }}>{parse(item.optionValue)}</MenuItem>;
                        })}
                </SelectValidator>
            </Grid>
            
        </React.Fragment >
    );
}