﻿import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import authService from '../../api-authorization/AuthorizeService';
import CircularProgress from '@material-ui/core/CircularProgress';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import { SelectValidator } from 'react-material-ui-form-validator';
import Bugsnag from '@bugsnag/js';

interface IDropdownProps {
    id: number;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function TextTypeDropdown(props: IDropdownProps) {
    const [list, setList] = React.useState<DropdownViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        setLoaded(false);
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        fetch('FormBuilder/GetTextTypes', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setList(data);
                setLoaded(true);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoaded(true);
            });
    }

    const items = list.map((item, index) =>
        <MenuItem key={index} value={item.key}>{item.value}</MenuItem>);

    return (
        <SelectValidator
            label="Text Type"
            name="textTypeId"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={props.onChange}
            value={items.length <= 0 || props.id === 0 ? '' : props.id}
            InputProps={{
                disabled: !loaded,
                startAdornment: (
                    <React.Fragment>
                        {loaded ? null : <CircularProgress size={20} />}
                    </React.Fragment>
                )
            }}
            validators={['required']}
            errorMessages={['This field is required']}
        >
            {items}
        </SelectValidator>
    );
}