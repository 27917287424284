﻿import * as React from 'react';
import ColourPaper from '../Utilities/ColourPaper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LightBlueButton from '../Utilities/LightBlueButton';
import LightGreenButton from '../Utilities/LightGreenButton';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormViewModel from '../ViewModels/FormViewModel';
import authService from '../api-authorization/AuthorizeService';
import LinearProgress from '@material-ui/core/LinearProgress';
import Collapse from '@material-ui/core/Collapse';
import FormElementViewModel from '../ViewModels/FormElementViewModel';
import DisplayText from './FormPages/DisplayText';
import FreeText from './FormPages/FreeText';
import SelectInput from './FormPages/SelectInput';
import DateInput from './FormPages/DateInput';
import CurrencyInput from './FormPages/CurrencyInput';
import CheckboxInput from './FormPages/CheckboxInput';
import { ValidatorForm } from 'react-material-ui-form-validator';
import SignatureInput from './FormPages/SignatureInput';
import StampInput from './FormPages/StampInput';
import FixedScroll from '../Utilities/FixedScroll';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Spacer from './FormPages/Spacer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ProjectTemplateViewModel from '../ViewModels/ProjectTemplateViewModel';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import YesNoRadioInput from './FormPages/YesNoRadioInput';
import LightTooltip from '../Utilities/LightTooltip';
import HelpIcon from '@material-ui/icons/Help';
import Bugsnag from '@bugsnag/js';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            color: theme.palette.primary.main
        },
        fixedTitle: {
            position: 'sticky',
            top: `calc(${theme.mixins.toolbar.minHeight}px + 8px)`,
            backgroundColor: theme.palette.background.paper,
            zIndex: 5
        },
        gutterBottom: {
            paddingBottom: theme.spacing(1)
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }));

interface LocationState {
    instanceId: number;
    formId: number;
    isTemplate: boolean;
    projectTemplate: ProjectTemplateViewModel;
}

interface AlertMessage {
    success: boolean;
    message: string;
}

export default function EditFormDraft() {
    const location = useLocation<LocationState>();
    const history = useHistory();
    const { formId, isTemplate, projectTemplate } = location.state || {
        instanceId: 0,
        formId: 0,
        isTemplate: false,
        templateId: null,
    };
    let { instanceId } = location.state || {
        instanceId: 0
    };
    const classes = useStyles();
    const [form, setForm] = React.useState(new FormViewModel());
    const [name, setName] = React.useState('');
    const [loaded, setLoaded] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [role, setRole] = React.useState('');
    const [canSign, setCanSign] = React.useState(false);
    const [nameError, setError] = React.useState(false);
    const [batching, setBatching] = React.useState(false);
    const [open, setDialogOpen] = React.useState(false);
    const [confirmationOpen, setDialogConfirmationOpen] = React.useState(false);
    const [message, setMessage] = React.useState<AlertMessage>({ success: true, message: "" });
    const [unsavedChanges, setUnsavedChanges] = React.useState(false);

    const [showWarning, setShowWarning] = React.useState(false);
    const [timeoutId, setTimeoutId] = React.useState(0);

    const friendlyNameRef = React.useRef<HTMLInputElement>(null);

    const logoutTimer = () => {
        const id = window.setTimeout(
            () => {
                setShowWarning(true);
            }, 55 * 60 * 1000);
        setTimeoutId(id);
    }

    const restartTimer = () => {
        if (timeoutId > 0) {
            window.clearTimeout(timeoutId);
            logoutTimer();
        }
    }

    const handleWarningClose = () => {
        setShowWarning(false);
    }

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleConfirmationClose = () => {
        setDialogConfirmationOpen(false);
    };

    React.useEffect(() => {
        setUnsavedChanges(false);
        getCanSign();

        if (timeoutId <= 0) {
            logoutTimer();
        }

        if (formId > 0) {
            getCredits();
        }
    }, []);

    const getCanSign = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setRole(user ? user.role : '');

        fetch(`User/GetCanSign`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setCanSign(data);
            restartTimer();
        }).catch(error => {
            Bugsnag.notify(error);
        });
    }

    const getCredits = async () => {
        if ((instanceId) && (instanceId > 0)) {
            getForm();
        } else {
            const token = await authService.getAccessToken();
            setLoaded(false);

            fetch(`Forms/GetCredits?id=${formId}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            }).then(response => response.json()).then((data) => {
                if (data > 0) {
                    getForm();
                    restartTimer();
                } else {
                    history.push("/Documents");
                }
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoaded(true);
            });
        }
    }

    const getForm = async () => {
        const token = await authService.getAccessToken();
        setLoaded(false);

        if ((instanceId) && (instanceId > 0)) {
            const url = `Forms/GetDraft?id=${instanceId}`;

            fetch(url, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            }).then(response => response.json()).then((data) => {
                if ((isTemplate === undefined || !isTemplate) && (instanceId === undefined || instanceId === 0)) {
                    data.friendlyName = '';
                    setName(data.name);
                } else {
                    setName(data.name);
                }
                setForm(data);
                setLoaded(true);
                restartTimer();
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoaded(true);
            });

        } else {
            fetch(`Forms/GetNewForm?formId=${formId}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            }).then(response => response.json()).then((data) => {
                setForm(data);
                setName(data.name);
                setLoaded(true);
                restartTimer();
            }).catch((error) => {
                Bugsnag.notify(error);
                setLoaded(true);
            });
        }
    }

    const saveForm = async () => {
        setShowWarning(false);

        if (form.userReference.length <= 0) {
            setError(true);
        } else {
            const token = await authService.getAccessToken();
            setSaving(true);

            const url = `Forms/SaveDraft`;

            fetch(url, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(form, replacer)
            })
                .then(response => response.json())
                .then(data => {
                    setSaving(false);
                    setUnsavedChanges(false);
                    restartTimer();
                    setForm(data);

                }).catch((error) => {
                    Bugsnag.notify(error);
                    setSaving(false);
                });
        }
    }

    function replacer(key: string, value: any) {
        if (key === "text") return '';
        else if (key === "documentPlaceholder") return '';
        else if (key === "helpText") return '';
        else return value;
    }

    // generate pdf
    const completeForm = async (batching: boolean) => {
        handleClose();

        if (form.userReference.length <= 0) {
            setError(true);
        } else {
            const token = await authService.getAccessToken();
            setSaving(true);

            let url = `Forms/CompleteForm`;

            fetch(url, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(form, replacer)
            })
                .then(response => response.text())
                .then(data => {
                    setBatching(batching);
                    if (data.length > 0) {
                        setMessage({ success: false, message: data });
                    } else {
                        setUnsavedChanges(false);
                        setDialogConfirmationOpen(true);
                        if (batching) {
                            setForm({
                                ...form,
                                id: 0
                            });
                        }
                    }
                    setSaving(false);
                    restartTimer();

                }).catch((error) => {
                    Bugsnag.notify(error);
                    setMessage({ success: false, message: "An error has occurred, please try again." });
                    setSaving(false);
                });
        }
    }

    const previewForm = async () => {
        if (form.userReference.length <= 0) {
            setError(true);
        } else {
            if (!(form.id > 0) || unsavedChanges) {
                setSaving(true);
                const token = await authService.getAccessToken();

                fetch(`Forms/SaveDraft`, {
                    method: 'POST',
                    headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Content-Type': 'application/json; charset=utf-8', 'Authorization': `Bearer ${token}` },
                    body: JSON.stringify(form, replacer)
                })
                    .then(response => response.json())
                    .then(data => {
                        setSaving(false);
                        setUnsavedChanges(false);
                        restartTimer();

                        data.previewCount += 1;
                        setForm(data);

                        window.open('Forms/PdfPreviewForm?formInstanceId=' + data.id, '_blank');
                    }).catch((error) => {
                        Bugsnag.notify(error);
                        setSaving(false);
                    });
            } else {
                const url = `Forms/PdfPreviewForm?formInstanceId=` + form.id;

                window.open(url, '_blank');

                setForm({
                    ...form,
                    previewCount: form.previewCount + 1,
                });
            }
        }
    }

    const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setTimeout(() => {
            setMessage({ success: true, message: "" });
        }, 250);
    }

    const confirmationOk = () => {
        history.push("/Documents");
    }

    const changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 0) {
            setError(false);
        } else {
            setError(true);
        }

        setForm({
            ...form,
            userReference: event.target.value
        });
        setUnsavedChanges(true);
    }

    React.useEffect(() => {
        if (friendlyNameRef.current) {
            friendlyNameRef.current.focus();
        }
    }, [form.userReference])

    const setShouldSubmit = (key: number) => {
        const copy = [...form.formElements];
        const index = copy.findIndex(i => i.id === key);

        copy[index].shouldSubmit = true;

        setForm({
            ...form,
            formElements: copy
        });
    }

    const onChange = (value: string, key: number) => {
        const copy = [...form.formElements];
        const index = copy.findIndex(i => i.id === key);

        copy[index].value = value;

        setForm({
            ...form,
            formElements: copy
        });
        setUnsavedChanges(true);
    }


    const getElement = (item: FormElementViewModel) => {
        switch (item.formElementType) {
            case "Spacer":
                return <Spacer key={item.id} />;
            case "Display Text":
                return <DisplayText key={item.id} element={item} />;
            case "Free Text Input":
                return <FreeText key={item.id} element={item} onChange={onChange} setShouldSubmit={setShouldSubmit} projectTemplate={projectTemplate} />;
            case "Select Input":
                return <SelectInput key={item.id} element={item} onChange={onChange} setShouldSubmit={setShouldSubmit} />;
            case "Date Input":
                return <DateInput key={item.id} element={item} onChange={onChange} setShouldSubmit={setShouldSubmit} />;
            case "Currency Input":
                return <CurrencyInput key={item.id} element={item} onChange={onChange} setShouldSubmit={setShouldSubmit} />;
            case "Checkbox Input":
                return <CheckboxInput key={item.id} element={item} onChange={onChange} setShouldSubmit={setShouldSubmit} />;
            case "Signature Input":
                return <SignatureInput key={item.id} element={item} onChange={onChange} setShouldSubmit={setShouldSubmit} owner={(canSign || role === 'Admin')} />
            case "Stamp Input":
                if (!isTemplate && (canSign || role === 'Admin')) {
                    return <StampInput key={item.id} element={item} onChange={onChange} setShouldSubmit={setShouldSubmit} />
                } else {
                    return <React.Fragment />;
                }
            case "YesNo Input":
                return <YesNoRadioInput key={item.id} element={item} onChange={onChange} setShouldSubmit={setShouldSubmit} />;
            default:
                return <React.Fragment />;
        }
    }


    const fixedPaper = (props: PaperProps) => <Paper style={{ padding: '15px' }} square {...props} />;

    const fixedHeader =
        <FixedScroll>
            <Grid container spacing={2} justify="space-between" alignItems="center" className={classes.fixedTitle} component={fixedPaper}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h5">{name}</Typography>
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: '-15px' }}>
                    <InputLabel className={classes.label}>{'FORM REFERENCE'}
                        <LightTooltip title="Enter a Reference that you can use to identify this Document in the future. This can be anything you wish; you may for example decide to use your Project Job Reference." arrow>
                            <HelpIcon style={{ height: '20px' }} color="secondary" />
                        </LightTooltip>
                    </InputLabel>
                    <TextField
                        fullWidth
                        name="friendlyName"
                        value={form.userReference}
                        onChange={changeName}
                        error={nameError}
                        helperText={nameError ? 'This field is required' : ''}
                        inputRef={friendlyNameRef}
                        inputProps={{
                            style: { color: '#1616b1' }
                        }}
                    />
                </Grid>

                <Grid item>
                    <Grid container spacing={2} justify="flex-end">
                        <Grid item>
                            <LightBlueButton variant="contained" disabled={saving || !loaded} onClick={confirmationOk}>Cancel</LightBlueButton>
                        </Grid>
                        {form.adviceNotes !== null && form.adviceNotes.length > 0 &&
                            <Grid item>
                                <LightBlueButton
                                    variant="contained"
                                    target="blank"
                                    href={`/Forms/GetAdvice?id=${form.formType}`}>
                                    Advice Notes
                                </LightBlueButton>
                            </Grid>
                        }
                        <Grid item>
                            <LightBlueButton variant="contained" target="_blank" disabled={saving || !loaded} href={`Forms/GetSampleDocument?formId=${formId}`}>View Sample PDF</LightBlueButton>
                        </Grid>
                        <Grid item>
                            <LightBlueButton variant="contained" name="complete" onClick={saveForm} disabled={saving || !loaded}>{isTemplate ? 'Save Template' : 'Save Draft'}</LightBlueButton>
                        </Grid>

                        <Grid item>
                            {saving &&
                                <LightGreenButton variant="contained" disabled={true}>Preview ({form.maxPreviews - form.previewCount}/{form.maxPreviews} remaining)
                                </LightGreenButton>
                            }
                            {!saving &&
                                <LightGreenButton variant="contained" disabled={saving || !loaded || (form.previewCount >= form.maxPreviews)} onClick={previewForm}>Preview ({form.maxPreviews - form.previewCount}/{form.maxPreviews} remaining)</LightGreenButton>
                            }
                        </Grid>

                        {!isTemplate &&
                            <Grid item>
                                <LightGreenButton variant="contained" disabled={saving || !loaded} onClick={handleClickOpen}>Generate PDF</LightGreenButton>
                            </Grid>
                        }


                    </Grid>
                </Grid>
            </Grid>
        </FixedScroll>;

    return (
        <ColourPaper>
            {!loaded && <LinearProgress />}
            <Prompt
                when={unsavedChanges}
                message={"Are you sure you want to leave? Any unsaved changes you have made will be lost."}
            />
            <Collapse in={loaded}>
                <ValidatorForm onSubmit={() => { completeForm(true); }}>
                    {fixedHeader}
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" style={{ padding: '22px', marginBottom: '10px' }}>
                        {form.formElements.map(item => {
                            return getElement(item)
                        })}
                    </Grid>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="submit-title"
                        aria-describedby="submit-description"
                    >
                        <DialogTitle id="submit-title">{"Submit document?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="submit-description">
                                {form.category === 'Health and Safety Documents' &&
                                    <Alert severity="info" className={classes.gutterBottom}>
                                        This document is part of the suite of documents relating to the Safety, Health and Welfare at Work (Construction) Regulations 2013. Please ensure that you complete the full set of Health & Safety documents for this project.
                                    </Alert>
                                }

                                Once you have submitted this document for PDF generation, you will not be able to make any more changes. Do you want to continue?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Return to draft
                            </Button>
                            <LightGreenButton variant="contained" type="submit" onClick={() => { completeForm(false); }} disabled={saving || !loaded} autoFocus>Submit & Generate PDF</LightGreenButton>
                            <LightGreenButton variant="contained" type="submit" onClick={() => { completeForm(true); }} disabled={saving || !loaded} autoFocus>Generate PDF and Batch</LightGreenButton>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={confirmationOpen}
                        onClose={handleConfirmationClose}
                        aria-labelledby="submitted-title"
                        aria-describedby="submitted-description"
                    >
                        <DialogTitle id="submitted-title">{"Document Submitted"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="submitted-description">
                                Your document has been submitted for generation. It will be sent to your email address when it has completed.
                            </DialogContentText>

                        </DialogContent>
                        <DialogActions>
                            {!batching &&
                                <Button onClick={confirmationOk} color="primary">
                                    Ok
                                </Button>
                            }

                            {batching &&
                                <Button onClick={handleConfirmationClose} color="primary">
                                    Next
                                </Button>
                            }

                        </DialogActions>
                    </Dialog>
                </ValidatorForm>
                <Snackbar open={message.message.length > 0} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} severity={message.success ? "success" : "error"}>
                        {message.message}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={showWarning}
                    onClose={handleWarningClose}
                    aria-labelledby="idle-warning-title"
                    aria-describedby="idle-warning-description"
                >
                    <DialogTitle id="idle-warning-title">{"Inactivity Warning"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="idle-warning-description">
                            You will be logged out in 5 minutes due to inactivity. You will lose any unsaved changes. Save your changes or navigate to a different part of the site to stay logged in.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LightBlueButton onClick={handleWarningClose} variant="contained">Ok</LightBlueButton>
                        <LightGreenButton variant="contained" onClick={saveForm}>Save & Continue</LightGreenButton>
                    </DialogActions>
                </Dialog>
            </Collapse>
        </ColourPaper>
    );
}