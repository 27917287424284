import React from 'react';
import { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';
import Bugsnag from '@bugsnag/js';

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false,
            isAdmin: false,
            isOwner: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated, isOwner, isAdmin } = this.state;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`
        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, admin, owner, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        if ((admin && owner) && (isAdmin || isOwner)) {
                            return <Component {...props} />
                        }
                        else if ((admin && owner) && (!isAdmin && !isOwner)) {
                            return <Redirect to="/" />
                        }
                        else if (admin && !isAdmin) {
                            return <Redirect to="/" />
                        }
                        else if (owner && !isOwner) {
                            return <Redirect to="/" />
                        }

                        return <Component {...props} />
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        let isAdmin = false;
        let isOwner = false;

        if (authenticated) {
            const user = await authService.getUser();
            const role = user ? user.role : '';
            isAdmin = role === "Admin";

            const token = await authService.getAccessToken();

            try {
                const response = await fetch('Organisation/GetMainOwnerStatus', {
                    headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                }).catch((error) => {
                    Bugsnag.notify(error);
                });

                isOwner = await response.json();
            } catch (error) {
            }
        }

        this.setState({ ready: true, authenticated, isAdmin, isOwner });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}

AuthorizeRoute.defaultProps = { admin: false, owner: false };
