﻿export default class UserViewModel {
    public id: string = '';
    public memberId: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public userLevel: string = '';
}

export class UserDropdownViewModel {
    public id: string = '';
    public name: string = '';
}