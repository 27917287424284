﻿import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import WarningDialog from '../Utilities/WarningDialog';
import { Alert } from '@material-ui/lab';
import Bugsnag from '@bugsnag/js';

class FileNames {
    signatureFile: string = '';
    stampFile: string = '';
}

export default function SigningFiles() {
    const [signature, setSignature] = React.useState<File>();
    const [names, setNames] = React.useState(new FileNames());
    const [sigFiles, setSigFiles] = React.useState<any>();
    const [loading, setLoading] = React.useState(false);
    const [openSigWarning, setSigOpen] = React.useState(false);

    React.useEffect(() => {
        getFiles();
    }, []);

    const getFiles = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`User/GetSignatureFilesName`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setNames(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
        fetch(`User/GetSignatureFiles`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then((data) => {
            setSigFiles(data.url);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const submitSig = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        let formData = new FormData();
        if (signature) {
            formData.append('signature', signature);
        }

        fetch('User/UploadSignature', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            body: formData
        }).then(response => {
            setLoading(false);
            toggleSigWarning();
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null) {
            setSignature(event.target.files[0]);
            setNames({
                ...names,
                signatureFile: event.target.files[0].name
            });
        }
    }

    const toggleSigWarning = () => {
        setSigOpen(!openSigWarning);
    }

    const signame = signature ?
        signature.name :
        names.signatureFile ?
            names.signatureFile.split("/").pop() :
            "";

    const sigClick = (signame !== undefined && signame.length) > 0 ? toggleSigWarning : submitSig;

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h5">Signing Files</Typography>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="info">For best results, please use a <b>PNG image with a transparent background</b> and is <b>250 x 52</b> or an equivalent aspect ratio.</Alert>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Signature</Typography>
            </Grid>
            <Grid item xs={12} >
                {
                    signature &&
                    <img style={{ "maxWidth": "100%" }} src={URL.createObjectURL(signature)} />
                }
                {
                    names.signatureFile && !signature &&
                    <img style={{ "maxWidth": "100%" }} src={sigFiles} />
                }
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <input id={"signature"} name="signature" type="file" accept="image/x-png" hidden onChange={handleChange} />

                        <label htmlFor={"signature"}>
                            <LightBlueButton
                                aria-label="signature file"
                                variant="contained"
                                endIcon={<CloudUploadIcon />}
                                component="span"
                                disabled={loading}
                            >
                                Upload Signature
                            </LightBlueButton>
                        </label>
                    </Grid>
                    <Grid item>
                        <Typography>{signame}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <LightBlueButton fullWidth variant="contained" onClick={sigClick} disabled={loading}>Submit Signature</LightBlueButton>
            </Grid>
            {signame !== undefined && signame.length > 0 &&
                <WarningDialog
                    close={toggleSigWarning}
                    open={openSigWarning}
                    okClick={submitSig}
                    disabled={loading}
                    buttonText="Continue"
                    text="This will replace the currently uploaded signature."
                />
            }
        </React.Fragment>
    );
}