﻿import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormElementViewModel from '../../ViewModels/FormElementViewModel';
import HelpIcon from '@material-ui/icons/Help';
import LightTooltip from '../../Utilities/LightTooltip';
import LightBlueButton from '../../Utilities/LightBlueButton';
import authService from '../../api-authorization/AuthorizeService';
import Bugsnag from '@bugsnag/js';

interface IProps {
    element: FormElementViewModel;
    onChange: (value: string, key: number) => void;
    setShouldSubmit: (key: number) => void;
}

export default function StampInput(props: IProps) {
    const { element, onChange, setShouldSubmit } = props;
    const [exists, setExists] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [stampFiles, setStampFiles] = React.useState<any>();
    React.useEffect(() => {
        setShouldSubmit(element.id);
        checkExists();

    }, []);

    const checkExists = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        fetch(`User/CheckStampExists`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setExists(data);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
        fetch(`User/GetStampFiles`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then((data) => {
            setStampFiles(data.url);
            setLoading(false);
        }).catch((error) => {
            Bugsnag.notify(error);
            setLoading(false);
        });
    }

    const handleClick = () => {
        onChange("True", element.id);
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography>{element.text}</Typography>
            </Grid>
            <Grid item xs={11}>
                <LightBlueButton
                    variant="contained"
                    name={element.documentPlaceholder}
                    disabled={element.value === 'True' || loading || !exists}
                    onClick={handleClick}
                >
                    Stamp Form
                </LightBlueButton>
            </Grid>
            {element.helpText &&
                <Grid item xs={1} style={{ textAlign: "center" }}>
                    <LightTooltip title={element.helpText} arrow>
                        <HelpIcon color="secondary" />
                    </LightTooltip>
                </Grid>
            }
            {!exists &&
                <Grid item xs={12}>
                    <Typography color="error">Please upload a stamp to your profile</Typography>
                </Grid>
            }
            {
                exists && element.value === 'True' &&
                <Grid item xs={12}>
                    <img style={{ "maxWidth": "100%" }} src={stampFiles} />
                </Grid>
            }
        </React.Fragment>
    );
}