import * as React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

export default class KeyboardDateValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            ...rest
        } = this.props;
        const { isValid } = this.state;

        return (
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        {...rest}
                        error={!isValid || error}
                        helperText={(!isValid && this.getErrorMessage()) || helperText}
                    />
                </MuiPickersUtilsProvider>
            </div>
        );
    }
} 