﻿import * as React from 'react';
import CartItemViewModel from '../ViewModels/CartItemViewModel';

type Action = { type: 'addItem', item: CartItemViewModel } | { type: 'removeItem', item: CartItemViewModel } | { type: 'removeAllItem', item: CartItemViewModel } | { type: 'clear' };
type Dispatch = (action: Action) => void;
type State = { cart: CartItemViewModel[] };
type CountProviderProps = { children: React.ReactNode };

const CartStateContext = React.createContext<State | undefined>(undefined);
const CartDispatchContext = React.createContext<Dispatch | undefined>(undefined,);

function cartReducer(state: State, action: Action) {
    switch (action.type) {
        case 'addItem': {
            const newCart = [...state.cart];
            const itemIndex = newCart.findIndex(i => i.formName === action.item.formName);

            if (itemIndex < 0) {
                newCart.push({ ...action.item, numberOfUnits: 1 });
            } else {
                const newItem = { ...newCart[itemIndex] };
                newItem.numberOfUnits++;
                newCart[itemIndex] = newItem;
            }

            return { ...state, cart: newCart };
        }
        case 'removeItem': {
            const newCart = [...state.cart];
            const itemIndex = newCart.findIndex(i => i.formName === action.item.formName);
            const newItem = { ...newCart[itemIndex] };

            newItem.numberOfUnits--;

            if (newItem.numberOfUnits <= 0) {
                newCart.splice(itemIndex, 1);
            } else {
                newCart[itemIndex] = newItem;
            }

            return { ...state, cart: newCart };
        }
        case 'removeAllItem': {
            const newCart = [...state.cart];
            const itemIndex = newCart.findIndex(i => i.formName === action.item.formName);

            newCart.splice(itemIndex, 1);

            return { ...state, cart: newCart };
        }
        case 'clear': {
            return { ...state, cart: [] };
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
}

export function CartProvider({ children }: CountProviderProps) {
    const [state, dispatch] = React.useReducer(cartReducer, { cart: [] });

    return (
        <CartStateContext.Provider value={state}>
            <CartDispatchContext.Provider value={dispatch}>
                {children}
            </CartDispatchContext.Provider>
        </CartStateContext.Provider>
    );
}

export function useCartState() {
    const context = React.useContext(CartStateContext);

    if (context === undefined) {
        throw new Error('useCartState must be used within a CartProvider');
    }

    return context;
}

export function useCartDispatch() {
    const context = React.useContext(CartDispatchContext);

    if (context === undefined) {
        throw new Error('useCartDispatch must be used within a CartProvider');
    }

    return context;
}