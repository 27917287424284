﻿import * as React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#fff',
            backgroundColor: '#00e863',
            '&:hover': {
                backgroundColor: '#00b34c'
            },
            borderRadius: 0
        },
    }));

interface IProps extends ButtonProps {
    component?: any;
    notextwrap?: boolean;
    target?: string;
}

const defaultProps: IProps = {
    component: undefined,
    notextwrap: false,
    target: '_self'
}

function LightGreenButton(props: IProps) {
    const classes = useStyles();
    const { component, notextwrap, ...rest } = props;

    return (
        <React.Fragment>
            {component ?
                <Button {...rest} target="" className={classes.root} style={notextwrap ? { whiteSpace: 'nowrap' } : {}} component={component} />
                :
                <Button {...rest} className={classes.root} style={notextwrap ? { whiteSpace: 'nowrap' } : {}} />
            }
        </React.Fragment>
    );
}

LightGreenButton.defaultProps = defaultProps;
export default LightGreenButton;