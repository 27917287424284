﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import SquareButton from '../Utilities/SquareButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
        img: {
            maxWidth:`100%`
        }
    })
);

interface IProps {
    formId: number;
    open: boolean;
    close: (refresh: boolean) => void;
}

export default function ViewPreviewDialog(props: IProps) {
    const classes = useStyles();
    const { formId, open, close } = props;

    const cancel = () => {
        close(false);
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="new-price-title"
            PaperProps={{ className: classes.paper }}
        >
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img src={"previewimages/" + formId + ".jpg"} className={ classes.img } />
                    </Grid>
                </Grid>
               
            </DialogContent>
            <DialogActions>
                <SquareButton onClick={cancel} variant="outlined" color="primary">Close</SquareButton>
            </DialogActions>
        </Dialog>
    );
}