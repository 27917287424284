﻿import * as React from 'react';
import HistoryViewModel from "../ViewModels/HistoryViewModel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LightBlueButton from '../Utilities/LightBlueButton';
import SquareButton from '../Utilities/SquareButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import WarningDialog from '../Utilities/WarningDialog';
import authService from '../api-authorization/AuthorizeService';
import Bugsnag from '@bugsnag/js';

interface IProps {
    form: HistoryViewModel;
    refresh: () => void;
}

export default function DraftsTableRow(props: IProps) {
    const { form, refresh } = props;
    const [open, setOpen] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const toggleWarning = () => {
        setOpen(!open);
    }

    const deleteDraft = async () => {
        const token = await authService.getAccessToken();
        setDeleting(true);

        fetch(`Forms/DeleteDraft?id=${form.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => {
            setDeleting(false);
            toggleWarning();
            refresh();
        }).catch((error) => {
            Bugsnag.notify(error);
            setDeleting(false);
        });
    }

    const continueLink = React.useMemo(() =>
        React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
            <RouterLink
                ref={ref}
                to={{
                    pathname: `/Documents/EditDraft`,
                    state: {
                        instanceId: form.id,
                        formId: form.typeId
                    }
                }}
                {...props} />
        )), [form.id, form.typeId]);

    return (
        <TableRow
            tabIndex={-1}
            key={form.name}
        >
            <TableCell>{form.name}</TableCell>
            <TableCell>{new Date(form.date).toLocaleDateString('en-GB')}</TableCell>
            <TableCell>{form.customName}</TableCell>
            <TableCell>{form.status}</TableCell>
            <TableCell align="right">
                <SquareButton color="primary" variant="outlined" size="small" fullWidth onClick={toggleWarning}>Delete</SquareButton>
                <WarningDialog
                    close={toggleWarning}
                    open={open}
                    okClick={deleteDraft}
                    disabled={deleting}
                    buttonText="Delete"
                    text="Are you sure you want to delete this draft?"
                />
            </TableCell>
            <TableCell align="right">
                <LightBlueButton size="small" fullWidth component={continueLink}>Edit</LightBlueButton>
            </TableCell>
        </TableRow>
    );
}