﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormElementLookupViewModel from '../../ViewModels/FormElementLookupViewModel';
import LightBlueButton from '../../Utilities/LightBlueButton';

interface IProps {
    item: FormElementLookupViewModel;
    remove: (id: number) => void;
}

export default function SelectItemRow(props: IProps) {
    const { item, remove } = props;

    const removeItem = () => {
        remove(item.id);
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="space-between">
                <Grid item>
                    <Typography>{item.optionValue}</Typography>
                </Grid>
                <Grid item>
                    <LightBlueButton variant="contained" onClick={removeItem}>Remove</LightBlueButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
