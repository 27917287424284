﻿import * as React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

interface Props {
  children: React.ReactElement;
}

export default function FixedScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
  });
}