import * as React from 'react';
import NavMenu from './NavMenu';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Footer from './Footer/Footer';
import SubFooter from './Footer/SubFooter';
import { CartProvider } from './Checkout/CartContext';
import { OrganisationContext } from './Organisations/OrganisationContext';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh'
        },
        shopRoot: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: '#f6f6f6'
        },
        content: {
            flexGrow: 1,
        },
        container: {
            height: '100%',
            padding: theme.spacing(2),
            paddingTop: theme.spacing(3)
        },
        footer: {
            marginTop: 'auto',
            padding: theme.spacing(3),
            width: '100%',
            backgroundColor: '#e8e8e8'
        },
        footerBar: {
            padding: theme.spacing(2),
            backgroundColor: '#fff'
        }
    }),
);

interface IProps {
    children: any;
}

const Layout = (props: IProps) => {
    const location = useLocation();
    const classes = useStyles();
    const { organisation, refresh } = React.useContext(OrganisationContext);

    React.useEffect(() => {
        refresh();
    }, []);

    return (
        <React.Fragment>
            {location.pathname.toLowerCase() === '/bookshop' || location.pathname.toLowerCase() === '/bookshoppayment' || location.pathname.toLowerCase().includes('/bookshoporderfailed') || location.pathname.toLowerCase().includes('/bookshopcompleteorder') ?
                <div className={classes.shopRoot}>
                    <main className={classes.content}>
                        <Container maxWidth="lg" className={classes.container}>
                            <React.Fragment key={'mainView' + organisation}>{props.children}</React.Fragment>
                        </Container>
                    </main>
                </div>
                :
                <CartProvider>
                    <div className={classes.root}>
                        <NavMenu org={organisation} refreshOrg={refresh} />
                        <main className={classes.content}>
                            <Toolbar />
                            <Container maxWidth="lg" className={classes.container}>
                                <React.Fragment key={'mainView' + organisation}>{props.children}</React.Fragment>
                            </Container>
                        </main>
                        <div className={classes.footer} >
                            <Container maxWidth="lg">
                                <Footer />
                            </Container>
                        </div>
                        <div className={classes.footerBar}>
                            <Container maxWidth="lg">
                                <SubFooter />
                            </Container>
                        </div>
                    </div>
                </CartProvider>
            }
        </React.Fragment>
    );
}
export default Layout;