﻿import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import StarOutlineIcon from '@material-ui/icons/StarBorder';
import { SortFaqViewModel } from '../ViewModels/FaqViewModel';
import authService from '../api-authorization/AuthorizeService';
import Fade from '@material-ui/core/Fade';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.primary.main,
            textDecoration: "none",
            '&:hover': {
                color: theme.palette.tertiary.main
            }
        },
    }),
);

export default function PopularQuestions() {
    const classes = useStyles();
    const [questions, setQuestions] = React.useState<SortFaqViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getQuestions();
    }, []);

    const getQuestions = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch(`Faq/GetPopularQuestions`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setQuestions(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item>
                    <StarOutlineIcon color="secondary" />
                </Grid>
                <Grid item>
                    <Typography variant="h6">Popular Questions</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {questions.map((item, index) => {
                    return <Fade in={!loading} timeout={500 + (index * 500)} key={item.id}>
                        <Grid item xs={12}>
                            <Link to={`/FAQ/${item.category}/${item.id}`} className={classes.link}>{item.question}</Link>
                        </Grid>
                    </Fade>
                })}
            </Grid>
        </React.Fragment>
    );
}