﻿export default class FormElementViewModel {
    public id: number = 0;
    public text: string = '';
    public documentPlaceholder: string = '';
    public friendlyName: string = '';
    public formTypeId: number = 0;
    public formElementTypeId: number = 0;
    public formElementType: string = '';
    public helpText: string = '';
    public order: number = 0;
    public value: string | null = null;
    public file: File | undefined = undefined;
    public required: boolean = false;
    public charLimit: number = 0;
    public textTypeId: number = 0;
    public projectTemplateId: number = 0;
    public shouldSubmit: boolean = false;
    public replaceOnFalse: string = '';
}

export class FormElementListViewModel {
    public id: number = 0;
    public friendlyName: string = '';
    public formType: string = '';
    public formElementType: string = '';
    public placeholder: string = '';
    public order: number = 0;
    public textTypeId: number = 0;
}