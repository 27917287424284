﻿import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FaqViewModel from '../ViewModels/FaqViewModel';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LightBlueButton from '../Utilities/LightBlueButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SquareButton from '../Utilities/SquareButton';
import CategoryViewModel from '../ViewModels/CategoryViewModel';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
    })
);

interface IProps {
    open: boolean;
    questionId: number;
    onClose: (id: number | undefined, refresh: boolean) => void;
}

export default function QuestionEditor(props: IProps) {
    const classes = useStyles();
    const { open, questionId, onClose } = props;
    const [question, setQuestion] = React.useState(new FaqViewModel());
    const [categories, setCategories] = React.useState<CategoryViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [serverError, setError] = React.useState('');

    React.useEffect(() => {
        getCategories();
    }, []);

    React.useEffect(() => {
        getQuestion();
    }, [questionId]);

    const getCategories = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        fetch('Faq/GetCategories', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setCategories(data);
                setLoading(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const getQuestion = async () => {
        // get question if not adding
        if (questionId > 0) {
            setLoading(true);
            const token = await authService.getAccessToken();
            fetch(`Faq/GetQuestion?id=${questionId}`, {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => response.json())
                .then(data => {
                    setQuestion(data);
                    setLoading(false);
                })
                .catch(error => {
                    Bugsnag.notify(error);
                    setLoading(false);
                });
        }
    }

    const close = () => {
        onClose(undefined, false);
    }

    const save = async () => {
        const token = await authService.getAccessToken();

        fetch('Faq/SaveQuestion', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(question)
        })
            .then(response => response.text())
            .then(data => {
                setQuestion(new FaqViewModel());
                onClose(undefined, true);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setError('A server error has occurred, please try again.');
            });
    }

    const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion({
            ...question,
            popular: event.target.checked
        });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion({
            ...question,
            [event.target.name]: event.target.value
        });
    }

    const items = categories.map(item => {
        return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>;
    });

    const cancel = () => {
        setQuestion(new FaqViewModel());
        onClose(undefined, false);
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="faq-editor-title"
            PaperProps={{ className: classes.paper }}
        >
            <ValidatorForm onSubmit={save}>
                <DialogTitle id="faq-editor-title">{question.id === 0 ? 'Add' : 'Edit'} Question</DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">{serverError}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="category">Question Type</InputLabel>
                            <SelectValidator
                                id="category"
                                name="category"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={question.category === 0 ? '' : question.category}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                                InputProps={{
                                    disabled: loading,
                                    startAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress size={20} /> : null}
                                        </React.Fragment>
                                    )
                                }}
                            >
                                {items}
                            </SelectValidator>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="question">Question</InputLabel>
                            <TextValidator
                                id="question"
                                name="question"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={question.question}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="answer">Answer</InputLabel>
                            <TextValidator
                                id="answer"
                                name="answer"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                multiline
                                rows={4}
                                value={question.answer}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="popular"
                                        checked={question.popular}
                                        onChange={onCheck}
                                        color="secondary"
                                    />}
                                label="Popular Question"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SquareButton onClick={cancel} variant="outlined" color="primary">Cancel</SquareButton>
                    <LightBlueButton type="submit" variant="contained" disabled={loading}>Save</LightBlueButton>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}