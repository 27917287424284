﻿import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Table, { TableProps } from '@material-ui/core/Table';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scrollTable: {
            display: "block",
            overflowX: "auto"
        }
    })
);

export default function ResponsiveTable(props: TableProps) {
    const classes = useStyles();

    return (
        <div className={classes.scrollTable}>
            <Table {...props} />
        </div>
    );
}