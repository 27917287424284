﻿import * as React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormElementViewModel from '../../ViewModels/FormElementViewModel';
import HelpIcon from '@material-ui/icons/Help';
import LightTooltip from '../../Utilities/LightTooltip';
import Checkbox from '@material-ui/core/Checkbox';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';


interface IProps {
    element: FormElementViewModel;
    onChange: (value: string, key: number) => void;
    setShouldSubmit: (key: number) => void;
}

export default function YesNoRadioInput(props: IProps) {
    const { element, onChange, setShouldSubmit } = props;
    const [elementValue, setElementValue] = React.useState("");


    React.useEffect(() => {
        setShouldSubmit(element.id);

        if (element.value !== undefined && element.value !== null) {
            setElementValue(element.value);
        }

    }, []);

    const handleChangeFalse = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(element.id + " set to false");

        onChange("False", element.id);
        setElementValue("False");
    }

    const handleChangeTrue = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(element.id + " set to true");

        onChange("True", element.id);
        setElementValue("True");
    }

    return (
        <React.Fragment>

            <Grid item xs={12} md={9}>
                <Typography style={{ textAlign: 'justify' }}>{element.text}   {element.helpText &&
                    <LightTooltip title={element.helpText} arrow>
                        <HelpIcon style={{ height: '20px' }} color="secondary" />
                    </LightTooltip>
                }</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Grid container alignItems="center" justify="flex-end">
                    <Grid item><Typography>Yes</Typography></Grid>
                    <Grid item><Checkbox
                        name={element.documentPlaceholder}
                        color="secondary"
                        checked={(elementValue === "True")}
                        onChange={handleChangeTrue}
                        inputProps={{ 'aria-label': element.friendlyName + ' radio' }}
                        icon={<RadioButtonUncheckedIcon style={{ fontSize: '2.5rem' }} />}
                        checkedIcon={<RadioButtonCheckedIcon style={{ fontSize: '2.5rem' }} color="primary" />}
                    /></Grid>
                    <Grid item><Typography>No</Typography></Grid>
                    <Grid item><Checkbox
                        name={element.documentPlaceholder}
                        color="secondary"
                        checked={(elementValue === "False")}
                        onChange={handleChangeFalse}
                        inputProps={{ 'aria-label': element.friendlyName + ' radio' }}
                        icon={<RadioButtonUncheckedIcon style={{ fontSize: '2.5rem' }} />}
                        checkedIcon={<RadioButtonCheckedIcon style={{ fontSize: '2.5rem' }} color="primary" />}
                    /></Grid>
                </Grid>
            </Grid>


        </React.Fragment>
    );
}