import * as React from 'react';
import FormListViewModel from "../ViewModels/FormListViewModel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LightBlueButton from '../Utilities/LightBlueButton';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import NewFormDialog from './NewFormDialog';
import Grid from '@material-ui/core/Grid';
import authService from '../api-authorization/AuthorizeService';
import { useCartDispatch } from '../Checkout/CartContext';
import SquareButton from '../Utilities/SquareButton';
import UpdatePriceDialog from './UpdatePriceDialog';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ViewPreviewDialog from './ViewPreviewDialog';
import ProjectTemplateViewModel from '../ViewModels/ProjectTemplateViewModel';
import Bugsnag from '@bugsnag/js';
import WarningDialog from '../Utilities/WarningDialog';

interface IProps {
    form: FormListViewModel;
    refreshTable: () => void;
    projectTemplates: ProjectTemplateViewModel[];
}

export default function FormTableRow(props: IProps) {
    const { form, refreshTable } = props;
    const [openDialog, setOpenDialog] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [role, setRole] = React.useState('');
    const [openPriceDialog, setOpenPrice] = React.useState(false);
    const [openPreviewDialog, setOpenPreview] = React.useState(false);
    const [openArchiveWarning, setOpenArchiveWarning] = React.useState(false);

    const cartDispatch = useCartDispatch();

    React.useEffect(() => {
        getRole();
    }, []);

    React.useEffect(() => {
        if (successMessage !== "") {
            setOpen(true);
        }
    }, [successMessage]);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);

        setTimeout(() => {

            setSuccessMessage("");
        }, 250);
    };

    const getRole = async () => {
        const user = await authService.getUser();
        if (user !== null) {
            setRole(user.role);
        }
    }

    const toggleDialog = () => {
        setOpenDialog(!openDialog);
    }

    const openPrice = () => {
        setOpenPrice(true);
    }

    const togglePriceDialog = (refresh: boolean) => {
        if (refresh) {
            refreshTable();
        }

        setOpenPrice(!openPriceDialog);
    }

    const togglePreviewDialog = () => {

        setOpenPreview(!openPreviewDialog);
    }

    const addToCart = () => {
        const item = {
            id: form.id,
            formName: form.name,
            numberOfUnits: 1,
            unitCost: form.price
        };

        setSuccessMessage(form.name + " added to cart");
        cartDispatch({ type: 'addItem', item: item });
    }

    const onArchiveClick = () => {
        form.archived ? archiveForm() : setOpenArchiveWarning(true);
    }

    const archiveForm = async () => {
        const token = await authService.getAccessToken();

        fetch(`Forms/ToggleArchiveForm?formId=${form.id}`, {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        }).then(response => {
            refreshTable();
            setOpenArchiveWarning(false);
        }).catch((error) => {
            Bugsnag.notify(error);
        });
    }

    const draftLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: `/Documents/Drafts`,
                state: {
                    formId: form.id
                }
            }}
            {...props} />
    ));

    const historyLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: `/Documents/History`,
                state: {
                    formId: form.id
                }
            }}
            {...props} />
    ));

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{form.name}</TableCell>
                <TableCell align="right">{form.credits}</TableCell>
                <TableCell align="right">€{form.price.toFixed(2)}</TableCell>
                <TableCell align="right">
                    <Grid container spacing={1} justify="flex-end" style={{ flexWrap: 'nowrap' }}>
                        {!form.archived &&
                            <Grid item>
                                <LightBlueButton notextwrap={true} fullWidth size="small" variant="contained" disabled={form.credits <= 0} onClick={toggleDialog}>New Form</LightBlueButton>
                            </Grid>
                        }
                        <Grid item>
                            <LightBlueButton notextwrap={true} size="small" variant="contained" fullWidth component={draftLink}>Drafts</LightBlueButton>
                        </Grid>
                        <Grid item>
                            <LightBlueButton notextwrap={true} size="small" variant="contained" fullWidth component={historyLink}>History</LightBlueButton>
                        </Grid>
                        {(!form.archived && role === 'Admin') &&
                            <Grid item>
                                <LightBlueButton notextwrap={true} fullWidth size="small" variant="contained" onClick={openPrice}>Update Price</LightBlueButton>
                            </Grid>
                        }
                        {!form.archived &&
                            <Grid item>
                                <SquareButton notextwrap={true} color="primary" variant="contained" fullWidth size="small" onClick={addToCart}>Add to Cart</SquareButton>
                            </Grid>
                        }
                        {role === 'Admin' &&
                            <Grid item>
                                <LightBlueButton notextwrap={true} fullWidth size="small" variant="contained" onClick={onArchiveClick}>{form.archived ? 'Unarchive' : 'Archive'}</LightBlueButton>
                            </Grid>
                        }
                    </Grid>
                    <NewFormDialog open={openDialog} close={toggleDialog} formId={form.id} projectTemplates={props.projectTemplates} />
                    <ViewPreviewDialog open={openPreviewDialog} close={togglePreviewDialog} formId={form.id} />
                    {role === "Admin" &&
                        <UpdatePriceDialog close={togglePriceDialog} open={openPriceDialog} currentPrice={form.price} formId={form.id} />
                    }

                </TableCell>
                <TableCell>
                    <IconButton size="small" aria-label="copy placeholder" onClick={togglePreviewDialog}><VisibilityIcon /></IconButton>
                </TableCell>
            </TableRow>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" variant="filled">
                    {successMessage}
                </Alert>
            </Snackbar>
            <WarningDialog close={() => setOpenArchiveWarning(false)} open={openArchiveWarning} okClick={archiveForm} text={`This will prevent this form from being bought or used.`} buttonText="Archive Form" />
        </React.Fragment>
    );
}