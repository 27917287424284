﻿import * as React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import InvoiceViewModel from '../ViewModels/InvoiceViewModel';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Bugsnag from '@bugsnag/js';

interface IProps {
    invoice: InvoiceViewModel;
}

interface AlertMessage {
    success: boolean;
    message: string;
}

export default function InvoiceTableRow(props: IProps) {
    const { invoice } = props;
    const [role, setRole] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [sending, setSending] = React.useState(false);
    const [message, setMessage] = React.useState<AlertMessage>({ success: true, message: ""});

    React.useEffect(() => {
        updateAuth();
    }, []);

    React.useEffect(() => {
        if (message.message !== "") {
            setOpen(true);
        }
    }, [message]);

    const updateAuth = async () => {
        const user = await authService.getUser();
        setRole(user ? user.role : '');
    }

    const resend = async () => {
        const token = await authService.getAccessToken();
        setSending(true);

        fetch('Invoice/ResendInvoice?id=' + invoice.id, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    setMessage({ success: false, message: data });
                } else {
                    setMessage({ success: true, message: "Invoice successfully sent" });
                }
                setSending(false);
            })
            .catch(error => {
                Bugsnag.notify(error);
                setMessage({ success: false, message: "An error has occurred, please try again" });
                setSending(false);
            });
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);

        setTimeout(() => {
            setMessage({ success: true, message: "" });
        }, 250);
    };

    return (
        <React.Fragment>
            <TableRow tabIndex={-1}>
                <TableCell>{invoice.id}</TableCell>
                <TableCell>{invoice.paymentOverview}</TableCell>
                <TableCell>{invoice.purchasedBy}</TableCell>
                {
                    role === "Admin" &&
                    <React.Fragment>
                        <TableCell align="right">€{invoice.netCost.toFixed(2)}</TableCell>
                        <TableCell align="right">€{invoice.vat.toFixed(2)}</TableCell>
                    </React.Fragment>
                }
                <TableCell align="right">€{invoice.cost.toFixed(2)}</TableCell>
                <TableCell align="right">{new Date(invoice.date).toLocaleDateString("en-GB")}</TableCell>
                <TableCell align="right">
                    <LightBlueButton variant="contained" fullWidth size="small" target="blank" href={`Invoice/GetInvoicePdf?id=${invoice.id}&download=${false}`}>View</LightBlueButton>
                </TableCell>
                <TableCell align="right">
                    <LightBlueButton variant="contained" fullWidth size="small" target="blank" href={`Invoice/GetInvoicePdf?id=${invoice.id}&download=${true}`}>Download</LightBlueButton>
                </TableCell>
                {
                    role === "Admin" &&
                    <TableCell align="right">
                        <LightBlueButton variant="contained" fullWidth size="small" onClick={resend} disabled={sending}>Resend</LightBlueButton>
                    </TableCell>
                }
            </TableRow>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={message.success ? "success" : "error"}>
                    {message.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}