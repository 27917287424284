﻿import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.secondary.main,
            '&:hover': {
                color: theme.palette.tertiary.main,
                backgroundColor: 'transparent'
            },
        },
    }));

export default function SearchButton(props: IconButtonProps) {
    const classes = useStyles();

    return (
        <IconButton {...props} disableRipple disableFocusRipple className={classes.root}><SearchIcon /></IconButton>
    );
}