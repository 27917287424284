﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ColourPaper from '../Utilities/ColourPaper';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import Alert from '@material-ui/lab/Alert';
import CreditChangeViewModel from '../ViewModels/CreditChangeViewModel';
import FormTypeDropdown from './FormTypeValidator';
import OrganisationDropdown from './OrganisationDropdown';
import InputLabel from '@material-ui/core/InputLabel';
import Bugsnag from '@bugsnag/js';

export default function UpdateFormCredit() {
    const [change, setChange] = React.useState(new CreditChangeViewModel());
    const [saving, setSaving] = React.useState(false);
    const [serverError, setError] = React.useState('');

    const submit = async () => {
        if (change.organisation > 0) {

            setSaving(true);
            const token = await authService.getAccessToken();

            fetch(`Organisation/UpdateFormCredits`, {
                method: 'POST',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                body: JSON.stringify(change)
            })
                .then(response => response.text())
                .then(data => {
                    setSaving(false);

                    if (data.length > 0) {
                        setError(data);
                    } else {
                        setError('');
                        setChange(new CreditChangeViewModel());
                    }
                })
                .catch(error => {
                    Bugsnag.notify(error);
                    setSaving(false);
                    setError('A server error has occurred, please try again.');
                });
        }
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = isNaN(event.target.valueAsNumber) ?
            event.target.value :
            event.target.valueAsNumber;

        setChange({
            ...change,
            [event.target.name]: value
        });
    }

    const onOrgChange = (value: number) => {
        setChange({
            ...change,
            organisation: value
        });
    }

    return (
        <ColourPaper>
            <ValidatorForm onSubmit={submit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>Credit Changes</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="info">A negative number will remove credit.</Alert>
                    </Grid>
                    <Grid item xs={12}>
                        {serverError.length > 0 && <Alert severity="error">{serverError}</Alert>}
                    </Grid>
                    <Grid item xs={12}>
                        <OrganisationDropdown keyValue={change.organisation} onChange={onOrgChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTypeDropdown value={change.formType} onChange={onChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Amount</InputLabel>
                        <TextValidator
                            name="amount"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            type="number"
                            value={change.amount}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Reason</InputLabel>
                        <TextValidator
                            name="reason"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            multiline
                            rows={2}
                            value={change.reason}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LightBlueButton fullWidth variant="contained" type="submit" disabled={saving || change.organisation === 0}>Add / Remove Credit</LightBlueButton>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </ColourPaper>
    );
}