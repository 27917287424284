﻿import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import NotificationViewModel from '../ViewModels/NotificationViewModel';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.secondary.main} solid 2px`,
            padding: theme.spacing(2),
            backgroundColor: '#f8f8f8'
        },
    }));

interface IProps {
    notification: NotificationViewModel;
    refresh: () => void;
    role: string;
}

export default function Notification(props: IProps) {
    const { notification, role, refresh } = props;
    const classes = useStyles();
    const [clearing, setClearing] = React.useState(false);

    const markAsRead = async () => {
        setClearing(true);
        const token = await authService.getAccessToken();

        fetch(`Notification/MarkAsRead?id=${notification.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => {
                setClearing(false);
                refresh();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setClearing(false);
            });
    }

    const deleteNotification = async () => {
        setClearing(true);
        const token = await authService.getAccessToken();

        fetch(`Notification/DeleteNotification?id=${notification.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => {
                setClearing(false);
                refresh();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setClearing(false);
            });
    }

    return (
        <Paper square className={classes.paper}>
            <Grid container justify="space-between" spacing={2}>
                <Grid item>
                    <Typography variant="h6">{notification.title}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1"><b>Date:</b> {new Date(notification.timeStamp).toLocaleDateString('en-GB')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography gutterBottom><b>{notification.subTitle}</b></Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} justify="flex-end">
                <Grid item xs={12}>
                    <Typography>{notification.body}</Typography>
                </Grid>
                {role !== "Admin" &&
                    <Grid item>
                        <LightBlueButton variant="contained" size="small" onClick={markAsRead} disabled={clearing}>Clear</LightBlueButton>
                    </Grid>
                }
                {role === "Admin" &&
                    <Grid item>
                        <LightBlueButton variant="contained" size="small" onClick={deleteNotification} disabled={clearing}>Delete</LightBlueButton>
                    </Grid>
                }
            </Grid>
        </Paper>
    );
}