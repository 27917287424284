import * as React from 'react';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, Link as RouterLink, LinkProps as RouterLinkProps, NavLink, useLocation } from 'react-router-dom';
import Button, { ButtonProps } from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import authService from './api-authorization/AuthorizeService';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import OrganisationDropdown from './Users/OrganisationDropdown';
import { useCartState } from './Checkout/CartContext';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            paddingLeft: 0,
            paddingRight: 0
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        logo: {
            marginRight: 'auto',
            height: 'inherit'
        },
        center: {
            marginRight: 'auto',
            marginLeft: 'auto'
        },
        account: {
            marginLeft: 'auto'
        },
        bar: {
            height: 64
        },
        profileButton: {
            textTransform: 'none'
        },
        profileIcon: {
            color: theme.palette.secondary.main
        },
        barButton: {
            transition: 'all .3s ease',
            color: theme.palette.primary.main,
            marginRight: theme.spacing(2),
            borderRadius: 0,
            borderBottom: `2px solid transparent`,
            '&:hover, &:focus, &$focusVisible': {
                color: theme.palette.tertiary.main,
                borderBottom: `2px solid ${theme.palette.tertiary.main}`,
                backgroundColor: "inherit",
            }
        },
        activeBarButton: {
            color: theme.palette.tertiary.main,
            borderBottom: `2px solid ${theme.palette.tertiary.main}`,
            marginRight: theme.spacing(2),
            borderRadius: 0,
        },
        focusVisible: {
        },
        formControl: {

            margin: 'auto',
            minWidth: 120,
        },
        select: {
            borderRadius: 0,
            margin: 8
        },
        selectBox: {

            paddingLeft: 14,
            paddingTop: 14,
            paddingRight: 35,
            paddingBottom: 14,

        },
        activeLink: {
            color: theme.palette.tertiary.main,
            borderBottom: `2px solid ${theme.palette.tertiary.main}`,
        },
        popper: {
            top: '13px !important',
            zIndex: 1300
        },
        profilePopper: {
            zIndex: 1300,
            [theme.breakpoints.down('md')]: {
                top: '10px !important'
            }
        },
        menu: {
            backgroundColor: '#f0edec',
            borderRadius: 0
        },
        menuItem: {
            backgroundColor: '#f0edec',
            color: theme.palette.primary.main,
            '&:focus, &:hover': {
                backgroundColor: '#f0edec',
                color: theme.palette.tertiary.main,
            },
        },
        hideWhenSmall: {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        }
    }),
);

interface AppButtonProps {
    text: string;
    link: string;
}

function UnderlineNavButton(props: AppButtonProps) {
    const { text, link } = props;
    const classes = useStyles()

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <NavLink exact={true} to={link} ref={ref} {...itemProps} activeClassName={classes.activeLink} />
            )),
        [text],
    );

    return (
        <Button disableRipple focusVisibleClassName={classes.focusVisible} className={classes.barButton} component={renderLink}>{text}</Button>
    );
}

function MenuLink(props: AppButtonProps & MenuItemProps) {
    const { text, link } = props;
    const classes = useStyles()

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <NavLink exact={true} to={link} ref={ref} {...itemProps} />
            )),
        [text],
    );

    return (
        <MenuItem className={classes.menuItem} component={renderLink}>{text}</MenuItem>
    );
}

function DocumentsButton(props: ButtonProps) {
    const classes = useStyles()
    const location = useLocation();

    const style = location.pathname.includes('Documents') ? classes.activeBarButton : classes.barButton;

    return (
        <Button disableRipple focusVisibleClassName={classes.focusVisible} className={style} {...props} />
    );
}

interface IProps {
    org: string;
    refreshOrg: () => void;
}

export default function NavMenu(props: IProps) {
    const classes = useStyles();
    const theme = useTheme();
    const { org, refreshOrg } = props;
    const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);
    const isProfileMenuOpen = Boolean(profileAnchorEl);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuAnchorEl);
    const [mouseOverButton, setMouseButton] = React.useState(false);
    const [mouseOverMenu, setMouseMenu] = React.useState(false);
    const [formAnchorEl, setFormAnchorEl] = React.useState<null | HTMLElement>(null);
    const isFormMenuOpen = Boolean(formAnchorEl);
    const [authenticated, setAuthenticated] = React.useState(false);
    const [userName, setUserName] = React.useState('');
    const [role, setRole] = React.useState('');
    const [openOrg, setOpenOrg] = React.useState(false);
    const [mainOwner, setMainOwner] = React.useState(false);
    const [isMember, setIsMember] = React.useState(false);

    const { cart } = useCartState();

    React.useEffect(() => {
        updateAuth();
    }, []);

    React.useEffect(() => {
        if (authenticated) {
            checkIfOwner();
        }
    }, [authenticated, org]);

    const updateAuth = async () => {
        const isAuth = await authService.isAuthenticated();
        setAuthenticated(isAuth);

        const user = await authService.getUser();
        const name = user ? user.name : '';
        setUserName(name);
        setRole(user ? user.role : '');
        setIsMember(user ? user.member === 'True' : false);
    }

    const checkIfOwner = async () => {
        const token = await authService.getAccessToken();

        fetch('Organisation/GetMainOwnerStatus', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((response) => response.json())
            .then(data => {
                setMainOwner(data);
            })
            .catch((error) => { Bugsnag.notify(error); });
    }

    const cartLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/ShoppingCart" ref={ref} {...itemProps} />
    ));

    const notifLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Notifications" ref={ref} {...itemProps} />
    ));

    const profileLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Profile" ref={ref} {...itemProps} />
    ));

    const orgLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Organisations" ref={ref} {...itemProps} />
    ));

    const adminLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to="/Admin" ref={ref} {...itemProps} />
    ));

    const logoutLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={{
            pathname: `${ApplicationPaths.LogOut}`,
            state: { local: true }
        }} ref={ref} {...itemProps} />
    ));

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileAnchorEl(null);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleFormMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMouseMenu(true);
    };

    const handleFormButtonOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMouseButton(true);
        setFormAnchorEl(event.currentTarget);
    };

    const handleFormMenuClose = () => {
        setMouseMenu(false);
    };

    const handleFormButtonClose = () => {
        setTimeout(() => {
            setMouseButton(false);
        }, 300);
    };

    React.useEffect(() => {
        if (!mouseOverButton && !mouseOverMenu) {
            setFormAnchorEl(null);
        }
    }, [mouseOverMenu, mouseOverButton]);

    const handleCloseOrg = (refresh: boolean) => {
        setOpenOrg(false);

        if (refresh) {
            refreshOrg();
        }
    }

    const handleOpenOrg = () => {
        setOpenOrg(true);
    }

    const cartCount = cart.reduce((count, item) => { return count + item.numberOfUnits; }, 0);

    const bigScreen = useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        if (bigScreen) {
            setMenuAnchorEl(null);
        }
    }, [bigScreen]);

    return (
        <div className={classes.root}>
            <AppBar position="fixed" color="default" className={classes.appBar}>
                <Toolbar disableGutters className={classes.bar}>
                    <div className={classes.logo}>
                        <Grid container spacing={2} alignItems="center" justify="flex-end">
                            <Grid item>
                                <Link to="/"><img src="logo.png" alt="RIAI" height="64" /></Link>
                            </Grid>
                        </Grid>
                    </div>

                    {bigScreen &&
                        <div className={classes.center}>
                            <Grid container spacing={4}>
                                <Grid item>
                                    <UnderlineNavButton text="Home" link="/" />
                                </Grid>
                                <Grid item>
                                    <DocumentsButton onClick={handleFormMenuOpen} onMouseOver={handleFormButtonOpen} onMouseLeave={handleFormButtonClose}>Documents</DocumentsButton>
                                </Grid>
                                <Grid item>
                                    <UnderlineNavButton text="Invoices" link="/Invoices" />
                                </Grid>
                                {((role && role === 'Admin') || mainOwner) &&
                                    <Grid item>
                                        <UnderlineNavButton text="Users" link="/Users" />
                                    </Grid>
                                }
                                <Grid item>
                                    <UnderlineNavButton text="FAQ" link="/FAQ" />
                                </Grid>
                            </Grid>
                        </div>
                    }

                    <div className={classes.account}>
                        <Grid container spacing={1} alignItems="center" justify="flex-end">
                            <Grid item>
                                <IconButton color="secondary" aria-label="shopping cart" component={cartLink}>
                                    <Badge color="primary" badgeContent={cartCount}>
                                        <ShoppingCartIcon />
                                    </Badge>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton color="secondary" aria-label="notifications" component={notifLink}>
                                    <NotificationsIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Button disabled={!authenticated} color="primary" size="large" aria-label="open account menu" className={classes.profileButton} endIcon={<AccountCircleIcon className={classes.profileIcon} />} onClick={handleProfileMenuOpen}>
                                    <div>
                                        <Typography className={classes.hideWhenSmall}>{userName}</Typography>
                                        <Typography className={classes.hideWhenSmall} variant="caption" align="right">{org}</Typography>
                                    </div>
                                </Button>
                            </Grid>
                            {!bigScreen &&
                                <Grid item>
                                    <IconButton color="secondary" aria-label="open menu" onClick={handleMenuOpen}>
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </Toolbar>
            </AppBar>

            <Popper
                anchorEl={profileAnchorEl}
                id='menu'
                open={isProfileMenuOpen}
                disablePortal
                role={undefined}
                transition
                placement="bottom-end"
                className={classes.profilePopper}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'right top'
                        }}
                    >
                        <div className={classes.menu}>
                            <ClickAwayListener onClickAway={handleProfileMenuClose}>
                                <MenuList>
                                    <MenuItem className={classes.menuItem} onClick={handleProfileMenuClose} component={notifLink}>Notifications</MenuItem>
                                    <MenuItem className={classes.menuItem} onClick={handleProfileMenuClose} component={profileLink}>Profile</MenuItem>
                                    <MenuItem className={classes.menuItem} onClick={handleOpenOrg}>Switch Organisation</MenuItem>
                                    {((role && role === "Admin") || isMember) && <MenuItem className={classes.menuItem} onClick={handleProfileMenuClose} component={orgLink}>Manage Organisation</MenuItem>}
                                    {role && role === "Admin" && <MenuItem className={classes.menuItem} onClick={handleProfileMenuClose} component={adminLink}>Admin</MenuItem>}
                                    {authenticated && <MenuItem className={classes.menuItem} onClick={handleProfileMenuClose} component={logoutLink}>Logout</MenuItem>}
                                </MenuList>
                            </ClickAwayListener>
                        </div>
                    </Grow>
                )}
            </Popper>

            {!bigScreen &&
                <Popper
                    anchorEl={menuAnchorEl}
                    id='menu'
                    open={isMenuOpen}
                    disablePortal
                    role={undefined}
                    transition
                    placement="bottom-end"
                    className={classes.profilePopper}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'right top'
                            }}
                        >
                            <div className={classes.menu}>
                                <ClickAwayListener onClickAway={handleMenuClose}>
                                    <MenuList>
                                        <MenuLink onClick={handleMenuClose} link={"/"} text="Home" />
                                        <MenuItem onClick={handleFormMenuOpen} onMouseOver={handleFormButtonOpen} onMouseLeave={handleFormButtonClose} className={classes.menuItem}>Documents</MenuItem>
                                        <MenuLink onClick={handleMenuClose} link="/Invoices" text="Invoices" />
                                        {((role && role === 'Admin') || mainOwner) && <MenuLink onClick={handleMenuClose} link="/Users" text="Users" />}
                                        <MenuLink onClick={handleMenuClose} text="FAQ" link="/FAQ" />
                                    </MenuList>
                                </ClickAwayListener>
                            </div>
                        </Grow>
                    )}
                </Popper>
            }

            <Popper
                anchorEl={formAnchorEl}
                id='formMenu'
                open={isFormMenuOpen}
                disablePortal
                role={undefined}
                transition
                placement={bigScreen ? "bottom-end" : "right-start"}
                className={classes.popper}
                onMouseEnter={handleFormMenuOpen}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'right top'
                        }}
                    >
                        <div className={classes.menu}>
                            <ClickAwayListener onClickAway={handleFormMenuClose}>
                                <MenuList onMouseLeave={handleFormMenuClose}>
                                    <MenuLink onClick={handleFormMenuClose} text="Buy & Create" link="/Documents" />
                                    <MenuLink onClick={handleFormMenuClose} text="My Drafts" link="/Documents/Drafts" />
                                    <MenuLink onClick={handleFormMenuClose} text="My Project Templates" link="/Documents/Templates" />
                                    <MenuLink onClick={handleFormMenuClose} text="View Generated" link="/Documents/History" />
                                </MenuList>
                            </ClickAwayListener>
                        </div>
                    </Grow>
                )}
            </Popper>
            <OrganisationDropdown close={handleCloseOrg} open={openOrg} />
        </div>
    );
}
