﻿import Bugsnag from '@bugsnag/js';
import * as React from 'react';
import authService from './../api-authorization/AuthorizeService';

type ContextType = {
    organisation: string;
    refresh: () => void;
};

type OrgProviderProps = { children: React.ReactNode };

export const OrganisationContext = React.createContext<ContextType>({ organisation: '', refresh: () => { throw Error('Cannot use a context without a provider'); } });

export default function OrganisationProvider({ children }: OrgProviderProps) {
    const [organisation, setOrganisation] = React.useState('');

    const getOrg = async () => {
        const token = await authService.getAccessToken();
        const isAuth = await authService.isAuthenticated();

        if (isAuth) {
            fetch('Organisation/GetActiveOrganisationName', {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            })
                .then(response => {
                    if (response.ok) {
                        return response.text();
                    } else {
                        return response.json();
                    }
                })
                .then(data => {
                    if (data.includes('html')) {
                        setOrganisation('');
                    } else {
                        setOrganisation(data);
                    }
                })
                .catch(error => {
                    Bugsnag.notify(error);
                    setOrganisation('');
                });
        } else {
            setOrganisation('');
        }
    }

    return (
        <OrganisationContext.Provider value={{ organisation, refresh: getOrg }}>
            {children}
        </OrganisationContext.Provider>
    );
}