﻿import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import ColourPaper from '../Utilities/ColourPaper';
import LightBlueButton from '../Utilities/LightBlueButton';
import AdjustVat from './AdjustVat';
import AlertManagement from './AlertManagement';
import MemberCreditsUpload from './MemberCreditsUpload';
import ReportingLinks from './ReportingLinks';
import UpdateFormCredit from './UpdateFormCredit';
import { Link, LinkProps } from 'react-router-dom';

export default function AdminContainer() {

    const bookshopLink = React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <Link to="/Admin/ManageBookshop" ref={ref} {...itemProps} />
    ));

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <ReportingLinks />
            </Grid>
            <Grid item xs={4}>
                <AdjustVat />
            </Grid>
            <Grid item xs={4}>
                <MemberCreditsUpload />
            </Grid>
            <Grid item xs={4}>
                <UpdateFormCredit />
            </Grid>
            <Grid item xs={4}>
                <AlertManagement />
            </Grid>
            <Grid item xs={4}>
                <ColourPaper>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom>Manage Bookshop</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LightBlueButton fullWidth variant="contained" component={bookshopLink}>Manage Bookshop Products</LightBlueButton>
                        </Grid>
                    </Grid>
                </ColourPaper>
            </Grid>
        </Grid>
    );
}