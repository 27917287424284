﻿import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

const green = '#5ec4b6';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiInput-root': {
                color: '#867874',
                backgroundColor: '#fefefe',
                minHeight: '3.125rem',
                padding: '0.5rem',
                boxShadow: 'inset 0 1px 2px rgb(10 10 10 / 10%)',
            },
            '& .MuiInput-underline:before': {
                borderBottomColor: green,
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: green,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: green,
            },
        },
    })
);

export default function GreenTextfield(props: TextFieldProps) {
    const classes = useStyles();

    return (
        <TextField className={classes.root} {...props} />
    );
}