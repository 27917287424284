﻿import * as React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { FormElementListViewModel } from '../../ViewModels/FormElementViewModel';
import LightBlueButton from '../../Utilities/LightBlueButton';
import authService from '../../api-authorization/AuthorizeService';
import AddSelectItemDialog from './AddSelectItemDialog';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Bugsnag from '@bugsnag/js';

interface IProps {
    formTypeId: number;
    element: FormElementListViewModel;
    edit: (id: number) => void;
    refresh: () => void;
    last: boolean;
}

export default function FormElementsTableRow(props: IProps) {
    const { element, formTypeId, last } = props;
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [beenCopied, setBeenCopied] = React.useState(false);

    const toggleDialog = () => {
        setOpen(!open);
    }

    const edit = () => {
        props.edit(element.id);
    }

    const deleteElement = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        fetch(`FormBuilder/DeleteElement?id=${element.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => {
                setLoading(false);
                props.refresh();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const increaseOrder = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        fetch(`FormBuilder/UpdateElementOrder?typeId=${formTypeId}&id=${element.id}&order=${element.order + 1}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => {
                setLoading(false);
                props.refresh();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const decreaseOrder = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        fetch(`FormBuilder/UpdateElementOrder?typeId=${formTypeId}&id=${element.id}&order=${element.order - 1}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => {
                setLoading(false);
                props.refresh();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const addElementBelow = async () =>
    {
        setLoading(true);
        const token = await authService.getAccessToken();
        fetch(`FormBuilder/AddElementBelow?typeId=${formTypeId}&id=${element.id}&order=${element.order}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => {
                setLoading(false);
                props.refresh();
            })
            .catch(error => {
                Bugsnag.notify(error);
                setLoading(false);
            });
    }

    const copyPlaceholder = () => {
        navigator.clipboard.writeText(element.placeholder);
        setBeenCopied(true);
    }

    return (
        <TableRow tabIndex={-1}>
            <TableCell>{element.order}</TableCell>
            <TableCell>{element.friendlyName}</TableCell>
            <TableCell>{element.formElementType}</TableCell>
            <TableCell>
                {(element.formElementType !== "Spacer" && element.formElementType !== "Display Text") &&
                    
                    <TableCell>
                    {beenCopied &&
                        <IconButton size="small" aria-label="copy placeholder" color="secondary" onClick={copyPlaceholder}>
                            <CopyIcon />
                        </IconButton>
                    }
                    {!beenCopied &&
                        <IconButton size="small" aria-label="copy placeholder" onClick={copyPlaceholder}>
                            <CopyIcon />
                        </IconButton>
                    }
                        {element.placeholder}
                    </TableCell>
                }
            </TableCell>
            <TableCell align="right">
                {element.formElementType === "Select Input" &&
                    <React.Fragment>
                        <LightBlueButton variant="contained" fullWidth size="small" onClick={toggleDialog} disabled={loading}>Modify Items</LightBlueButton>
                        <AddSelectItemDialog elementId={element.id} onClose={toggleDialog} open={open} />
                    </React.Fragment>
                }
            </TableCell>
            <TableCell align="right">
                <IconButton size="small" aria-label="copy placeholder" onClick={edit}><EditIcon /></IconButton>
            </TableCell>

            <TableCell align="right">
                <IconButton size="small" aria-label="copy placeholder" onClick={addElementBelow}><AddIcon /></IconButton>
            </TableCell>
            <TableCell>
                {element.order > 1 &&
                    <IconButton size="small" aria-label="decrease order" onClick={decreaseOrder}><ArrowUpwardIcon /></IconButton>
                }
                {!last &&
                    <IconButton size="small" aria-label="increase order" onClick={increaseOrder}><ArrowDownwardIcon /></IconButton>
                }
            </TableCell>
            <TableCell align="right">
                <IconButton size="small" aria-label="copy placeholder" onClick={deleteElement}><DeleteIcon /></IconButton>

            </TableCell>
        </TableRow>
    );
}