import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';

interface IProps {
}

export default function Success(props: IProps) {
    return (
        <div>
            <Typography variant="h4">Payment Success</Typography>
            <Redirect to={{
                pathname: '/',
            }} />
        </div>
    );
}
