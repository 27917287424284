﻿import * as React from 'react';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditOrganisation from './EditOrganisation';
import AddOrganisation from './AddOrganisation';
import Bugsnag from '@bugsnag/js';

export default function ManageOrganisations() {
    const [role, setRole] = React.useState('');
    const [isMember, setIsMember] = React.useState(false);
    const [mainOwner, setMainOwner] = React.useState(false);

    React.useEffect(() => {
        getRole();
        checkIfOwner();
    }, []);

    const getRole = async () => {
        const user = await authService.getUser();
        setRole(user ? user.role : '');
        setIsMember(user.member === 'True');
    }

    const checkIfOwner = async () => {
        const token = await authService.getAccessToken();

        fetch('Organisation/GetMainOwnerStatus', {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then((response) => response.json())
            .then(data => {
                setMainOwner(data);
            }).catch(error => {
                Bugsnag.notify(error);
            });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>Manage Organisations</Typography>
            </Grid>
            {(role === 'Admin' || mainOwner) && 
                <Grid item xs={12} sm={4}>
                    <EditOrganisation role={role} />
                </Grid>
            }
            {isMember || role === 'Admin' &&
                <Grid item xs={12} sm={4}>
                    <AddOrganisation role={role} />
                </Grid>
            }
        </Grid>
    );
}