﻿import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import SquareButton from '../Utilities/SquareButton';
import LightBlueButton from '../Utilities/LightBlueButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderTop: `${theme.palette.primary.main} solid 5px`,
            padding: theme.spacing(2)
        },
    })
);

interface IProps {
    text: string;
    buttonText: string;
    open: boolean;
    okLink?: any;
    okClick?: () => void;
    close: () => void;
    disabled?: boolean;
}

export default function WarningDialog(props: IProps) {
    const classes = useStyles();
    const { open, close, okLink, okClick, text, buttonText, disabled } = props;

    const cancel = () => {
        close();
    }

    const isDisabled = disabled === undefined ? false : disabled;

    const okButton = okLink ?
        <LightBlueButton variant="contained" component={okLink} disabled={isDisabled}>{buttonText}</LightBlueButton> :
        <LightBlueButton variant="contained" onClick={okClick} disabled={isDisabled}>{buttonText}</LightBlueButton>;

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={close}
            aria-labelledby="new-form-title"
            PaperProps={{ className: classes.paper }}
        >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <SquareButton onClick={cancel} variant="outlined" color="primary" disabled={disabled}>Cancel</SquareButton>
                {okButton}
            </DialogActions>
        </Dialog>
    );
}