﻿import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ColourPaper from '../Utilities/ColourPaper';
import { BookshopProductViewModel } from '../BookShop/OrderViewmodel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import authService from '../api-authorization/AuthorizeService';
import UpdateBookshopRow from './UpdateBookshopRow';
import LightBlueButton from '../Utilities/LightBlueButton';
import BookProductDialog from './BookProductDialog';
import Bugsnag from '@bugsnag/js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2)
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableHead: {
            borderBottom: `${theme.palette.secondary.main} solid 2px`
        },
        headers: {
            color: theme.palette.primary.main
        },
        hidden: {
            display: 'none'
        }
    }),
);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof BookshopProductViewModel;
    label: string;
    numeric: boolean;
    property: string;
}

const headCells: HeadCell[] = [
    { id: 'description', property: 'Description', numeric: false, disablePadding: false, label: 'Description' },
    { id: 'weight', property: 'Weight', numeric: true, disablePadding: false, label: 'Weight' },
    { id: 'cost', property: 'Cost', numeric: true, disablePadding: false, label: 'Cost' }
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof BookshopProductViewModel) => void;
    order: Order;
    orderBy: string;
    children?: React.ReactNode;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof BookshopProductViewModel) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            className={classes.headers}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {props.children}
            </TableRow>
        </TableHead>
    );
}

export default function ReportingLinks() {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof BookshopProductViewModel>('id');
    const [products, setProducts] = React.useState<BookshopProductViewModel[]>([]);
    const [openAdd, setOpenAdd] = React.useState(false);

    React.useEffect(() => {
        getProducts();
    }, [order, orderBy]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof BookshopProductViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getProducts = async () => {
        const token = await authService.getAccessToken();

        fetch(`Bookshop/GetOrderItems`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.json()).then((data) => {
            setProducts(data);
        }).catch((error) => {
            Bugsnag.notify(error);
        });
    }

    const toggleOpenAdd = (refresh: boolean) => {
        if (refresh) {
            getProducts();
        }

        setOpenAdd(!openAdd);
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ColourPaper>
                        <Grid container spacing={1} justify="space-between">
                            <Grid item>
                                <Typography variant="h4" gutterBottom>Bookshop Orders Report</Typography>
                            </Grid>
                            <Grid item>
                                <LightBlueButton variant="contained" onClick={() => setOpenAdd(true)}>Add New Product</LightBlueButton>
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <ResponsiveTable
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                aria-label="manage bookshop table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(products, getComparator(order, orderBy))
                                        .map((row: BookshopProductViewModel, index) => {
                                            return (<UpdateBookshopRow key={index} product={row} refreshTable={getProducts} />);
                                        })
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                    </ColourPaper>
                </Grid>
            </Grid>
            <BookProductDialog open={openAdd} close={toggleOpenAdd} product={new BookshopProductViewModel()} />
        </div>
    );
}