﻿import * as React from 'react';
import HistoryViewModel from "../ViewModels/HistoryViewModel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LightBlueButton from '../Utilities/LightBlueButton';
import authService from '../api-authorization/AuthorizeService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SquareButton from '../Utilities/SquareButton';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Bugsnag from '@bugsnag/js';

interface IProps {
    form: HistoryViewModel;
    refreshTable: () => void;
}

interface AlertMessage {
    success: boolean;
    message: string;
}

export default function HistoryTableRow(props: IProps) {
    const { form } = props;
    const [role, setRole] = React.useState('');
    const [open, setEmailOpen] = React.useState(false);
    const [sending, setSending] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [clickedReprocess, setClickedReprocess] = React.useState(false);
    const [message, setMessage] = React.useState<AlertMessage>({ success: true, message: "" });


    const sendEmail = async () => {
        const token = await authService.getAccessToken();
        setSending(true);
        var url = `Forms/ResendDocument?id=${form.id}&email=${email}`;

        fetch(url, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.text())
            .then(data => {
                if (data.length > 0) {
                    setMessage({ success: false, message: data });
                } else {
                    setMessage({ success: true, message: "Document successfully sent" });
                }
                handleClose();
                setSending(false);
            }).catch((error) => {
                Bugsnag.notify(error);
                setMessage({ success: false, message: "An error has occurred, please try again" });
                handleClose();
                setSending(false);
            });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const handleOpen = () => {
        setEmailOpen(true);
    }

    const handleClose = () => {
        setEmail('');
        setEmailOpen(false);
    }

    const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setTimeout(() => {
            setMessage({ success: true, message: "" });
        }, 250);
    }

    React.useEffect(() => {
        updateAuth();
    }, []);

    const reprocess = async () => {
        setClickedReprocess(true);

        const token = await authService.getAccessToken();

        fetch(`Forms/ReprocessForm?formInstanceId=` + props.form.id, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }).then(response => response.text()).then((data) => {

            props.refreshTable();
        }).catch((error) => {
            Bugsnag.notify(error);
        });
    }

    const updateAuth = async () => {
        const user = await authService.getUser();
        setRole(user ? user.role : '');
    }

    return (
        <TableRow
            tabIndex={-1}
            key={form.name}
        >
            <TableCell>{form.name}</TableCell>
            <TableCell>{form.customName}</TableCell>
            <TableCell>{new Date(form.date).toLocaleDateString('en-GB')}</TableCell>
            <TableCell>{form.status}</TableCell>
            <TableCell>{form.generatedDocRef}</TableCell>
            {(form.status === "Complete") &&
                <React.Fragment>
                    <TableCell align="right">
                        <LightBlueButton size="small" target="blank" download={true} href={`Forms/GetGeneratedDocument?formInstanceId=${form.id}&download=${true}`}>Download</LightBlueButton>
                    </TableCell>
                    <TableCell align="right"><LightBlueButton size="small" target="blank" href={`Forms/GetGeneratedDocument?formInstanceId=${form.id}&download=${false}`}>View</LightBlueButton></TableCell>
                </React.Fragment>
            }
            {form.status === "Processing" &&
                <React.Fragment>
                    <TableCell align="right">
                        <Alert severity="info">
                            Document is processing. Please check back in a few minutes.
                    </Alert>
                    </TableCell>
                </React.Fragment >
            }
            {(form.status === "Failed" &&
                role !== "Admin") &&
                <React.Fragment>
                    <TableCell align="right">
                        <Alert severity="error">
                        Processing has failed. Please contact <a href="mailto:info@riai.ie">info@riai.ie</a>.
                    </Alert>
                    </TableCell>

                </React.Fragment >
            }

            {(form.status === "Failed" &&
                role === "Admin") &&
                <React.Fragment>
                    <TableCell align="right">
                        <Alert severity="error">
                        If processing issues persist, please contact Smashed Crab support at <a href="mailto:support@smashedcrab.zendesk.com">support@smashedcrab.zendesk.com</a>.
                    </Alert>
                    </TableCell>

                    <TableCell align="right">
                        <LightBlueButton disabled={clickedReprocess} fullWidth size="small" onClick={reprocess}>Reprocess</LightBlueButton>
                    </TableCell>

                </React.Fragment>
            }

            {
                (form.status === "Complete" &&
                    role === "Admin") &&
                <React.Fragment>
                    <TableCell align="right">
                        <LightBlueButton fullWidth size="small" onClick={handleOpen}>Resend</LightBlueButton>
                    </TableCell>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="email-form-dialog">
                        <ValidatorForm onSubmit={sendEmail}>
                            <DialogTitle id="email-form-dialog">Resend Generated Document</DialogTitle>
                            <DialogContent>
                                <DialogContentText>The provided email address must be for a user within the same organisation the generated document belongs to.</DialogContentText>
                                <TextValidator
                                    autoFocus
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    type="email"
                                    value={email}
                                    onChange={handleChange}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['This field is required', 'Invalid email address']}
                                />
                            </DialogContent>
                            <DialogActions>
                                <SquareButton onClick={handleClose} variant="outlined" color="primary" disabled={sending}>Cancel</SquareButton>
                                <LightBlueButton type="submit" disabled={sending}>Send</LightBlueButton>
                            </DialogActions>
                        </ValidatorForm>
                    </Dialog>
                    <Snackbar open={message.message.length > 0} autoHideDuration={6000} onClose={handleAlertClose}>
                        <Alert onClose={handleAlertClose} severity={message.success ? "success" : "error"}>
                            {message.message}
                        </Alert>
                    </Snackbar>
                </React.Fragment>
            }
        </TableRow >
    );
}